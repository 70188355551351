/*** BOOTSTRAP ***/
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: sans-serif;
    line-height: 1.15
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    color: #212529;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    margin-top: 0
}

p {
    margin-top: 0
}

abbr[data-original-title],
abbr[title] {
    border-bottom: 0;
    cursor: help;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem
}

dl,
ol,
ul {
    margin-top: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    background-color: transparent;
    color: #007bff;
    text-decoration: none
}

a:hover {
    color: #0056b3
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

pre {
    -ms-overflow-style: scrollbar;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img,
svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    caption-side: bottom;
    color: #6c757d;
    padding-bottom: .75rem;
    padding-top: .75rem;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0
}

legend {
    color: inherit;
    display: block;
    font-size: 1.5rem;
    line-height: inherit;
    margin-bottom: .5rem;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: none;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

output {
    display: inline-block
}

summary {
    cursor: pointer;
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .5rem
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem
}

.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem
}

.display-3 {
    font-size: 4.5rem
}

.display-3,
.display-4 {
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem
}

hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 1rem;
    margin-top: 1rem
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    background-color: #fcf8e3;
    padding: .2em
}

.list-inline,
.list-unstyled {
    list-style: none;
    padding-left: 0
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    font-size: 1.25rem;
    margin-bottom: 1rem
}

.blockquote-footer {
    color: #6c757d;
    display: block;
    font-size: 80%
}

.blockquote-footer:before {
    content: "— "
}

.img-thumbnail {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    height: auto;
    max-width: 100%;
    padding: .25rem
}

.figure {
    display: inline-block
}

.figure-img {
    line-height: 1;
    margin-bottom: .5rem
}

.figure-caption {
    color: #6c757d;
    font-size: 90%
}

code {
    word-wrap: break-word;
    color: #e83e8c;
    font-size: 87.5%
}

a>code {
    color: inherit
}

kbd {
    background-color: #212529;
    border-radius: .2rem;
    color: #fff;
    font-size: 87.5%;
    padding: .2rem .4rem
}

kbd kbd {
    font-size: 100%;
    font-weight: 700;
    padding: 0
}

pre {
    color: #212529;
    display: block;
    font-size: 87.5%
}

pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.no-gutters {
    margin-left: 0;
    margin-right: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-left: 0;
    padding-right: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto
}

.col-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
}

.col-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
}

.col-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
}

.col-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
}

.col-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-sm-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-sm-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-sm-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-sm-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333333%
    }

    .offset-sm-2 {
        margin-left: 16.66666667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333333%
    }

    .offset-sm-5 {
        margin-left: 41.66666667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333333%
    }

    .offset-sm-8 {
        margin-left: 66.66666667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333333%
    }

    .offset-sm-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }
}

.table {
    color: #212529;
    margin-bottom: 1rem;
    width: 100%
}

.table td,
.table th {
    border-top: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top
}

.table thead th {
    border-bottom: 2px solid #dee2e6;
    vertical-align: bottom
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, .075);
    color: #212529
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #b8daff
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #7abaff
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d6d8db
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #b3b7bb
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c3e6cb
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #8fd19e
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #86cfda
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffeeba
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #ffdf7e
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #f5c6cb
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #ed969e
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fdfdfe
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #fbfcfc
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #c6c8ca
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #95999c
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th,
.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    background-color: #343a40;
    border-color: #454d55;
    color: #fff
}

.table .thead-light th {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #495057
}

.table-dark {
    background-color: #343a40;
    color: #fff
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #454d55
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, .05)
}

.table-dark.table-hover tbody tr:hover {
    background-color: hsla(0, 0%, 100%, .075);
    color: #fff
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        -webkit-overflow-scrolling: touch;
        display: block;
        overflow-x: auto;
        width: 100%
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        -webkit-overflow-scrolling: touch;
        display: block;
        overflow-x: auto;
        width: 100%
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        -webkit-overflow-scrolling: touch;
        display: block;
        overflow-x: auto;
        width: 100%
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        -webkit-overflow-scrolling: touch;
        display: block;
        overflow-x: auto;
        width: 100%
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
    width: 100%
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    background-color: #fff;
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    color: #495057;
    outline: 0
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057
}

select.form-control:focus::-ms-value {
    background-color: #fff;
    color: #495057
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    font-size: inherit;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(.375rem + 1px);
    padding-top: calc(.375rem + 1px)
}

.col-form-label-lg {
    font-size: 1.25rem;
    line-height: 1.5;
    padding-bottom: calc(.5rem + 1px);
    padding-top: calc(.5rem + 1px)
}

.col-form-label-sm {
    font-size: .875rem;
    line-height: 1.5;
    padding-bottom: calc(.25rem + 1px);
    padding-top: calc(.25rem + 1px)
}

.form-control-plaintext {
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    color: #212529;
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .375rem 0;
    width: 100%
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-left: 0;
    padding-right: 0
}

.form-control-sm {
    border-radius: .2rem;
    font-size: .875rem;
    height: calc(1.5em + .5rem + 2px);
    line-height: 1.5;
    padding: .25rem .5rem
}

.form-control-lg {
    border-radius: .3rem;
    font-size: 1.25rem;
    height: calc(1.5em + 1rem + 2px);
    line-height: 1.5;
    padding: .5rem 1rem
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-left: 5px;
    padding-right: 5px
}

.form-check {
    display: block;
    padding-left: 1.25rem;
    position: relative
}

.form-check-input {
    margin-left: -1.25rem;
    margin-top: .3rem;
    position: absolute
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    align-items: center;
    display: inline-flex;
    margin-right: .75rem;
    padding-left: 0
}

.form-check-inline .form-check-input {
    margin-left: 0;
    margin-right: .3125rem;
    margin-top: 0;
    position: static
}

.valid-feedback {
    color: #28a745;
    display: none;
    font-size: 80%;
    margin-top: .25rem;
    width: 100%
}

.valid-tooltip {
    background-color: rgba(40, 167, 69, .9);
    border-radius: .25rem;
    color: #fff;
    display: none;
    font-size: .875rem;
    left: 0;
    line-height: 1.5;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.form-row>.col>.valid-tooltip,
.form-row>[class*=col-]>.valid-tooltip {
    left: 5px
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    border-color: #28a745;
    padding-right: calc(1.5em + .75rem) !important
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
    background-position: right 1.5rem center;
    padding-right: 3rem !important
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
    padding-right: calc(1.5em + .75rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
    border-color: #28a745;
    padding-right: calc(.75em + 2.3125rem) !important
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #28a745
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    background-color: #34ce57;
    border-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before {
    border-color: #28a745
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28a745
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: 80%;
    margin-top: .25rem;
    width: 100%
}

.invalid-tooltip {
    background-color: rgba(220, 53, 69, .9);
    border-radius: .25rem;
    color: #fff;
    display: none;
    font-size: .875rem;
    left: 0;
    line-height: 1.5;
    margin-top: .1rem;
    max-width: 100%;
    padding: .25rem .5rem;
    position: absolute;
    top: 100%;
    z-index: 5
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*=col-]>.invalid-tooltip {
    left: 5px
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right calc(.375em + .1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem) !important
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
    background-position: right 1.5rem center;
    padding-right: 3rem !important
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
    padding-right: calc(1.5em + .75rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
    border-color: #dc3545;
    padding-right: calc(.75em + 2.3125rem) !important
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #dc3545
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    background-color: #e4606d;
    border-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before {
    border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    align-items: center;
    display: flex;
    flex-flow: row wrap
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        justify-content: center
    }

    .form-inline .form-group,
    .form-inline label {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    .form-inline .form-group {
        flex: 0 0 auto;
        flex-flow: row wrap
    }

    .form-inline .form-control {
        display: inline-block;
        vertical-align: middle;
        width: auto
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-left: 0;
        width: auto
    }

    .form-inline .form-check-input {
        flex-shrink: 0;
        margin-left: 0;
        margin-right: .25rem;
        margin-top: 0;
        position: relative
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: #212529;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    outline: 0
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
    color: #fff
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #0062cc;
    border-color: #005cbf;
    color: #fff
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
    color: #fff
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem hsla(208, 6%, 54%, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #545b62;
    border-color: #4e555b;
    color: #fff
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(208, 6%, 54%, .5)
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    background-color: #218838;
    border-color: #1e7e34;
    color: #fff
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    background-color: #1e7e34;
    border-color: #1c7430;
    color: #fff
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
    background-color: #138496;
    border-color: #117a8b;
    color: #fff
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    background-color: #117a8b;
    border-color: #10707f;
    color: #fff
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
    background-color: #e0a800;
    border-color: #d39e00;
    color: #212529
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    background-color: #d39e00;
    border-color: #c69500;
    color: #212529
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5)
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
    color: #fff
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    background-color: #bd2130;
    border-color: #b21f2d;
    color: #fff
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-light {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #212529
}

.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
    color: #212529
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #212529
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    background-color: #dae0e5;
    border-color: #d3d9df;
    color: #212529
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5)
}

.btn-dark {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
    background-color: #23272b;
    border-color: #1d2124;
    color: #fff
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    background-color: #1d2124;
    border-color: #171a1d;
    color: #fff
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-outline-primary {
    border-color: #007bff;
    color: #007bff
}

.btn-outline-primary:hover {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    background-color: transparent;
    color: #007bff
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-secondary {
    border-color: #6c757d;
    color: #6c757d
}

.btn-outline-secondary:hover {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem hsla(208, 7%, 46%, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    background-color: transparent;
    color: #6c757d
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(208, 7%, 46%, .5)
}

.btn-outline-success {
    border-color: #28a745;
    color: #28a745
}

.btn-outline-success:hover {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    background-color: transparent;
    color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
    border-color: #17a2b8;
    color: #17a2b8
}

.btn-outline-info:hover {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    background-color: transparent;
    color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    border-color: #ffc107;
    color: #ffc107
}

.btn-outline-warning:hover {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    background-color: transparent;
    color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.btn-outline-danger {
    border-color: #dc3545;
    color: #dc3545
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    background-color: transparent;
    color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    border-color: #f8f9fa;
    color: #f8f9fa
}

.btn-outline-light:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #212529
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    background-color: transparent;
    color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #212529
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    border-color: #343a40;
    color: #343a40
}

.btn-outline-dark:hover {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    background-color: transparent;
    color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5)
}

.btn-link {
    color: #007bff;
    font-weight: 400;
    text-decoration: none
}

.btn-link:hover {
    color: #0056b3
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    border-radius: .3rem;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: .5rem 1rem
}

.btn-group-sm>.btn,
.btn-sm {
    border-radius: .2rem;
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    position: relative;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    border-bottom: 0;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    color: #212529;
    display: none;
    float: left;
    font-size: 1rem;
    left: 0;
    list-style: none;
    margin: .125rem 0 0;
    min-width: 10rem;
    padding: .5rem 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: 1000
}

.dropdown-menu-left {
    left: 0;
    right: auto
}

.dropdown-menu-right {
    left: auto;
    right: 0
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        left: 0;
        right: auto
    }

    .dropdown-menu-sm-right {
        left: auto;
        right: 0
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        left: 0;
        right: auto
    }

    .dropdown-menu-md-right {
        left: auto;
        right: 0
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        left: 0;
        right: auto
    }

    .dropdown-menu-lg-right {
        left: auto;
        right: 0
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        left: 0;
        right: auto
    }

    .dropdown-menu-xl-right {
        left: auto;
        right: 0
    }
}

.dropup .dropdown-menu {
    bottom: 100%;
    margin-bottom: .125rem;
    margin-top: 0;
    top: auto
}

.dropup .dropdown-toggle:after {
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: 0;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-menu {
    left: 100%;
    margin-left: .125rem;
    margin-top: 0;
    right: auto;
    top: 0
}

.dropright .dropdown-toggle:after {
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
    border-right: 0;
    border-top: .3em solid transparent;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em
}

.dropright .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-toggle:after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    left: auto;
    margin-right: .125rem;
    margin-top: 0;
    right: 100%;
    top: 0
}

.dropleft .dropdown-toggle:after {
    content: "";
    display: inline-block;
    display: none;
    margin-left: .255em;
    vertical-align: .255em
}

.dropleft .dropdown-toggle:before {
    border-bottom: .3em solid transparent;
    border-right: .3em solid;
    border-top: .3em solid transparent;
    content: "";
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em
}

.dropleft .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    bottom: auto;
    right: auto
}

.dropdown-divider {
    border-top: 1px solid #e9ecef;
    height: 0;
    margin: .5rem 0;
    overflow: hidden
}

.dropdown-item {
    background-color: transparent;
    border: 0;
    clear: both;
    color: #212529;
    display: block;
    font-weight: 400;
    padding: .25rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #e9ecef;
    color: #16181b;
    text-decoration: none
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #007bff;
    color: #fff;
    text-decoration: none
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    background-color: transparent;
    color: #adb5bd;
    pointer-events: none
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    color: #6c757d;
    display: block;
    font-size: .875rem;
    margin-bottom: 0;
    padding: .5rem 1.5rem;
    white-space: nowrap
}

.dropdown-item-text {
    color: #212529;
    display: block;
    padding: .25rem 1.5rem
}

.btn-group,
.btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: 1 1 auto;
    position: relative
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.dropdown-toggle-split {
    padding-left: .5625rem;
    padding-right: .5625rem
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-left: .375rem;
    padding-right: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-left: .75rem;
    padding-right: .75rem
}

.btn-group-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    position: absolute
}

.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
    width: 1%
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.input-group>.custom-file {
    align-items: center;
    display: flex
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation>.custom-file:nth-last-child(n+3) .custom-file-label:after,
.input-group.has-validation>.custom-select:nth-last-child(n+3),
.input-group.has-validation>.form-control:nth-last-child(n+3),
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label:after,
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    align-items: center;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0;
    padding: .375rem .75rem;
    text-align: center;
    white-space: nowrap
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 1rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    border-radius: .3rem;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: .5rem 1rem
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .5rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    border-radius: .2rem;
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem
}

.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn,
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn,
.input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.custom-control {
    color-adjust: exact;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
    -webkit-print-color-adjust: exact;
    z-index: 1
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    height: 1.25rem;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 1rem;
    z-index: -1
}

.custom-control-input:checked~.custom-control-label:before {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}

.custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #80bdff
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
    color: #fff
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #6c757d
}

.custom-control-input:disabled~.custom-control-label:before,
.custom-control-input[disabled]~.custom-control-label:before {
    background-color: #e9ecef
}

.custom-control-label {
    margin-bottom: 0;
    position: relative;
    vertical-align: top
}

.custom-control-label:before {
    background-color: #fff;
    border: 1px solid #adb5bd;
    pointer-events: none
}

.custom-control-label:after,
.custom-control-label:before {
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    position: absolute;
    top: .25rem;
    width: 1rem
}

.custom-control-label:after {
    background: 50%/50% 50% no-repeat
}

.custom-checkbox .custom-control-label:before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='m6.564.75-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
    background-color: #007bff;
    border-color: #007bff
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-radio .custom-control-label:before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-switch {
    padding-left: 2.25rem
}

.custom-switch .custom-control-label:before {
    border-radius: .5rem;
    left: -2.25rem;
    pointer-events: all;
    width: 1.75rem
}

.custom-switch .custom-control-label:after {
    background-color: #adb5bd;
    border-radius: .5rem;
    height: calc(1rem - 4px);
    left: calc(-2.25rem + 2px);
    top: calc(.25rem + 2px);
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: calc(1rem - 4px)
}

@media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label:after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(0, 123, 255, .5)
}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    padding: .375rem 1.75rem .375rem .75rem;
    vertical-align: middle;
    width: 100%
}

.custom-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    outline: 0
}

.custom-select:focus::-ms-value {
    background-color: #fff;
    color: #495057
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: .75rem
}

.custom-select:disabled {
    background-color: #e9ecef;
    color: #6c757d
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057
}

.custom-select-sm {
    font-size: .875rem;
    height: calc(1.5em + .5rem + 2px);
    padding-bottom: .25rem;
    padding-left: .5rem;
    padding-top: .25rem
}

.custom-select-lg {
    font-size: 1.25rem;
    height: calc(1.5em + 1rem + 2px);
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-top: .5rem
}

.custom-file {
    display: inline-block;
    margin-bottom: 0
}

.custom-file,
.custom-file-input {
    height: calc(1.5em + .75rem + 2px);
    position: relative;
    width: 100%
}

.custom-file-input {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    z-index: 2
}

.custom-file-input:focus~.custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
    background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label:after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]:after {
    content: attr(data-browse)
}

.custom-file-label {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    font-weight: 400;
    height: calc(1.5em + .75rem + 2px);
    left: 0;
    overflow: hidden;
    z-index: 1
}

.custom-file-label,
.custom-file-label:after {
    color: #495057;
    line-height: 1.5;
    padding: .375rem .75rem;
    position: absolute;
    right: 0;
    top: 0
}

.custom-file-label:after {
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
    bottom: 0;
    content: "Browse";
    display: block;
    height: calc(1.5em + .75rem);
    z-index: 3
}

.custom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 1.4rem;
    padding: 0;
    width: 100%
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b3d7ff
}

.custom-range::-webkit-slider-runnable-track {
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.custom-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #b3d7ff
}

.custom-range::-moz-range-track {
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.custom-range::-ms-thumb {
    appearance: none;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-left: .2rem;
    margin-right: .2rem;
    margin-top: 0;
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #b3d7ff
}

.custom-range::-ms-track {
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    color: transparent;
    cursor: pointer;
    height: .5rem;
    width: 100%
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
    background-color: #dee2e6;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

    .custom-control-label:before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d;
    cursor: default;
    pointer-events: none
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    margin-bottom: -1px
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent;
    color: #6c757d
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    color: #495057
}

.nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #007bff;
    color: #fff
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    padding: .5rem 1rem;
    position: relative
}

.navbar,
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    font-size: 1.25rem;
    line-height: inherit;
    margin-right: 1rem;
    padding-bottom: .3125rem;
    padding-top: .3125rem;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0
}

.navbar-nav .dropdown-menu {
    float: none;
    position: static
}

.navbar-text {
    display: inline-block;
    padding-bottom: .5rem;
    padding-top: .5rem
}

.navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1
}

.navbar-toggler {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: 1.25rem;
    line-height: 1;
    padding: .25rem .75rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    background: 50%/100% 100% no-repeat;
    content: "";
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em
}

.navbar-nav-scroll {
    max-height: 75vh;
    overflow-y: auto
}

@media (max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        padding-left: 0;
        padding-right: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    padding-left: 0;
    padding-right: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    border-color: hsla(0, 0%, 100%, .1);
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative
}

.card>hr {
    margin-left: 0;
    margin-right: 0
}

.card>.list-group {
    border-bottom: inherit;
    border-top: inherit
}

.card>.list-group:first-child {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
    border-top-width: 0
}

.card>.list-group:last-child {
    border-bottom-left-radius: calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-width: 0
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 0;
    padding: .75rem 1.25rem
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125);
    padding: .75rem 1.25rem
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    border-bottom: 0;
    margin-bottom: -.75rem
}

.card-header-pills,
.card-header-tabs {
    margin-left: -.625rem;
    margin-right: -.625rem
}

.card-img-overlay {
    border-radius: calc(.25rem - 1px);
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    position: absolute;
    right: 0;
    top: 0
}

.card-img,
.card-img-bottom,
.card-img-top {
    flex-shrink: 0;
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-left-radius: calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px)
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-left: -15px;
        margin-right: -15px
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-bottom: 0;
        margin-left: 15px;
        margin-right: 15px
    }
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        border-left: 0;
        margin-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion {
    overflow-anchor: none
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px
}

.breadcrumb {
    background-color: #e9ecef;
    border-radius: .25rem;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 1rem;
    padding: .75rem 1rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item:before {
    color: #6c757d;
    content: "/";
    float: left;
    padding-right: .5rem
}

.breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    border-radius: .25rem;
    display: flex;
    list-style: none;
    padding-left: 0
}

.page-link {
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #007bff;
    display: block;
    line-height: 1.25;
    margin-left: -1px;
    padding: .5rem .75rem;
    position: relative
}

.page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #0056b3;
    text-decoration: none;
    z-index: 2
}

.page-link:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    outline: 0;
    z-index: 3
}

.page-item:first-child .page-link {
    border-bottom-left-radius: .25rem;
    border-top-left-radius: .25rem;
    margin-left: 0
}

.page-item:last-child .page-link {
    border-bottom-right-radius: .25rem;
    border-top-right-radius: .25rem
}

.page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    z-index: 3
}

.page-item.disabled .page-link {
    background-color: #fff;
    border-color: #dee2e6;
    color: #6c757d;
    cursor: auto;
    pointer-events: none
}

.pagination-lg .page-link {
    font-size: 1.25rem;
    line-height: 1.5;
    padding: .75rem 1.5rem
}

.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: .3rem;
    border-top-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: .3rem;
    border-top-right-radius: .3rem
}

.pagination-sm .page-link {
    font-size: .875rem;
    line-height: 1.5;
    padding: .25rem .5rem
}

.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: .2rem;
    border-top-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: .2rem;
    border-top-right-radius: .2rem
}

.badge {
    border-radius: .25rem;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    padding: .25em .4em;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    vertical-align: baseline;
    white-space: nowrap
}

@media (prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    border-radius: 10rem;
    padding-left: .6em;
    padding-right: .6em
}

.badge-primary {
    background-color: #007bff;
    color: #fff
}

a.badge-primary:focus,
a.badge-primary:hover {
    background-color: #0062cc;
    color: #fff
}

a.badge-primary.focus,
a.badge-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
    outline: 0
}

.badge-secondary {
    background-color: #6c757d;
    color: #fff
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    background-color: #545b62;
    color: #fff
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    box-shadow: 0 0 0 .2rem hsla(208, 7%, 46%, .5);
    outline: 0
}

.badge-success {
    background-color: #28a745;
    color: #fff
}

a.badge-success:focus,
a.badge-success:hover {
    background-color: #1e7e34;
    color: #fff
}

a.badge-success.focus,
a.badge-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
    outline: 0
}

.badge-info {
    background-color: #17a2b8;
    color: #fff
}

a.badge-info:focus,
a.badge-info:hover {
    background-color: #117a8b;
    color: #fff
}

a.badge-info.focus,
a.badge-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
    outline: 0
}

.badge-warning {
    background-color: #ffc107;
    color: #212529
}

a.badge-warning:focus,
a.badge-warning:hover {
    background-color: #d39e00;
    color: #212529
}

a.badge-warning.focus,
a.badge-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
    outline: 0
}

.badge-danger {
    background-color: #dc3545;
    color: #fff
}

a.badge-danger:focus,
a.badge-danger:hover {
    background-color: #bd2130;
    color: #fff
}

a.badge-danger.focus,
a.badge-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
    outline: 0
}

.badge-light {
    background-color: #f8f9fa;
    color: #212529
}

a.badge-light:focus,
a.badge-light:hover {
    background-color: #dae0e5;
    color: #212529
}

a.badge-light.focus,
a.badge-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
    outline: 0
}

.badge-dark {
    background-color: #343a40;
    color: #fff
}

a.badge-dark:focus,
a.badge-dark:hover {
    background-color: #1d2124;
    color: #fff
}

a.badge-dark.focus,
a.badge-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
    outline: 0
}

.jumbotron {
    background-color: #e9ecef;
    border-radius: .3rem;
    margin-bottom: 2rem;
    padding: 2rem 1rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0
}

.alert {
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-bottom: 1rem;
    padding: .75rem 1.25rem;
    position: relative
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    color: inherit;
    padding: .75rem 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

.alert-primary {
    background-color: #cce5ff;
    border-color: #b8daff;
    color: #004085
}

.alert-primary hr {
    border-top-color: #9fcdff
}

.alert-primary .alert-link {
    color: #002752
}

.alert-secondary {
    background-color: #e2e3e5;
    border-color: #d6d8db;
    color: #383d41
}

.alert-secondary hr {
    border-top-color: #c8cbcf
}

.alert-secondary .alert-link {
    color: #202326
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-success .alert-link {
    color: #0b2e13
}

.alert-info {
    background-color: #d1ecf1;
    border-color: #bee5eb;
    color: #0c5460
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    background-color: #fefefe;
    border-color: #fdfdfe;
    color: #818182
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    background-color: #d6d8d9;
    border-color: #c6c8ca;
    color: #1b1e21
}

.alert-dark hr {
    border-top-color: #b9bbbe
}

.alert-dark .alert-link {
    color: #040505
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    background-color: #e9ecef;
    border-radius: .25rem;
    font-size: .75rem;
    height: 1rem;
    line-height: 0
}

.progress,
.progress-bar {
    display: flex;
    overflow: hidden
}

.progress-bar {
    background-color: #007bff;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: width .6s ease;
    white-space: nowrap
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.media {
    align-items: flex-start;
    display: flex
}

.media-body {
    flex: 1
}

.list-group {
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0
}

.list-group-item-action {
    color: #495057;
    text-align: inherit;
    width: 100%
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: #f8f9fa;
    color: #495057;
    text-decoration: none;
    z-index: 1
}

.list-group-item-action:active {
    background-color: #e9ecef;
    color: #212529
}

.list-group-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    display: block;
    padding: .75rem 1.25rem;
    position: relative
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    background-color: #fff;
    color: #6c757d;
    pointer-events: none
}

.list-group-item.active {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    z-index: 2
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    border-top-width: 1px;
    margin-top: -1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: .25rem
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    background-color: #b8daff;
    color: #004085
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    background-color: #9fcdff;
    color: #004085
}

.list-group-item-primary.list-group-item-action.active {
    background-color: #004085;
    border-color: #004085;
    color: #fff
}

.list-group-item-secondary {
    background-color: #d6d8db;
    color: #383d41
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    background-color: #c8cbcf;
    color: #383d41
}

.list-group-item-secondary.list-group-item-action.active {
    background-color: #383d41;
    border-color: #383d41;
    color: #fff
}

.list-group-item-success {
    background-color: #c3e6cb;
    color: #155724
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    background-color: #b1dfbb;
    color: #155724
}

.list-group-item-success.list-group-item-action.active {
    background-color: #155724;
    border-color: #155724;
    color: #fff
}

.list-group-item-info {
    background-color: #bee5eb;
    color: #0c5460
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    background-color: #abdde5;
    color: #0c5460
}

.list-group-item-info.list-group-item-action.active {
    background-color: #0c5460;
    border-color: #0c5460;
    color: #fff
}

.list-group-item-warning {
    background-color: #ffeeba;
    color: #856404
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    background-color: #ffe8a1;
    color: #856404
}

.list-group-item-warning.list-group-item-action.active {
    background-color: #856404;
    border-color: #856404;
    color: #fff
}

.list-group-item-danger {
    background-color: #f5c6cb;
    color: #721c24
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    background-color: #f1b0b7;
    color: #721c24
}

.list-group-item-danger.list-group-item-action.active {
    background-color: #721c24;
    border-color: #721c24;
    color: #fff
}

.list-group-item-light {
    background-color: #fdfdfe;
    color: #818182
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    background-color: #ececf6;
    color: #818182
}

.list-group-item-light.list-group-item-action.active {
    background-color: #818182;
    border-color: #818182;
    color: #fff
}

.list-group-item-dark {
    background-color: #c6c8ca;
    color: #1b1e21
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    background-color: #b9bbbe;
    color: #1b1e21
}

.list-group-item-dark.list-group-item-action.active {
    background-color: #1b1e21;
    border-color: #1b1e21;
    color: #fff
}

.close {
    color: #000;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    text-shadow: 0 1px 0 #fff
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    background-color: transparent;
    border: 0;
    padding: 0
}

a.close.disabled {
    pointer-events: none
}

.toast {
    background-clip: padding-box;
    background-color: hsla(0, 0%, 100%, .85);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
    flex-basis: 350px;
    font-size: .875rem;
    max-width: 350px;
    opacity: 0
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    align-items: center;
    background-clip: padding-box;
    background-color: hsla(0, 0%, 100%, .85);
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
    color: #6c757d;
    display: flex;
    padding: .25rem .75rem
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050
}

.modal-dialog {
    margin: .5rem;
    pointer-events: none;
    position: relative;
    width: auto
}

.modal.fade .modal-dialog {
    transform: translateY(-50px);
    transition: transform .3s ease-out
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    align-items: center;
    display: flex;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
    content: "";
    display: block;
    height: calc(100vh - 1rem);
    height: -moz-min-content;
    height: min-content
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    height: 100%;
    justify-content: center
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none
}

.modal-content {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%
}

.modal-backdrop {
    background-color: #000;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1040
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    align-items: flex-start;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    justify-content: space-between;
    padding: 1rem
}

.modal-header .close {
    margin: -1rem -1rem -1rem auto;
    padding: 1rem
}

.modal-title {
    line-height: 1.5;
    margin-bottom: 0
}

.modal-body {
    flex: 1 1 auto;
    padding: 1rem;
    position: relative
}

.modal-footer {
    align-items: center;
    border-bottom-left-radius: calc(.3rem - 1px);
    border-bottom-right-radius: calc(.3rem - 1px);
    border-top: 1px solid #dee2e6;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: .75rem
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    height: 50px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
    width: 50px
}

@media (min-width:576px) {
    .modal-dialog {
        margin: 1.75rem auto;
        max-width: 500px
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem);
        height: -moz-min-content;
        height: min-content
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    word-wrap: break-word;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1070
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    display: block;
    height: .4rem;
    position: absolute;
    width: .8rem
}

.tooltip .arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    position: absolute
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow:before,
.bs-tooltip-top .arrow:before {
    border-top-color: #000;
    border-width: .4rem .4rem 0;
    top: 0
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    height: .8rem;
    left: 0;
    width: .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow:before,
.bs-tooltip-right .arrow:before {
    border-right-color: #000;
    border-width: .4rem .4rem .4rem 0;
    right: 0
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #000;
    border-width: 0 .4rem .4rem;
    bottom: 0
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    height: .8rem;
    right: 0;
    width: .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow:before,
.bs-tooltip-left .arrow:before {
    border-left-color: #000;
    border-width: .4rem 0 .4rem .4rem;
    left: 0
}

.tooltip-inner {
    background-color: #000;
    border-radius: .25rem;
    color: #fff;
    max-width: 200px;
    padding: .25rem .5rem;
    text-align: center
}

.popover {
    word-wrap: break-word;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    left: 0;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    max-width: 276px;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    top: 0;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1060
}

.popover,
.popover .arrow {
    display: block;
    position: absolute
}

.popover .arrow {
    height: .5rem;
    margin: 0 .3rem;
    width: 1rem
}

.popover .arrow:after,
.popover .arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    display: block;
    position: absolute
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top]>.arrow:before,
.bs-popover-top>.arrow:before {
    border-top-color: rgba(0, 0, 0, .25);
    border-width: .5rem .5rem 0;
    bottom: 0
}

.bs-popover-auto[x-placement^=top]>.arrow:after,
.bs-popover-top>.arrow:after {
    border-top-color: #fff;
    border-width: .5rem .5rem 0;
    bottom: 1px
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
    height: 1rem;
    left: calc(-.5rem - 1px);
    margin: .3rem 0;
    width: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow:before,
.bs-popover-right>.arrow:before {
    border-right-color: rgba(0, 0, 0, .25);
    border-width: .5rem .5rem .5rem 0;
    left: 0
}

.bs-popover-auto[x-placement^=right]>.arrow:after,
.bs-popover-right>.arrow:after {
    border-right-color: #fff;
    border-width: .5rem .5rem .5rem 0;
    left: 1px
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom]>.arrow:before,
.bs-popover-bottom>.arrow:before {
    border-bottom-color: rgba(0, 0, 0, .25);
    border-width: 0 .5rem .5rem;
    top: 0
}

.bs-popover-auto[x-placement^=bottom]>.arrow:after,
.bs-popover-bottom>.arrow:after {
    border-bottom-color: #fff;
    border-width: 0 .5rem .5rem;
    top: 1px
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    border-bottom: 1px solid #f7f7f7;
    content: "";
    display: block;
    left: 50%;
    margin-left: -.5rem;
    position: absolute;
    top: 0;
    width: 1rem
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
    height: 1rem;
    margin: .3rem 0;
    right: calc(-.5rem - 1px);
    width: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow:before,
.bs-popover-left>.arrow:before {
    border-left-color: rgba(0, 0, 0, .25);
    border-width: .5rem 0 .5rem .5rem;
    right: 0
}

.bs-popover-auto[x-placement^=left]>.arrow:after,
.bs-popover-left>.arrow:after {
    border-left-color: #fff;
    border-width: .5rem 0 .5rem .5rem;
    right: 1px
}

.popover-header {
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    font-size: 1rem;
    margin-bottom: 0;
    padding: .5rem .75rem
}

.popover-header:empty {
    display: none
}

.popover-body {
    color: #212529;
    padding: .5rem .75rem
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%
}

.carousel-inner:after {
    clear: both;
    content: "";
    display: block
}

.carousel-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
    float: left;
    margin-right: -100%;
    position: relative;
    transition: transform .6s ease-in-out;
    width: 100%
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transform: none;
    transition-property: opacity
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    opacity: 1;
    z-index: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
    transition: opacity 0s .6s;
    z-index: 0
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    align-items: center;
    background: 0 0;
    border: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    opacity: .5;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity .15s ease;
    width: 15%;
    z-index: 1
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    opacity: .9;
    outline: 0;
    text-decoration: none
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background: 50%/100% 100% no-repeat;
    display: inline-block;
    height: 20px;
    width: 20px
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='m5.25 0-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8'%3E%3Cpath d='m2.75 0-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    list-style: none;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 0;
    position: absolute;
    right: 0;
    z-index: 15
}

.carousel-indicators li {
    background-clip: padding-box;
    background-color: #fff;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    flex: 0 1 auto;
    height: 3px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: .5;
    text-indent: -999px;
    transition: opacity .6s ease;
    width: 30px
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    bottom: 20px;
    color: #fff;
    left: 15%;
    padding-bottom: 20px;
    padding-top: 20px;
    position: absolute;
    right: 15%;
    text-align: center;
    z-index: 10
}

@keyframes spinner-border {
    to {
        transform: rotate(1turn)
    }
}

.spinner-border {
    animation: spinner-border .75s linear infinite;
    border: .25em solid;
    border-radius: 50%;
    border-right: .25em solid transparent;
    display: inline-block;
    height: 2rem;
    vertical-align: -.125em;
    width: 2rem
}

.spinner-border-sm {
    border-width: .2em;
    height: 1rem;
    width: 1rem
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    animation: spinner-grow .75s linear infinite;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    height: 2rem;
    opacity: 0;
    vertical-align: -.125em;
    width: 2rem
}

.spinner-grow-sm {
    height: 1rem;
    width: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        animation-duration: 1.5s
    }
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #007bff !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0062cc !important
}

.bg-secondary {
    background-color: #6c757d !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #545b62 !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-top {
    border-top: 1px solid #dee2e6 !important
}

.border-right {
    border-right: 1px solid #dee2e6 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-left {
    border-left: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #007bff !important
}

.border-secondary {
    border-color: #6c757d !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #343a40 !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .2rem !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important
}

.rounded-right,
.rounded-top {
    border-top-right-radius: .25rem !important
}

.rounded-bottom,
.rounded-right {
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom,
.rounded-left {
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix:after {
    clear: both;
    content: "";
    display: block
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%
}

.embed-responsive:before {
    content: "";
    display: block
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.embed-responsive-21by9:before {
    padding-top: 42.85714286%
}

.embed-responsive-16by9:before {
    padding-top: 56.25%
}

.embed-responsive-4by3:before {
    padding-top: 75%
}

.embed-responsive-1by1:before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.fixed-top {
    top: 0
}

.fixed-bottom,
.fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

@supports (position:sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5,
.my-5 {
    margin-top: 3rem !important
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pr-5,
.px-5 {
    padding-right: 3rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
    padding-left: 3rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.stretched-link:after {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #007bff !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #0056b3 !important
}

.text-secondary {
    color: #6c757d !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #494f54 !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #19692c !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #0f6674 !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #ba8b00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #a71d2a !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #343a40 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #121416 !important
}

.text-body {
    color: #212529 !important
}

.text-muted {
    color: #6c757d !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5) !important
}

.text-hide {
    background-color: transparent;
    border: 0;
    color: transparent;
    font: 0/0 a;
    text-shadow: none
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    :after,
    :before {
        box-shadow: none !important;
        text-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #adb5bd
    }

    blockquote,
    img,
    pre,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container,
    body {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #dee2e6 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #dee2e6
    }

    .table .thead-dark th {
        border-color: #dee2e6;
        color: inherit
    }
}

.body_buffer {
    height: 100px;
    position: relative;
    width: 100%;
    z-index: 1030
}

@media (min-width:770px) {
    .body_buffer {
        height: 96px
    }
}

.body_buffer_homepage,
.us-homepage .body_buffer {
    height: 54px;
    position: relative
}

.local_nav.fixed_nav {
    top: 54px
}

.dropdown-toggle:after {
    content: none
}

.nav-flag {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 28px;
    height: 54px;
    position: absolute;
    right: 0;
    transition: all .1s linear;
    width: 54px;
    z-index: 1000
}

.global-nav-spacing {
    display: block;
    transition: all .1s linear
}

@media (max-width:991px) {
    .global-nav-spacing {
        height: 54px
    }
}

.global-nav-spacing .global-nav-container {
    background-color: #000;
    display: block;
    width: 100%;
    z-index: 9000
}

.global-nav-spacing .global-nav-container ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.global-nav-spacing .global-nav-container .global-nav {
    display: block;
    height: 100px;
    margin: auto;
    padding: 0;
    position: relative;
    transition: all .1s linear
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav {
        height: 54px
    }
}

.global-nav-spacing .global-nav-container .global-nav .hamburger {
    background-color: #000;
    border: none;
    display: none;
    margin: 14px 0 0;
    padding: 0
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .hamburger {
        display: inline-block
    }
}

.global-nav-spacing .global-nav-container .global-nav .hamburger:hover {
    opacity: 1
}

.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner {
    left: 7px;
    top: 5px
}

.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner:before {
    top: 8px
}

.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner:after {
    top: 16px
}

.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner,
.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner:after,
.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-inner:before {
    background-color: #fff;
    border-radius: 0;
    height: 3px;
    width: 25px
}

.global-nav-spacing .global-nav-container .global-nav .hamburger .hamburger-box:focus,
.global-nav-spacing .global-nav-container .global-nav .hamburger:focus {
    outline: none
}

.global-nav-spacing .global-nav-container .global-nav .hamburger--slider.is-active {
    opacity: .7
}

.global-nav-spacing .global-nav-container .global-nav .hamburger--slider.is-active .hamburger-inner:after {
    transform: translate3d(0, -16px, 0) rotate(-90deg)
}

.global-nav-spacing .global-nav-container .global-nav.scroll-shrink {
    height: 54px
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-img {
    margin-top: 15px
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-exit {
    margin-top: 20px
}

.global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container {
    float: left;
    max-width: 225px;
    min-width: 140px;
    padding: 6px 15px;
    vertical-align: top;
    width: 170px
}

@media (max-width:360px) {
    .global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container {
        padding: 6px 0
    }
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container {
        padding: 6px 15px
    }
}

.global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container .global-nav-logo {
    max-height: 42px
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container .global-nav-logo {
        max-height: 42px
    }
}

.global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container .logo-link {
    display: block;
    max-height: 74px
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .global-nav-logo-container .logo-link {
        max-height: 42px
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container {
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    margin-left: 10px;
    margin-right: 0;
    position: absolute;
    right: 58px;
    vertical-align: top;
    width: 25px;
    z-index: 1030
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container {
        transition: none
    }
}

@media (max-width:360px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container {
        margin-left: 0;
        width: 25px
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search {
    background-color: transparent;
    border: 1px solid transparent;
    height: 54px;
    outline: none;
    transition: margin-top .2s linear;
    width: 25px
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search::-moz-placeholder {
    color: #fff
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search:-ms-input-placeholder {
    color: #fff
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search::placeholder {
    color: #fff
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search {
        height: 54px;
        margin-top: 0
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container label {
    display: inline
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .search-results-container {
    display: none;
    max-height: 0;
    overflow: hidden
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container:not(.searching) label[for=nav-search] {
    display: none
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching {
    margin-left: 224px;
    max-width: calc(100% - 450px);
    position: absolute;
    right: auto;
    transition: width .7s ease-in-out;
    width: 100%
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching {
        margin-left: 0;
        max-width: 100%;
        transition: none;
        width: 100%
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching input.nav-search {
    background-color: #565656;
    border: 1px solid #565656;
    font-size: 16px;
    font-weight: 100;
    height: 54px;
    padding: 0 35px;
    width: 100%
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching input.nav-search::-moz-placeholder {
    color: #c4c4c4;
    font-size: 15px;
    font-weight: 100
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching input.nav-search:-ms-input-placeholder {
    color: #c4c4c4;
    font-size: 15px;
    font-weight: 100
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching input.nav-search::placeholder {
    color: #c4c4c4;
    font-size: 15px;
    font-weight: 100
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .nav-search-img {
    margin-left: 5px
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: 0 0 8px #8e8e8e;
    display: block;
    font-size: 16px;
    font-weight: 100;
    max-height: 500px;
    overflow-y: scroll;
    transition: "max-height" .2s linear;
    width: 100%
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container {
        max-height: calc(100vh - 54px)
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container.android-search {
        max-height: calc(100vh - 108px)
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li {
    color: #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 54px;
    padding: 0 20px;
    width: 100%
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a {
    align-items: center;
    color: #333;
    display: flex;
    padding: 8px 15px 8px 35px;
    text-decoration: none
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a {
        display: block
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-content {
    display: inline-block;
    width: 70%
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-content {
        width: 100%
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-type {
    color: #c4c4c4;
    display: none;
    font-size: 14px;
    text-transform: capitalize;
    width: 30%
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-type span {
    float: right
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-type {
        float: none;
        margin-top: 0;
        text-transform: uppercase;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li a .link-type span {
        float: none
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li:hover {
    background-color: #ececec;
    cursor: pointer;
    transition: all .3s linear
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li:hover {
        background-color: initial
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li.search-no-result {
    border-bottom: 1px solid #333;
    color: #333;
    font-size: 15px;
    font-weight: 100;
    padding: 5px;
    text-align: center
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container li.search-no-result:hover {
    background-color: inherit;
    cursor: inherit;
    transition: none
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container .load-more {
    border-top: 1px solid #d2d0d0;
    color: #333;
    font-size: 15px;
    font-weight: 100;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    width: 100%
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .search-results-container .load-more:hover {
    background-color: #1e84cc;
    color: #fff;
    cursor: pointer;
    transition: all .3s linear
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .nav-search-exit {
    transition: all .3s linear;
    transition-delay: .2s;
    width: 15px
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .nav-search-exit {
        transition: unset;
        transition-delay: unset
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-icon {
    margin-left: 15px;
    margin-top: 1rem;
    position: absolute;
    z-index: 1000
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-exit {
    cursor: pointer;
    height: 15px;
    margin-right: 18px;
    margin-top: 1rem !important;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    z-index: 1000
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-exit {
        margin-top: 18px
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-img {
    cursor: pointer;
    height: 25px;
    left: 0;
    margin-top: 30px;
    position: absolute;
    top: 0;
    transition: all .12s linear;
    width: 25px
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-img {
        margin-top: 15px
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container {
    float: left;
    vertical-align: top
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container {
        left: 0;
        position: absolute;
        width: 100%
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links {
    height: 54px;
    line-height: 54px;
    padding: 0;
    top: 54px;
    transition: all .1s linear
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links {
        background-color: #fff;
        left: 0;
        line-height: 54px;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 54px;
        vertical-align: top;
        width: 100%
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links a {
    color: #fff;
    display: inline-block
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links a {
        display: block
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links a:hover {
    color: #c4c4c4;
    cursor: pointer;
    text-decoration: none
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links.is-active {
    -webkit-overflow-scrolling: touch;
    height: auto;
    max-height: calc(100vh - 54px);
    overflow: scroll;
    overflow: auto;
    position: fixed;
    transition: max-height .5s ease-out;
    z-index: 1030
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links.is-active.android-nav {
        max-height: calc(100vh - 108px)
    }
}

@media (min-width:992px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-links-spacer {
        margin: 0;
        padding: 0;
        width: 100%
    }
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 0 !important;
    text-align: center
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a,
.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.nav-dropdown {
    min-width: 80px;
    padding: 0 10px;
    vertical-align: top
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable {
    max-width: 150px
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable.searching {
    max-width: 0;
    overflow: hidden;
    transition: "max-width" .2s linear
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a {
    color: #000;
    display: inline-block;
    padding: 0 24px
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a:hover {
    color: #c4c4c4
}

.global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a.parent-link {
    color: #fff;
    display: inline-block;
    min-width: 0;
    padding: 0
}

@media (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link {
        background-color: #fff;
        background-color: #2c2c2c;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #eee;
        color: #2c2c2c;
        display: block;
        padding: 0;
        text-align: left
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link:not(:first-child) {
        padding: 0 20px
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a,
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.nav-dropdown {
        padding: 0
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link .nav-dropdown-txt .dd-text {
        padding: 0 20px
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable {
        max-width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable.searching {
        max-width: none
    }
}

.nav-flag-container {
    cursor: pointer;
    position: relative
}

.nav-flag-container ::-webkit-scrollbar {
    width: 10px
}

.nav-flag-container ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #999;
    border: 2px solid transparent
}

.nav-flag-container ::-webkit-scrollbar-thumb:hover {
    background-color: #777
}

.nav-flag-container ::-webkit-scrollbar-track {
    background-color: transparent
}

.nav-flag-container:hover .flag-list {
    height: auto;
    max-height: 90vh;
    overflow-x: hidden !important;
    overflow-y: auto;
    transition: max-height .5s ease-in, top .3s linear
}

@media (max-width:991px) {
    .nav-flag-container:hover .flag-list {
        height: auto;
        max-height: 0;
        transition: max-height .5s ease-out, top .3s linear
    }
}

.nav-flag-container.is-active .flag-list {
    height: auto;
    max-height: 86vh;
    overflow: auto;
    transition: max-height .5s ease-in, top .3s linear
}

.nav-flag-container .flag-list {
    background-color: #fff !important;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    list-style: none;
    margin: 0;
    max-height: 0;
    max-width: none;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 54px;
    transition: none;
    width: auto;
    z-index: 1030
}

@media (max-width:991px) {
    .nav-flag-container .flag-list {
        top: 54px
    }

    .nav-flag-container .flag-list.searching .global-nav-logo-container,
    .nav-flag-container .flag-list.searching .hamburger,
    .nav-flag-container .flag-list.searching .nav-flag-container {
        display: none
    }

    .nav-flag-container .flag-list.searching .nav-search-container {
        float: none
    }
}

.nav-flag-container .flag-list li {
    height: 54px;
    padding: 6px 0
}

.nav-flag-container .flag-list li:not(:first-child) {
    border-top: 1px solid #fff
}

.nav-flag-container .flag-list li .flag-link {
    cursor: pointer;
    display: block;
    height: 40px;
    min-width: 192px;
    overflow: initial;
    text-decoration: none;
    white-space: nowrap
}

.nav-flag-container .flag-list li .flag-link:hover .flag-txt {
    color: #c4c4c4
}

.nav-flag-container .flag-list li .flag-img-container {
    display: inline-block;
    padding: 8px
}

.nav-flag-container .flag-list li .flag-img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 24px;
    width: 24px
}

.nav-flag-container .flag-list li .flag-txt {
    color: #000;
    display: inline-block;
    font-size: 16px;
    line-height: 40px;
    padding: 0 24px 0 15px;
    text-transform: capitalize;
    vertical-align: top
}

.nav-flag-container .flag-list li .flag-txt:not(:first-child) {
    border-top: 0
}

.nav-flag-container .flag-list li:not(:first-child) {
    border-top: 1px solid #eee
}

#nav-links .dropdown .nav-link .dropbtn .dropdown-icon {
    float: right;
    margin-top: 24px
}

.dropdown {
    display: block;
    position: relative
}

@media only screen and (min-width:992px) {
    .dropdown {
        display: inline-block
    }
}

.dropbtn-collapse {
    display: none;
    text-indent: 0 !important
}

.dropbtn-hover {
    display: block
}

[data-toggle=collapse][aria-expanded=true]>.js-rotate-if-collapsed {
    transform: rotate(180deg)
}

hr {
    border-top: 1px solid #eee
}

.dropdown-content-mobile hr,
hr {
    margin-bottom: 0;
    margin-top: 0
}

@media only screen and (max-width:767px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container {
        border-radius: 3px;
        color: #000;
        margin-left: -171px
    }

    .dropdown {
        background-color: #fff;
        color: #000;
        display: block;
        padding-left: 3%;
        padding-right: 3%;
        position: relative;
        width: 100%
    }

    .dropdown:last-child {
        padding-bottom: 8%
    }

    .dropbtn {
        border: none;
        color: #000;
        text-align: left
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching {
        margin-left: 0;
        max-width: 100%;
        transition: none;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable {
        background-color: #fff;
        color: #000;
        display: block;
        margin-left: 0;
        text-align: left;
        text-indent: 0;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable .icon {
        display: block;
        text-align: right
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a,
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.nav-dropdown {
        color: #000;
        padding: 0;
        text-align: left
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a.parent-link {
        color: #000;
        text-indent: 0;
        width: 100%
    }

    .nav-links.is-active {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2)
    }
}

@media only screen and (max-width:991px) {
    .global-nav-spacing .global-nav-container .global-nav .hamburger {
        display: block;
        float: left
    }

    .global-nav-spacing .global-nav-container .global-nav.scroll-shrink .global-nav-logo-container {
        float: left
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container {
        border-radius: 3px;
        color: #000;
        margin-left: -171px
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container input.nav-search {
        color: #fff
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching {
        margin-left: 0;
        max-width: 100%;
        transition: none;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a,
    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.nav-dropdown {
        color: #000;
        padding: 0;
        text-align: left
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.is-active {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2)
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable {
        background-color: #fff;
        color: #000;
        display: block;
        margin-left: 0;
        text-align: left;
        text-indent: 0;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable .icon {
        display: block;
        text-align: right
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link a.parent-link {
        color: #000;
        text-indent: 0;
        width: 100%
    }

    .dropdown {
        background-color: #fff;
        color: #000;
        display: block;
        padding-left: 3%;
        padding-right: 8%;
        position: relative;
        width: 100%
    }

    .dropbtn {
        border: none;
        color: #000;
        text-align: left
    }

    a.dropbtn {
        text-indent: 30px
    }

    .dropbtn-collapse {
        display: block
    }

    .dropbtn-collapse svg {
        transition: transform .5s ease
    }

    .dropbtn-collapse:not(.collapsed) svg {
        transform: rotate(180deg)
    }

    .dropbtn:last-child div {
        padding-bottom: 70%
    }

    .dropbtn-hover {
        display: none
    }

    .nav-search::-webkit-input-placeholder {
        color: #fff !important
    }

    .nav-search::-moz-placeholder {
        color: #fff !important
    }

    .nav-search:-ms-input-placeholder {
        color: #fff !important
    }

    .nav-search:-moz-placeholder {
        color: #fff !important
    }
}

@media only screen and (min-width:992px) {
    .global-nav-spacing .global-nav-container .global-nav .nav-search-container .nav-search-img {
        margin: 15px 2px 0;
        position: absolute;
        transition: none
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching {
        transition: none
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching .nav-search-exit {
        transition: none;
        transition-delay: none;
        width: 15px
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-search-container.searching input.nav-search {
        background-color: #fff;
        border-radius: 4px
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links a:hover {
        cursor: pointer;
        text-decoration: none
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable {
        display: block;
        margin-left: 24px;
        text-align: left;
        text-indent: 0;
        width: 100%
    }

    .global-nav-spacing .global-nav-container .global-nav .nav-links-container .nav-links .nav-link.hideable li:last-child {
        border-bottom: 0 solid #000 !important
    }

    .nav-flag-container .flag-list li {
        height: 54px;
        padding: 6px 0
    }

    .dropdown-content-mobile {
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
        color: #000;
        margin-top: -2px;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        z-index: 10000
    }

    .dropdown-content-mobile a {
        color: #000;
        display: block;
        padding: 12px 16px;
        text-decoration: none
    }

    .dropdown-content-link {
        color: #000
    }

    .dropdown:hover .dropdown-content-mobile {
        display: block;
        height: auto;
        max-height: 1500px;
        transition: max-height .5s ease-in, top .3s linear
    }

    .dropbtn svg,
    .dropdown-icon {
        display: none
    }

    .hamburger {
        display: none !important
    }

    .reduced-height {
        line-height: 46px !important
    }
}

.nav-menu {
    box-shadow: none;
    position: relative;
    z-index: 9999
}

.nav-menu:after,
.nav-menu:before {
    content: " ";
    display: table
}

.nav-menu:after {
    clear: both
}

.nav-menu ul {
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    min-width: 260px;
    width: auto
}

.nav-menu ul.global-nav-level-3 {
    min-width: 360px
}

.nav-menu a {
    padding: 15px 10px;
    text-decoration: none
}

.nav-menu .global-nav-level-2 a,
.nav-menu .global-nav-level-3 a {
    padding: 10px 15px
}

.nav-menu>li {
    float: left;
    z-index: 200
}

.nav-menu>li>a {
    color: #fff;
    display: block;
    text-decoration: none
}

.nav-menu li ul {
    background-color: #fff;
    left: -9999px;
    position: absolute;
    z-index: 100
}

.nav-menu li li a {
    color: #000;
    display: block;
    text-decoration: none;
    z-index: 100
}

.nav-menu li li li a {
    text-decoration: none;
    z-index: 200
}

.nav-menu li {
    position: relative
}

.nav-menu>li.hover>ul {
    left: -25%
}

.nav-menu li li.hover ul {
    left: 100%;
    top: 0
}

.nav-menu li li ul {
    background-color: #ececec
}

.mobile-global-nav-spacer {
    display: none;
    height: 15px;
    width: 100%
}

.global-nav-arrow {
    background-image: url(https://objects.kaxmedia.com/auto/o/139685/d9a1ff00df.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 6px;
    height: 10px;
    width: 10px
}

.nav-link-hover-color {
    color: #dc3545 !important
}

.nav-link-hover-color .global-nav-arrow {
    background-image: url(https://objects.kaxmedia.com/auto/o/139759/a2938e1de1.svg)
}

.global-nav-item-level-1>.global-nav-arrow {
    display: none
}

@media screen and (max-width:991px) {
    .global-nav-arrow {
        background-image: url(https://objects.kaxmedia.com/auto/o/139683/e55cf6c283.svg);
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: 10px;
        height: 10px;
        width: 10px
    }

    .global-nav-item-level-1>.global-nav-arrow {
        display: inline-block
    }

    .mobile-global-nav-spacer {
        display: block
    }

    .nav-links-container {
        top: 54px
    }

    .nav-menu {
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
        height: auto;
        max-height: calc(100vh - 54px);
        overflow-y: auto
    }

    .nav-menu a {
        padding: 6px 13px
    }

    .nav-menu .global-nav-level-2 a {
        padding: 6px 13px 6px 23px
    }

    .nav-menu .global-nav-level-3 a {
        padding: 6px 13px 6px 33px
    }

    .nav-menu li:last-child {
        border: none
    }

    .nav-menu>li>a {
        color: #000
    }

    .nav-menu>li {
        float: none
    }

    .nav-menu ul {
        box-shadow: none;
        display: block;
        height: auto;
        width: 100%
    }

    .nav-menu ul.global-nav-level-3 {
        width: 100%
    }

    .nav-menu li li ul,
    .nav-menu>li>ul {
        display: none
    }

    .nav-menu li li.hover ul,
    .nav-menu>li.hover>ul {
        display: block;
        position: static
    }

    li>a>.global-nav-arrow {
        transform: rotate(0deg);
        transition: all .1s ease-in-out
    }

    li.hover>a>.global-nav-arrow {
        transform: rotate(180deg);
        transition: all .1s ease-in-out
    }

    .nav-link-hover-color .global-nav-arrow {
        background-image: url(https://objects.kaxmedia.com/auto/o/139757/00c0516d11.svg)
    }
}

li.hover>.global-nav-item-level-1 {
    font-weight: 600
}

.nav-menu li a {
    cursor: pointer
}

.local_nav {
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    height: 3.5em
}

.local_nav .local_nav_container {
    padding-left: 8px;
    padding-right: 8px
}

@media (min-width:768px) {
    .local_nav .local_nav_container {
        padding-left: 15px;
        padding-right: 15px
    }
}

.local_nav .local_nav_container .more_items {
    display: none
}

.local_nav .local_nav_container .primary_item {
    border-right: 1px solid hsla(0, 0%, 100%, .2);
    color: #999
}

.local_nav .local_nav_container .primary_item_active {
    border-right: 1px solid hsla(0, 0%, 100%, .2);
    color: #000
}

.local_nav .local_nav_container .local_items_list .more_items {
    display: none
}

.local_nav .local_nav_container .arrow_btn {
    background-color: #fff;
    border: none;
    height: 2.5em;
    width: 2em
}

.fixed_nav {
    box-shadow: 0 -2px 8px #4e4e4e;
    position: fixed;
    top: 7.5em;
    width: 100%;
    z-index: 1000
}

.pos_arrow {
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    position: absolute;
    width: 0
}

div.scrollmenu {
    -ms-overflow-style: none;
    background-color: #fff;
    height: 3.5em;
    overflow-x: auto;
    overflow-y: hidden;
    overflow: -moz-scrollbars-none;
    white-space: nowrap
}

div.scrollmenu::-webkit-scrollbar {
    height: .1em;
    width: .1em
}

div.scrollmenu a {
    color: #999;
    display: inline-block;
    padding: 14px;
    text-align: center;
    text-decoration: none
}

div.scrollmenu a:hover {
    color: #5a5a5a
}

div.scrollmenu a.active_link {
    color: #000;
    cursor: default;
    pointer-events: none
}

@media (min-width:992px) {
    .local_nav_container {
        padding: 0
    }

    .local_nav_container .scroller_container:only-child {
        width: 100%
    }

    .local_nav_container .primary_item {
        left: 0;
        position: relative
    }

    .local_nav_container .more_items {
        display: inline;
        max-height: 3.5em;
        padding: 0
    }

    .local_nav_container .more_items_toggle {
        background-color: #fff;
        border: none;
        color: #b3b2b2;
        cursor: pointer;
        display: inline-block;
        font-size: 36px;
        height: 42px;
        line-height: 28px;
        overflow: hidden;
        padding: 0;
        text-align: center;
        text-decoration: none;
        width: 100%
    }

    .local_nav_container .more_items_toggle span {
        position: relative
    }

    .local_nav_container .more_items_container .dropdown-animated {
        left: -4.5em !important;
        max-height: 0;
        min-width: 160px;
        overflow: hidden;
        position: absolute;
        text-align: left;
        top: 30px
    }

    .local_nav_container .more_items_container:hover .more_items_toggle {
        color: #dc3545
    }

    .local_nav_container .more_items_container:hover .dropdown-animated {
        height: auto;
        max-height: 1500px;
        transition: max-height .5s ease-in, top .3s linear
    }

    .local_nav_container .more_items_menu {
        background-color: #fff;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        color: #b3b2b2;
        display: block;
        font-size: 14px;
        list-style: none;
        margin: 0 15px 15px;
        min-width: 160px;
        padding: 1em !important;
        position: relative;
        z-index: 1000
    }

    .local_nav_container .more_items_menu a {
        color: #999;
        display: block;
        left: -.25em;
        padding-bottom: .25em;
        padding-left: .25em;
        padding-top: .25em;
        position: relative;
        text-decoration: none
    }

    .local_nav_container .more_items_menu a:hover {
        color: #5a5a5a
    }

    .local_nav_container .local_nav_dropdown_topper {
        border: 3px solid #fff;
        height: 12px;
        margin: 0 auto;
        position: relative;
        width: 25px
    }

    .local_nav_container .local_nav_dropdown_topper span.pointer {
        border-bottom: 12px solid #fff;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        height: 0;
        left: -2px;
        position: absolute;
        top: 1px;
        width: 0;
        z-index: 10000
    }

    .local_nav_container .local_nav_dropdown_topper span.pointer-container {
        border-bottom: 12px solid #ccc;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        height: 0;
        left: -2px;
        position: absolute;
        top: 0;
        width: 0
    }

    div.scrollmenu {
        overflow: hidden
    }

    div.scrollmenu a {
        font-size: 1.2em;
        min-width: 150px;
        position: relative;
        top: -.15em;
        width: auto
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .local_nav .local_nav_container .scroller_container {
        position: relative;
        width: 100%
    }

    .local_nav .local_nav_container .arrow_btn {
        position: relative;
        top: .5em;
        width: 3em
    }

    .local_nav .local_nav_container .arrow_btn .fat_arrow {
        width: 2.5em
    }

    div.scrollmenu a {
        font-size: 1.2em;
        position: relative;
        top: -.1em
    }

    .fixed_nav {
        top: 4.5em
    }
}

@media (min-width:768px) and (max-width:991px) {
    .local_nav .local_nav_container .scroller_container {
        width: 100%
    }

    .local_nav .local_nav_container .arrow_btn {
        position: relative;
        top: .5em;
        width: 3em
    }

    .local_nav .local_nav_container .arrow_btn .fat_arrow {
        width: 2.5em
    }

    div.scrollmenu a {
        font-size: 1.2em;
        position: relative;
        top: -.1em
    }

    .fixed_nav {
        top: 4.45em
    }
}

@media (max-width:767px) {
    .local_nav {
        height: 4em
    }

    .local_nav .local_nav_container {
        max-width: 96%
    }

    .local_nav .local_nav_container .scroller_container {
        overflow: hidden;
        padding: 0;
        width: 100%
    }

    .local_nav .local_nav_container .arrow_btn {
        position: relative;
        top: .5em;
        width: 1.5em
    }

    .local_nav .local_nav_container .arrow_btn .fat_arrow {
        width: 2.5em
    }

    .local_nav .local_nav_container .primary_item {
        padding-left: 0
    }

    div.scrollmenu {
        height: 4em
    }

    .shadow-box-right-arrow {
        background-image: url(https://objects.kaxmedia.com/auto/o/139740/eb6a54de44.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 8px;
        width: 24px
    }

    .shadow-box-right,
    .shadow-box-right-arrow {
        background-color: #fff;
        box-shadow: 1px 0 40px 30px #fff;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        top: 0
    }

    .shadow-box-right {
        width: 5px
    }

    .shadow-box-left {
        background-color: #fff;
        box-shadow: 1px 20px 40px 30px #fff;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 5px;
        z-index: 2
    }

    div.scrollmenu a {
        font-size: 16px;
        line-height: 1.6;
        position: relative;
        top: -.1em
    }

    .fixed_nav {
        top: 4.45em
    }
}

.hamburger {
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font: inherit;
    margin: 0;
    overflow: visible;
    padding: 15px;
    text-transform: none;
    transition-duration: .15s;
    transition-property: opacity, filter;
    transition-timing-function: linear
}

.hamburger:hover {
    opacity: .7
}

.hamburger-box {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 40px
}

.hamburger-inner {
    display: block;
    margin-top: -2px;
    top: 50%
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    background-color: #000;
    border-radius: 4px;
    height: 4px;
    position: absolute;
    transition-duration: .15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 40px
}

.hamburger-inner:after,
.hamburger-inner:before {
    content: "";
    display: block
}

.hamburger-inner:before {
    top: -10px
}

.hamburger-inner:after {
    bottom: -10px
}

.hamburger--3dx .hamburger-box {
    perspective: 80px
}

.hamburger--3dx .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx .hamburger-inner:after,
.hamburger--3dx .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateY(180deg)
}

.hamburger--3dx.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dx.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dx-r .hamburger-box {
    perspective: 80px
}

.hamburger--3dx-r .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx-r .hamburger-inner:after,
.hamburger--3dx-r .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dx-r.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateY(-180deg)
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dy .hamburger-box {
    perspective: 80px
}

.hamburger--3dy .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy .hamburger-inner:after,
.hamburger--3dy .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateX(-180deg)
}

.hamburger--3dy.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dy.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dy-r .hamburger-box {
    perspective: 80px
}

.hamburger--3dy-r .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy-r .hamburger-inner:after,
.hamburger--3dy-r .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dy-r.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateX(180deg)
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dxy .hamburger-box {
    perspective: 80px
}

.hamburger--3dxy .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dxy .hamburger-inner:after,
.hamburger--3dxy .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dxy.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateX(180deg) rotateY(180deg)
}

.hamburger--3dxy.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dxy.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--3dxy-r .hamburger-box {
    perspective: 80px
}

.hamburger--3dxy-r .hamburger-inner {
    transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dxy-r .hamburger-inner:after,
.hamburger--3dxy-r .hamburger-inner:before {
    transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s
}

.hamburger--3dxy-r.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotateX(180deg) rotateY(180deg) rotate(-180deg)
}

.hamburger--3dxy-r.is-active .hamburger-inner:before {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--3dxy-r.is-active .hamburger-inner:after {
    transform: translate3d(0, -10px, 0) rotate(-45deg)
}

.hamburger--arrow.is-active .hamburger-inner:before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrow.is-active .hamburger-inner:after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrowalt .hamburger-inner:before {
    transition: top .1s ease .1s, transform .1s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt .hamburger-inner:after {
    transition: bottom .1s ease .1s, transform .1s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
    top: 0;
    transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(.7);
    transition: top .1s ease, transform .1s cubic-bezier(.895, .03, .685, .22) .1s
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
    bottom: 0;
    transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(.7);
    transition: bottom .1s ease, transform .1s cubic-bezier(.895, .03, .685, .22) .1s
}

.hamburger--arrowalt-r .hamburger-inner:before {
    transition: top .1s ease .1s, transform .1s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt-r .hamburger-inner:after {
    transition: bottom .1s ease .1s, transform .1s cubic-bezier(.165, .84, .44, 1)
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
    top: 0;
    transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(.7);
    transition: top .1s ease, transform .1s cubic-bezier(.895, .03, .685, .22) .1s
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
    bottom: 0;
    transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(.7);
    transition: bottom .1s ease, transform .1s cubic-bezier(.895, .03, .685, .22) .1s
}

.hamburger--arrowturn.is-active .hamburger-inner {
    transform: rotate(-180deg)
}

.hamburger--arrowturn.is-active .hamburger-inner:before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--arrowturn.is-active .hamburger-inner:after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrowturn-r.is-active .hamburger-inner {
    transform: rotate(-180deg)
}

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(.7)
}

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(.7)
}

.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner:after,
.hamburger--boring .hamburger-inner:before {
    transition-property: none
}

.hamburger--boring.is-active .hamburger-inner {
    transform: rotate(45deg)
}

.hamburger--boring.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0
}

.hamburger--boring.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg)
}

.hamburger--collapse .hamburger-inner {
    bottom: 0;
    top: auto;
    transition-delay: .13s;
    transition-duration: .13s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse .hamburger-inner:after {
    top: -20px;
    transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear
}

.hamburger--collapse .hamburger-inner:before {
    transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--collapse.is-active .hamburger-inner:after {
    opacity: 0;
    top: 0;
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s
}

.hamburger--collapse.is-active .hamburger-inner:before {
    top: 0;
    transform: rotate(-90deg);
    transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s
}

.hamburger--collapse-r .hamburger-inner {
    bottom: 0;
    top: auto;
    transition-delay: .13s;
    transition-duration: .13s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse-r .hamburger-inner:after {
    top: -20px;
    transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear
}

.hamburger--collapse-r .hamburger-inner:before {
    transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse-r.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
    opacity: 0;
    top: 0;
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
    top: 0;
    transform: rotate(90deg);
    transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s
}

.hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: .275s;
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic .hamburger-inner:before {
    top: 10px;
    transition: opacity .125s ease .275s
}

.hamburger--elastic .hamburger-inner:after {
    top: 20px;
    transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 75ms
}

.hamburger--elastic.is-active .hamburger-inner:before {
    opacity: 0;
    transition-delay: 0s
}

.hamburger--elastic.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 75ms
}

.hamburger--elastic-r .hamburger-inner {
    top: 2px;
    transition-duration: .275s;
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic-r .hamburger-inner:before {
    top: 10px;
    transition: opacity .125s ease .275s
}

.hamburger--elastic-r .hamburger-inner:after {
    top: 20px;
    transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic-r.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(-135deg);
    transition-delay: 75ms
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
    opacity: 0;
    transition-delay: 0s
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 75ms
}

.hamburger--emphatic {
    overflow: hidden
}

.hamburger--emphatic .hamburger-inner {
    transition: background-color .125s ease-in .175s
}

.hamburger--emphatic .hamburger-inner:before {
    left: 0;
    transition: transform .125s cubic-bezier(.6, .04, .98, .335), top .05s linear .125s, left .125s ease-in .175s
}

.hamburger--emphatic .hamburger-inner:after {
    right: 0;
    top: 10px;
    transition: transform .125s cubic-bezier(.6, .04, .98, .335), top .05s linear .125s, right .125s ease-in .175s
}

.hamburger--emphatic.is-active .hamburger-inner {
    background-color: transparent;
    transition-delay: 0s;
    transition-timing-function: ease-out
}

.hamburger--emphatic.is-active .hamburger-inner:before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left .125s ease-out, top .05s linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s
}

.hamburger--emphatic.is-active .hamburger-inner:after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right .125s ease-out, top .05s linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s
}

.hamburger--emphatic-r {
    overflow: hidden
}

.hamburger--emphatic-r .hamburger-inner {
    transition: background-color .125s ease-in .175s
}

.hamburger--emphatic-r .hamburger-inner:before {
    left: 0;
    transition: transform .125s cubic-bezier(.6, .04, .98, .335), top .05s linear .125s, left .125s ease-in .175s
}

.hamburger--emphatic-r .hamburger-inner:after {
    right: 0;
    top: 10px;
    transition: transform .125s cubic-bezier(.6, .04, .98, .335), top .05s linear .125s, right .125s ease-in .175s
}

.hamburger--emphatic-r.is-active .hamburger-inner {
    background-color: transparent;
    transition-delay: 0s;
    transition-timing-function: ease-out
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
    left: -80px;
    top: 80px;
    transform: translate3d(80px, -80px, 0) rotate(-45deg);
    transition: left .125s ease-out, top .05s linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
    right: -80px;
    top: 80px;
    transform: translate3d(-80px, -80px, 0) rotate(45deg);
    transition: right .125s ease-out, top .05s linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s
}

.hamburger--minus .hamburger-inner:after,
.hamburger--minus .hamburger-inner:before {
    transition: bottom .08s ease-out 0s, top .08s ease-out 0s, opacity 0s linear
}

.hamburger--minus.is-active .hamburger-inner:after,
.hamburger--minus.is-active .hamburger-inner:before {
    opacity: 0;
    transition: bottom .08s ease-out, top .08s ease-out, opacity 0s linear .08s
}

.hamburger--minus.is-active .hamburger-inner:before {
    top: 0
}

.hamburger--minus.is-active .hamburger-inner:after {
    bottom: 0
}

.hamburger--slider .hamburger-inner {
    top: 2px
}

.hamburger--slider .hamburger-inner:before {
    top: 10px;
    transition-duration: .15s;
    transition-property: transform, opacity;
    transition-timing-function: ease
}

.hamburger--slider .hamburger-inner:after {
    top: 20px
}

.hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg)
}

.hamburger--slider.is-active .hamburger-inner:before {
    opacity: 0;
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0)
}

.hamburger--slider.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(-90deg)
}

.hamburger--slider-r .hamburger-inner {
    top: 2px
}

.hamburger--slider-r .hamburger-inner:before {
    top: 10px;
    transition-duration: .15s;
    transition-property: transform, opacity;
    transition-timing-function: ease
}

.hamburger--slider-r .hamburger-inner:after {
    top: 20px
}

.hamburger--slider-r.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(-45deg)
}

.hamburger--slider-r.is-active .hamburger-inner:before {
    opacity: 0;
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0)
}

.hamburger--slider-r.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(90deg)
}

.hamburger--spin .hamburger-inner {
    transition-duration: .22s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin .hamburger-inner:before {
    transition: top .1s ease-in .25s, opacity .1s ease-in
}

.hamburger--spin .hamburger-inner:after {
    transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--spin.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0;
    transition: top .1s ease-out, opacity .1s ease-out .12s
}

.hamburger--spin.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s
}

.hamburger--spin-r .hamburger-inner {
    transition-duration: .22s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin-r .hamburger-inner:before {
    transition: top .1s ease-in .25s, opacity .1s ease-in
}

.hamburger--spin-r .hamburger-inner:after {
    transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spin-r.is-active .hamburger-inner {
    transform: rotate(-225deg);
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--spin-r.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0;
    transition: top .1s ease-out, opacity .1s ease-out .12s
}

.hamburger--spin-r.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s
}

.hamburger--spring .hamburger-inner {
    top: 2px;
    transition: background-color 0s linear .13s
}

.hamburger--spring .hamburger-inner:before {
    top: 10px;
    transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring .hamburger-inner:after {
    top: 20px;
    transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring.is-active .hamburger-inner {
    background-color: transparent;
    transition-delay: .22s
}

.hamburger--spring.is-active .hamburger-inner:before {
    top: 0;
    transform: translate3d(0, 10px, 0) rotate(45deg);
    transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s
}

.hamburger--spring.is-active .hamburger-inner:after {
    top: 0;
    transform: translate3d(0, 10px, 0) rotate(-45deg);
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s
}

.hamburger--spring-r .hamburger-inner {
    bottom: 0;
    top: auto;
    transition-delay: 0s;
    transition-duration: .13s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring-r .hamburger-inner:after {
    top: -20px;
    transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity 0s linear
}

.hamburger--spring-r .hamburger-inner:before {
    transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--spring-r.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: .22s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--spring-r.is-active .hamburger-inner:after {
    opacity: 0;
    top: 0;
    transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .22s
}

.hamburger--spring-r.is-active .hamburger-inner:before {
    top: 0;
    transform: rotate(90deg);
    transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s
}

.hamburger--stand .hamburger-inner {
    transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms
}

.hamburger--stand .hamburger-inner:before {
    transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand .hamburger-inner:after {
    transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotate(90deg);
    transition: transform 75ms cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .15s
}

.hamburger--stand.is-active .hamburger-inner:before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s
}

.hamburger--stand.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s
}

.hamburger--stand-r .hamburger-inner {
    transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms
}

.hamburger--stand-r .hamburger-inner:before {
    transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand-r .hamburger-inner:after {
    transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19) 0s
}

.hamburger--stand-r.is-active .hamburger-inner {
    background-color: transparent;
    transform: rotate(-90deg);
    transition: transform 75ms cubic-bezier(.215, .61, .355, 1) 0s, background-color 0s linear .15s
}

.hamburger--stand-r.is-active .hamburger-inner:before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s
}

.hamburger--stand-r.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 75ms;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--squeeze .hamburger-inner:before {
    transition: top 75ms ease .12s, opacity 75ms ease
}

.hamburger--squeeze .hamburger-inner:after {
    transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19)
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--squeeze.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0;
    transition: top 75ms ease, opacity 75ms ease .12s
}

.hamburger--squeeze.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s
}

.hamburger--vortex .hamburger-inner {
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger--vortex .hamburger-inner:after,
.hamburger--vortex .hamburger-inner:before {
    transition-delay: .1s;
    transition-duration: 0s;
    transition-timing-function: linear
}

.hamburger--vortex .hamburger-inner:before {
    transition-property: top, opacity
}

.hamburger--vortex .hamburger-inner:after {
    transition-property: bottom, transform
}

.hamburger--vortex.is-active .hamburger-inner {
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger--vortex.is-active .hamburger-inner:after,
.hamburger--vortex.is-active .hamburger-inner:before {
    transition-delay: 0s
}

.hamburger--vortex.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0
}

.hamburger--vortex.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(90deg)
}

.hamburger--vortex-r .hamburger-inner {
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger--vortex-r .hamburger-inner:after,
.hamburger--vortex-r .hamburger-inner:before {
    transition-delay: .1s;
    transition-duration: 0s;
    transition-timing-function: linear
}

.hamburger--vortex-r .hamburger-inner:before {
    transition-property: top, opacity
}

.hamburger--vortex-r .hamburger-inner:after {
    transition-property: bottom, transform
}

.hamburger--vortex-r.is-active .hamburger-inner {
    transform: rotate(-765deg);
    transition-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.hamburger--vortex-r.is-active .hamburger-inner:after,
.hamburger--vortex-r.is-active .hamburger-inner:before {
    transition-delay: 0s
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
    opacity: 0;
    top: 0
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg)
}

.footer {
    background-color: #000;
    border-top: 1px solid #7a7a7a;
    color: #fff;
    display: block
}

.footer #footer_links {
    background: 0 0;
    display: table;
    height: auto;
    margin-top: 0;
    padding-top: 15px
}

.footer #footer_links .col-sm-3:last-child li,
.footer #footer_links .col-sm-3:nth-child(2) li,
.footer #footer_links .col-sm-6:last-child li,
.footer #footer_links .col-sm-6:nth-child(2) li {
    border-bottom: 1px solid #2c2c2c;
    padding: .8em
}

.footer #footer_links .col-sm-3:first-child,
.footer #footer_links .col-sm-6:first-child {
    color: #6f6f6f;
    flex: 1 0 27%;
    padding-left: 0;
    width: 27%
}

.footer #footer_links .col-sm-3:first-child a:hover,
.footer #footer_links .col-sm-6:first-child a:hover {
    color: #ccc
}

.footer #footer_links .col-sm-3:nth-child(2),
.footer #footer_links .col-sm-6:nth-child(2) {
    flex: 1 0 27%;
    width: 27%
}

.footer #footer_links .col-sm-3:nth-child(3),
.footer #footer_links .col-sm-6:nth-child(3) {
    flex: 1 0 23%;
    width: 23%
}

.footer #footer_links .col-sm-3:last-child,
.footer #footer_links .col-sm-6:last-child {
    flex: 1 0 23%;
    padding-bottom: 55px;
    padding-right: 0;
    width: 23%
}

.footer #footer_links .col-sm-3.flags li,
.footer #footer_links .col-sm-6.flags li {
    display: inline-block;
    width: 32%
}

.footer #footer_links .col-sm-3.flags a,
.footer #footer_links .col-sm-6.flags a {
    text-align: center
}

.footer #footer_links .col-sm-3 ul,
.footer #footer_links .col-sm-6 ul {
    padding: 0
}

.footer #footer_links .col-sm-3 ul li,
.footer #footer_links .col-sm-6 ul li {
    font-size: 13px;
    list-style: none;
    padding: .8em .8em 0
}

.footer #footer_links .col-sm-3 ul li a,
.footer #footer_links .col-sm-6 ul li a {
    color: #6f6f6f;
    display: block
}

.footer #footer_links .col-sm-3 ul li a:hover,
.footer #footer_links .col-sm-6 ul li a:hover {
    color: #ccc;
    text-decoration: none
}

.footer #footer_links .col-sm-3 ul.social,
.footer #footer_links .col-sm-6 ul.social {
    border: 0;
    bottom: 0;
    position: absolute
}

.footer #footer_links .col-sm-3 ul.social li,
.footer #footer_links .col-sm-6 ul.social li {
    border: 0;
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0
}

.footer #footer_links .col-sm-3 ul.social li svg,
.footer #footer_links .col-sm-6 ul.social li svg {
    fill: #fff;
    border: 1px solid #2e2e2e;
    display: block;
    height: 40px;
    padding: 10px;
    width: 40px
}

.footer #footer_links .col-sm-3 ul.social li .icon-facebook:hover,
.footer #footer_links .col-sm-6 ul.social li .icon-facebook:hover {
    fill: #3b5998;
    border: 1px solid #3b5998
}

.footer #footer_links .col-sm-3 ul.social li .icon-twitter:hover,
.footer #footer_links .col-sm-6 ul.social li .icon-twitter:hover {
    fill: #00aced;
    border: 1px solid #00aced
}

.footer #footer_links .col-sm-3 ul.social li .icon-instagram:hover,
.footer #footer_links .col-sm-6 ul.social li .icon-instagram:hover {
    fill: #b900b4;
    border: 1px solid #b900b4
}

.footer #footer_links .col-sm-3 ul.support-link svg,
.footer #footer_links .col-sm-6 ul.support-link svg {
    display: block;
    height: 43px !important;
    width: 127px !important
}

.footer #footer_links .col-sm-3 h4,
.footer #footer_links .col-sm-6 h4 {
    background: #242424;
    color: #fff;
    font-size: 14px;
    padding: .8em
}

.footer #footer_links .col-sm-3 h4 a,
.footer #footer_links .col-sm-6 h4 a {
    color: #fff !important
}

.footer #footer_links .col-sm-3 .flag_img,
.footer #footer_links .col-sm-6 .flag_img {
    height: auto;
    width: 2.2em
}

.disclaimer {
    background: #000;
    border-top: 1px solid #7a7a7a
}

.disclaimer .container {
    padding: 15px
}

.disclaimer p {
    color: #676767;
    font-size: 12px !important;
    line-height: 18px !important
}

.disclaimer p a {
    color: #676767
}

.disclaimer p a:hover {
    color: #ccc
}

.disclaimer .footer-nasdaq-logo {
    margin-bottom: 20px;
    width: 100%
}

@media (min-width:400px) {
    .disclaimer .footer-nasdaq-logo {
        margin-bottom: 10px;
        width: 180px
    }
}

@media (min-width:768px) and (max-width:991px) {

    .footer #footer_links .col-sm-3,
    .footer #footer_links .col-sm-6 {
        padding-left: 10px;
        padding-right: 10px;
        width: 26.6%
    }

    .footer #footer_links .col-sm-3:first-child,
    .footer #footer_links .col-sm-3:nth-child(2),
    .footer #footer_links .col-sm-6:first-child,
    .footer #footer_links .col-sm-6:nth-child(2) {
        width: 28%
    }

    .footer #footer_links .col-sm-3:last-child,
    .footer #footer_links .col-sm-3:nth-child(3),
    .footer #footer_links .col-sm-6:last-child,
    .footer #footer_links .col-sm-6:nth-child(3) {
        width: 22%
    }

    .footer #footer_links .col-sm-3 ul li,
    .footer #footer_links .col-sm-6 ul li {
        padding: .7em .7em 0
    }

    .footer #footer_links .col-sm-3 ul li:last-child,
    .footer #footer_links .col-sm-6 ul li:last-child {
        padding: .7em
    }

    .footer #footer_links .col-sm-3 ul.social li:last-child,
    .footer #footer_links .col-sm-6 ul.social li:last-child {
        margin: 0;
        padding: 0
    }

    .footer #footer_links .col-sm-3 h4,
    .footer #footer_links .col-sm-6 h4 {
        font-size: 13px;
        padding: .7em;
        width: 90%
    }
}

@media (max-width:767px) {
    .footer #footer_links {
        width: 100%
    }

    .footer #footer_links .col-sm-3,
    .footer #footer_links .col-sm-6 {
        flex: none !important;
        float: left;
        height: auto;
        max-width: none !important;
        padding: 0 0 2em !important;
        width: 100% !important
    }

    .footer #footer_links .col-sm-3 ul.social,
    .footer #footer_links .col-sm-6 ul.social {
        padding: 2em 0 0;
        position: relative
    }
}

.footer-logos {
    background-color: #000;
    overflow-x: hidden;
    padding: 1em 0
}

.footer-logos p {
    color: #676767;
    font-size: 12px;
    line-height: 18px
}

.footer-logos .footer-logo {
    flex-grow: 1;
    padding-bottom: .5em
}

@media (max-width:767px) {
    .footer-logos .footer-logo {
        padding-top: .5em
    }
}

.footer-logos img {
    display: block;
    margin: .5em auto;
    padding: 0 5px
}

@media (max-width:767px) {
    .footer-logos .row {
        margin-left: 0;
        margin-right: 0
    }
}

footer.ca .footer {
    background-color: #fff;
    border-top: 1px solid #d8d8d8
}

footer.ca .footer #footer_links .col-sm-3 ul li a {
    color: #0157ff
}

footer.ca .footer #footer_links .col-sm-3 ul li a:hover {
    color: #0157ff;
    text-decoration: underline
}

footer.ca .footer #footer_links .col-sm-3 h4,
footer.ca .footer #footer_links .col-sm-6 h4 {
    background: #f7f7f7;
    border-radius: 10px;
    color: #333
}

footer.ca .footer #footer_links .col-sm-3 li,
footer.ca .footer #footer_links .col-sm-6 li {
    border: none;
    padding: .7em
}

footer.ca .footer #footer_links .col-sm-3 ul.social li svg,
footer.ca .footer #footer_links .col-sm-6 ul.social li svg {
    fill: #000;
    border: none
}

footer.ca .disclaimer {
    background-color: #fff;
    border-top: 1px solid #d8d8d8
}

footer.ca .disclaimer p a:hover {
    color: #333;
    text-decoration: underline
}

footer.ca .footer-logos {
    background-color: #fff
}

.container_redirect_message {
    overflow: hidden;
    position: relative
}

.redirect-box {
    background-color: #e8ebf7;
    color: #333;
    position: fixed;
    width: 100%;
    z-index: 9000
}

.redirect-box__inner {
    height: 160px;
    margin: 0 auto;
    max-width: 982px;
    padding: 20px;
    position: relative
}

.redirect-box__inner.lazyloaded {
    background-image: url(https://objects.kaxmedia.com/auto/o/81841/80c965f536.png);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 538px
}

.redirect-box__main-message {
    align-items: center;
    display: flex;
    padding: 7px 0 0
}

.redirect-box__main-message__flag {
    display: inline-block;
    height: 40px;
    margin-right: 20px;
    width: 40px
}

.redirect-box__main-message__text {
    display: inline-block;
    font-size: 18px;
    line-height: 27px
}

.redirect-box__actions a {
    border-radius: 4px;
    box-shadow: 0 6px 0 0 rgba(0, 0, 0, .2);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    height: 36px;
    line-height: 38px;
    margin: 15px 0 0;
    min-width: 210px;
    padding: 0 25px;
    text-align: center;
    text-transform: uppercase
}

.redirect-box__actions a:hover {
    box-shadow: 0 6px 0 0 transparent;
    text-decoration: none
}

.redirect-box__actions a.redirect-box__action-leave {
    background: #1e84cc;
    box-shadow: 0 6px 0 0 #186aa4;
    color: #fff
}

.redirect-box__actions a.redirect-box__action-leave:hover {
    background: #f93;
    box-shadow: 0 6px 0 0 #d66d04
}

.redirect-box__close {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 40px;
    line-height: 0;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 30px
}

.redirect-box__close:hover {
    text-decoration: none
}

@media (max-width:599px) {
    .redirect-box__inner {
        padding-bottom: 30px
    }

    .redirect-box__main-message {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 7px 25px 0 0
    }

    .redirect-box__main-message__flag {
        margin-right: 10px;
        max-width: 60px
    }

    .redirect-box__main-message__text {
        font-size: 15px;
        line-height: 19px
    }

    .redirect-box__actions a {
        display: block;
        font-size: 12px;
        margin: 15px auto;
        padding: 0 10px;
        width: 240px
    }
}

#breadcrumb {
    font-size: 12px;
    line-height: 18px;
    padding: 1em 0 .5em
}

.body_content #breadcrumb {
    padding-bottom: 1em
}

@media (max-width:767px) {
    .body_content #breadcrumb {
        padding-bottom: .5em
    }
}

.body_content #breadcrumb a {
    color: #777
}

@font-face {
    font-display: swap;
    font-family: GDCIcons;
    font-style: normal;
    font-weight: 400;
    src: url(https://objects.kaxmedia.com/auto/o/2106/501ce4bd96.eot);
    src: url(https://objects.kaxmedia.com/auto/o/2106/501ce4bd96.eot) format("embedded-opentype"), url(https://objects.kaxmedia.com/auto/o/2108/61e2b10169.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/2107/236f20a0b1.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    src: local("Poppins Light"), local("Poppins-Light"), url(https://objects.kaxmedia.com/auto/o/134903/533641319c.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134895/4e52f415a1.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134887/f49a74f847.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    src: local("Poppins"), local("Poppins"), url(https://objects.kaxmedia.com/auto/o/134905/75db21114f.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134897/6a00b33faf.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134889/3340eb2363.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: italic;
    font-weight: 400;
    src: local("Poppins Italic"), local("Poppins Italic"), url(https://objects.kaxmedia.com/auto/o/134902/6c3d22393e.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134894/c9621b910a.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134886/1b73bc209d.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src: local("Poppins Semibold"), local("Poppins-Semibold"), url(https://objects.kaxmedia.com/auto/o/134906/1a2dbcd1a7.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134898/d2cb72ec39.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134890/772b90b3bb.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    src: local("Poppins Bold"), local("Poppins-Bold"), url(https://objects.kaxmedia.com/auto/o/134900/3d4b782972.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134892/df34e9ff75.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134884/42184bc76d.ttf) format("truetype")
}

@font-face {
    font-display: swap;
    font-family: Poppins Bold;
    font-style: normal;
    font-weight: 600;
    src: local("Poppins Bold"), local("Poppins-Bold"), url(https://objects.kaxmedia.com/auto/o/134900/3d4b782972.woff2) format("woff2"), url(https://objects.kaxmedia.com/auto/o/134892/df34e9ff75.woff) format("woff"), url(https://objects.kaxmedia.com/auto/o/134884/42184bc76d.ttf) format("truetype")
}

p {
    margin-bottom: 1rem
}

h1,
h2,
h3 {
    margin: 3rem 0 1.38rem
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.3
}

h4,
h5 {
    margin: 1rem 0
}

h1,
h2,
h3 {
    font-weight: 600
}

h4,
h5 {
    font-weight: 400
}

h1 {
    font-size: 1.802rem;
    margin-top: 0
}

h2 {
    font-size: 1.602rem
}

h3 {
    font-size: 1.424rem
}

h4 {
    font-size: 1.266rem
}

h5 {
    font-size: 1.125rem
}

.text_small,
small {
    font-size: .889rem
}

.body_content ol,
.body_content ul {
    margin-bottom: 10px;
    width: 100%
}

.body_content ol li,
.body_content ul li {
    margin-bottom: 7px
}

.body_content ol.no-bullet-point li,
.body_content ul.no-bullet-point li {
    list-style: none !important
}

.body_content .ft_snpt {
    list-style: none;
    margin: 0;
    padding: 0
}

.body_content .ft_snpt li {
    list-style: decimal;
    list-style-position: inside
}

.body_content .ft_snpt li::marker {
    padding-left: 15px
}

.body_content .ft_snpt h1,
.body_content .ft_snpt h2,
.body_content .ft_snpt h3,
.body_content .ft_snpt h4 {
    display: contents;
    font-size: 18px;
    font-weight: 600
}

.body_content .ft_snpt h1 strong,
.body_content .ft_snpt h2 strong,
.body_content .ft_snpt h3 strong,
.body_content .ft_snpt h4 strong {
    font-weight: 600
}

.body_content .hero-text-geo {
    font-size: 12px;
    line-height: 18px
}

.h3 a,
h3 a {
    color: #dc3545;
    font-weight: 700
}

.h3 a:hover,
h3 a:hover {
    text-decoration: underline
}

.section-paragraph {
    padding: 5px 0
}

.section-paragraph a {
    color: #dc3545
}

.section-paragraph a:hover {
    text-decoration: underline
}

.section-paragraph.p-us {
    padding: 0 0 5px;
    text-align: center
}

.read-more {
    color: #dc3545;
    font-size: 12px;
    letter-spacing: 1px
}

.red-links-title {
    color: #dc3545;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px
}

.red-links-text {
    color: #dc3545;
    font-size: 12px;
    line-height: 17px
}

a {
    color: #dc3545
}

a:focus,
a:hover {
    color: #9d242a
}

a:hover {
    text-decoration: underline
}

a:focus {
    text-decoration: none
}

body,
html {
    height: 100%;
    width: 100%
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    -webkit-animation: safari-render-fix 1s;
    background-color: #fff;
    color: #000;
    font-family: Poppins, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    overflow-x: hidden
}

@media (max-width:767px) {
    .container {
        max-width: none;
        padding-left: 8px;
        padding-right: 8px
    }
}

.container.body_content {
    background-color: #fff
}

* {
    -webkit-overflow-scrolling: touch
}

::-webkit-input-placeholder {
    color: #333 !important
}

::-moz-placeholder {
    color: #333 !important
}

:-ms-input-placeholder {
    color: #333 !important
}

.body_buffer.nolocalnav {
    height: 54px !important
}

.body_buffer.nolocalnav .global-nav-spacing {
    height: 54px
}

.spacing {
    clear: both;
    display: table;
    width: 100%
}

.spacing.low {
    margin-bottom: 32px
}

.spacing.high {
    margin-bottom: 64px
}

input:focus {
    outline-offset: 0
}

.hidden {
    display: none !important
}

.img-fluid {
    display: block;
    height: auto;
    max-width: 100%
}

.break {
    flex-basis: 100%;
    height: 0
}

.grey-anchor {
    color: #6e6e6e
}

.grey-anchor:hover {
    color: #333;
    text-decoration: none
}

.video-container {
    height: 0;
    margin: 20px auto;
    max-width: 660px;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative
}

.video-container embed,
.video-container iframe,
.video-container object {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.btn.focus,
.btn:focus,
button {
    box-shadow: none;
    outline: 0
}

.img-responsive {
    display: block;
    height: auto;
    max-width: 100%
}

.disclosure {
    color: #333;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    margin-left: 15px
}

button:focus {
    outline: none
}

.btn-cta-play-now,
.button-blue,
.button-green,
.button-yellow {
    border: 0;
    border-radius: 4px;
    display: block;
    font-size: 16px;
    font-weight: 700;
    height: 54px;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.btn-cta-play-now:active,
.btn-cta-play-now:hover,
.button-blue:active,
.button-blue:hover,
.button-green:active,
.button-green:hover,
.button-yellow:active,
.button-yellow:hover {
    text-decoration: none
}

.button-blue {
    background: #1e84cc;
    border-bottom: 7px solid #186aa4;
    color: #fff
}

.button-blue:active,
.button-blue:hover {
    background: #186aa4;
    color: #fff
}

.button-yellow {
    background: #fc3;
    border-bottom: 7px solid #f93;
    color: #000
}

.button-yellow:active,
.button-yellow:hover {
    background: #f93;
    color: #000
}

.button-green {
    background: #00b866;
    border-bottom: 7px solid #00a74e
}

.button-green:active,
.button-green:hover {
    background: #00a74e
}

.btn-cta-play-now {
    background: #0157ff;
    color: #fff
}

.btn-cta-play-now:active,
.btn-cta-play-now:hover {
    background: #003ee6;
    color: #fff
}

.button-ghost-black,
.button-ghost-black-big,
.button-ghost-white {
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    line-height: 36px;
    padding: 0 16px;
    text-shadow: none;
    text-transform: none;
    transition: background-color .2s ease-out;
    width: -moz-max-content;
    width: max-content
}

.button-ghost-black-big:active,
.button-ghost-black-big:hover,
.button-ghost-black:active,
.button-ghost-black:hover,
.button-ghost-white:active,
.button-ghost-white:hover {
    text-decoration: none
}

.button-ghost-black,
.button-ghost-black-big {
    border: 1px solid #333;
    color: #333
}

@media (hover:hover) {

    .button-ghost-black-big:hover,
    .button-ghost-black:hover {
        background-color: #333;
        border-color: #333;
        color: #fff !important;
        transition: #333 .3s ease-in
    }
}

.button-ghost-black-big.active,
.button-ghost-black-big:active,
.button-ghost-black.active,
.button-ghost-black:active {
    background-color: #333;
    border-color: #333;
    color: #fff !important;
    transition: #333 .3s ease-in
}

.button-ghost-black-big {
    border: 2px solid #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 5px;
    padding: 8px 10px;
    text-align: center;
    text-transform: uppercase;
    width: 100%
}

.button-ghost-white {
    border: 1px solid #fff;
    color: #fff !important
}

.button-ghost-white:active,
.button-ghost-white:hover {
    background-color: #fff;
    color: #333 !important;
    transition: #fff .3s ease-in
}

.all-sites-link {
    padding: 1em 0
}

@media (max-width:767px) {
    .all-sites-link {
        justify-content: center !important;
        text-align: center
    }
}

.all-sites-link .button-ghost-black {
    padding: 0 30px
}

@media (max-width:767px) {
    .all-sites-link .button-ghost-black {
        width: 60%
    }
}

@media (max-width:479px) {
    .all-sites-link .button-ghost-black {
        width: auto
    }
}

.all-sites-link .button-ghost-black:hover {
    text-decoration: none
}

.btn-gradient {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .33), hsla(0, 0%, 100%, .66) 10%, #fff 30%, #fdfdfd);
    border: none;
    font-weight: 700;
    height: 50px;
    letter-spacing: .8px;
    outline: none;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
    width: 100%
}

.btn-gradient,
.btn-gradient i {
    font-size: 12px
}

.btn-rounded {
    background: #fff;
    border: 1px solid #333;
    border-radius: 40px;
    color: #333;
    font-size: 16px;
    margin-right: 8px;
    padding: 6px 16px
}

.btn-rounded.btn-active,
.btn-rounded:active,
.btn-rounded:focus,
.btn-rounded:hover {
    background: #333;
    border-color: #333;
    color: #fff;
    outline: 0 !important
}

.btn-rounded-blue {
    background: #0157ff;
    border-radius: 15px;
    color: #fff;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    min-width: 90px;
    padding: 0 10px;
    text-align: center;
    text-shadow: none;
    text-transform: capitalize
}

.btn-rounded-blue:hover {
    background: #003ee6;
    text-decoration: none
}

.bonus-btn {
    background-color: #fff;
    background-image: none;
    border: 2px solid #498bc9;
    color: #498bc9;
    font-size: 14px;
    font-weight: 400;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase
}

.reset-btn {
    background: 0 0;
    border: 0;
    color: #fff;
    line-height: 40px;
    padding: 0
}

.slots-button {
    background: #0157ff;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    text-align: left;
    width: 100%
}

@media (max-width:767px) {
    .slots-button {
        font-size: 15px;
        text-align: center
    }
}

.slots-button .text {
    float: left;
    line-height: 22px
}

@media (max-width:767px) {
    .slots-button .text {
        float: none
    }
}

.slots-button .arrow-box {
    display: inline-flex;
    float: right;
    height: 22px;
    width: 22px
}

@media (max-width:767px) {
    .slots-button .arrow-box {
        display: none
    }
}

.slots-button .arrow {
    border: 1px solid #fff;
    border-radius: 50%;
    float: right;
    height: 22px;
    width: 22px
}

@media (max-width:767px) {
    .slots-button .arrow {
        display: none
    }
}

.slots-button .arrow:after {
    _border-color: #fff;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    content: "";
    display: block;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#ffffff");
    height: 0;
    line-height: 0;
    margin: 6px auto;
    width: 0
}

.play_now_btn {
    background: #0157ff;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
    padding: 7px 3px;
    text-align: center;
    vertical-align: middle;
    width: 100%
}

@media (min-width:768px) and (max-width:991px) {
    .play_now_btn {
        height: auto;
        min-width: auto
    }
}

@media (min-width:992px) {
    .play_now_btn {
        font-size: 14px
    }
}

.play_now_btn:active,
.play_now_btn:focus,
.play_now_btn:hover {
    background: #003ee6;
    color: #fff;
    text-decoration: none
}

.play_now_btn span {
    color: #fff;
    font-size: 14px;
    vertical-align: middle
}

.visit_btn {
    padding: .4em;
    width: 100%
}

.close-button {
    align-self: flex-start;
    background: 0 0;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 22px;
    font-weight: 400;
    line-height: 10px;
    min-height: 15px;
    min-width: 20px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0
}

.button-yellow-slim {
    background-color: #ffca23;
    border: none;
    border-radius: 7px;
    color: #262626;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    line-height: 15px;
    text-align: center
}

.button-yellow-slim:active,
.button-yellow-slim:hover {
    text-decoration: none
}

.elements-view-btn {
    background-color: #06c;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center
}

.float {
    bottom: 40px;
    left: auto;
    right: 40px
}

.float a {
    background-color: #06c;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
    color: #fff;
    float: right;
    height: 60px;
    margin: 0 10px;
    position: relative;
    text-align: center;
    width: 60px
}

.float a>svg {
    margin-top: 15px
}

.float a .label-container {
    bottom: 62px;
    display: table;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: visibility 0s, opacity .5s ease;
    visibility: hidden;
    z-index: 100
}

.float a .label-container .label-text {
    background: #ccc;
    border-radius: 3px;
    color: #fff;
    display: block;
    font-size: 15px;
    padding: 10px
}

.float a .label-container svg {
    display: block;
    margin: -4px auto
}

.float a:hover {
    background-color: #1e87f1
}

.float a:hover .label-container {
    opacity: 1;
    visibility: visible
}

.float a .my-float {
    font-size: 24px;
    margin-top: 18px
}

.cookie-container {
    align-items: center;
    background-color: #ececec;
    bottom: 20px;
    display: flex;
    font-size: 12px;
    height: auto;
    justify-content: space-between;
    left: 20px;
    line-height: 17px;
    min-height: 50px;
    position: fixed;
    right: 0;
    width: 275px;
    z-index: 10001
}

.cookie-container-hidden {
    height: 0;
    min-height: 0;
    opacity: 0;
    overflow: hidden
}

.cookie-container .cookie-message {
    font-size: 12px;
    margin: 10px;
    padding: 0 26px 0 0
}

.cookie-container .cookie-dismiss {
    font-weight: 700;
    margin-top: 15px;
    text-transform: uppercase
}

.cookie-container .cookie-dismiss,
.cookie-container .cookies-learn-more {
    color: #333;
    cursor: pointer;
    text-decoration: underline
}

.cookie-container .cookies-learn-more:hover {
    color: #333
}

@media (max-width:720px) {
    .cookie-container .cookie-message {
        margin: 5px 25px;
        text-align: left
    }

    .cookie-container .cookie-dismiss {
        margin-bottom: 5px;
        margin-top: 5px
    }
}

.cookie-dismiss-btn-1 {
    color: #000;
    margin: 14px 0;
    padding: 0 10px
}

.slot-games-placeholder {
    height: auto
}

@media (min-width:1200px) {
    .slot-games-placeholder {
        height: 4530px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .slot-games-placeholder {
        height: 5375px
    }
}

@media (max-width:599px) and (min-width:321px) {
    .slot-games-placeholder {
        height: 7428px
    }
}

@media (max-width:767px) and (min-width:600px) {
    .slot-games-placeholder {
        height: 8786px
    }
}

@media (max-width:991px) and (min-width:768px) {
    .slot-games-placeholder {
        height: 3564px
    }
}

@media (max-width:1199px) and (min-width:992px) {
    .slot-games-placeholder {
        height: 4055px
    }
}

.loader-full-container {
    height: 100%
}

.oplist-limit-1 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-1 {
        height: 301px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-1 {
        height: 443px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-1 {
        height: 443px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-1 {
        height: 431px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-1 {
        height: 346px
    }
}

.oplist-limit-2 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-2 {
        height: 443px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-2 {
        height: 721px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-2 {
        height: 701px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-2 {
        height: 677px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-2 {
        height: 528px
    }
}

.oplist-limit-3 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-3 {
        height: 585px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-3 {
        height: 959px
    }
}

@media (max-width:599px) and (min-width:321px) {
    .oplist-limit-3 {
        height: 923px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-3 {
        height: 710px
    }
}

.oplist-limit-4 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-4 {
        height: 727px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-4 {
        height: 1225px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-4 {
        height: 1193px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-4 {
        height: 1169px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-4 {
        height: 892px
    }
}

.oplist-limit-5 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-5 {
        height: 869px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-5 {
        height: 1505px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-5 {
        height: 1451px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-5 {
        height: 1415px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-5 {
        height: 1074px
    }
}

.oplist-limit-6 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-6 {
        height: 1101px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-6 {
        height: 1751px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-6 {
        height: 1709px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-6 {
        height: 1661px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-6 {
        height: 1256px
    }
}

.oplist-limit-7 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-7 {
        height: 1153px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-7 {
        height: 1989px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-7 {
        height: 1955px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-7 {
        height: 1907px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-7 {
        height: 1438px
    }
}

.oplist-limit-8 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-8 {
        height: 1295px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-8 {
        height: 2235px
    }
}

@media (max-width:599px) and (min-width:321px) {
    .oplist-limit-8 {
        height: 2213px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-8 {
        height: 1620px
    }
}

.oplist-limit-9 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-9 {
        height: 1437px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-9 {
        height: 2515px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-9 {
        height: 2481px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-9 {
        height: 2411px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-9 {
        height: 1814px
    }
}

.oplist-limit-10 {
    height: auto
}

@media (min-width:992px) {
    .oplist-limit-10 {
        height: 1579px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-10 {
        height: 2773px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-10 {
        height: 2739px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-10 {
        height: 2657px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-10 {
        height: 1996px
    }
}

.oplist-limit-small-3 {
    height: auto
}

@media (min-width:1199px) {
    .oplist-limit-small-3 {
        height: 675px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-small-3 {
        height: 961px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-small-3 {
        height: 917px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-small-3 {
        height: 905px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-small-3 {
        height: 720px
    }
}

@media (max-width:1198px) and (min-width:992px) {
    .oplist-limit-small-3 {
        height: 687px
    }
}

.oplist-limit-small-10 {
    height: auto
}

@media (min-width:1199px) {
    .oplist-limit-small-10 {
        height: 2068px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .oplist-limit-small-10 {
        height: 2831px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .oplist-limit-small-10 {
        height: 2800px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .oplist-limit-small-10 {
        height: 2731px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .oplist-limit-small-10 {
        height: 2210px
    }
}

@media (max-width:1198px) and (min-width:992px) {
    .oplist-limit-small-10 {
        height: 2085px
    }
}

.single-offer-state-selector-placeholder {
    display: initial;
    height: auto
}

@media (min-width:1199px) {
    .single-offer-state-selector-placeholder {
        height: 238px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .single-offer-state-selector-placeholder {
        height: 435px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .single-offer-state-selector-placeholder {
        height: 429px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .single-offer-state-selector-placeholder {
        height: 354px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .single-offer-state-selector-placeholder {
        height: 274px
    }
}

@media (max-width:1198px) and (min-width:992px) {
    .single-offer-state-selector-placeholder {
        height: 249px
    }
}

.single-offer-placeholder {
    height: auto
}

@media (min-width:1199px) {
    .single-offer-placeholder {
        min-height: 152px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .single-offer-placeholder {
        min-height: 360px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .single-offer-placeholder {
        min-height: 354px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .single-offer-placeholder {
        min-height: 270px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .single-offer-placeholder {
        min-height: 194px
    }
}

@media (max-width:1198px) and (min-width:992px) {
    .single-offer-placeholder {
        min-height: 174px
    }
}

.modal {
    z-index: 9001
}

.modal-backdrop {
    z-index: 9000
}

.modal-open.select-state {
    overflow: auto
}

.modal-content {
    background-color: transparent;
    border: 0
}

.modal-header {
    border: 0;
    padding: 0
}

.modal-header .close {
    color: #fff;
    font-size: 35px;
    font-weight: 100;
    line-height: 35px;
    margin: -1rem 0 -1rem auto;
    opacity: 1;
    width: 35px
}

.modal-body {
    padding: 0
}

#slotModal .modal-body .game-demo-header {
    border-radius: 10px 10px 0 0;
    margin-top: 0
}

#slotModal .modal-body .oplist-slots {
    border-radius: 0 10px
}

#slotModal .modal-dialog {
    max-width: 970px !important;
    width: auto !important
}

@media (max-width:767px) {
    #slotModal .modal-dialog {
        max-width: inherit !important
    }
}

#slotModal .oplist-top-bar {
    display: none
}

.debug-bar .nav-tabs {
    background-color: #f7f7f7;
    border-top: 1px solid #d8d8d8 !important
}

.debug-bar .nav-tabs .nav-link.active,
.debug-bar .nav-tabs .nav-link.active:hover {
    background-color: #dc3545
}

.content-blocks {
    background: #fbfbfb;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px
}

.content-blocks .anchor {
    margin-top: -120px;
    position: absolute
}

.content-blocks label.title {
    background: #999;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px
}

.content-blocks-helper {
    font-size: 16px;
    margin-top: 20px;
    padding: 0
}

.content-blocks-helper li {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    margin-top: -1px;
    padding: 10px 5px
}

.content-blocks-helper li a {
    color: #000;
    cursor: pointer;
    font-weight: 700
}

.content-blocks-helper li a .icon {
    display: inline-block;
    transform: rotate(90deg)
}

.content-blocks-helper li a:hover {
    color: #dc3545;
    text-decoration: none
}

.content-blocks-helper li span {
    margin-left: 10px
}

.content-blocks-helper li span.icon-check {
    color: #00bb28
}

.content-blocks-helper li span.icon-cross {
    color: #ec0000
}

.redirect-box {
    background: #f2f2f2
}

.redirect-box .redirect-box__inner {
    background: 0 0;
    height: 95px;
    max-width: 1120px
}

@media (min-width:768px) {
    .redirect-box .redirect-box__inner {
        height: 60px
    }
}

@media (max-width:420px) {
    .redirect-box .redirect-box__inner {
        height: 115px
    }
}

.redirect-box .redirect-box__main-message {
    padding: 0
}

.redirect-box .redirect-box__actions {
    position: static;
    text-align: center
}

@media (min-width:768px) {
    .redirect-box .redirect-box__actions {
        position: absolute;
        right: 150px;
        top: 0
    }
}

.redirect-box .redirect-box__actions .automation-redirect-button {
    background: #2d9cdb;
    border-radius: 20px;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    height: auto;
    line-height: 28px;
    min-width: inherit;
    padding: 0 30px;
    text-align: center;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    text-transform: capitalize;
    width: auto
}

.redirect-box .redirect-box__actions .automation-redirect-button:hover {
    background: #2d9cdb;
    box-shadow: none
}

.redirect-box .redirect-box__main-message__flag {
    display: none
}

.redirect-box .redirect-box__main-message__text {
    color: rgba(49, 49, 49, .79);
    font-size: 13.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-right: 20px;
    text-align: center
}

.pt-5px {
    padding-top: 5px
}

.py-10px {
    padding-bottom: 10px;
    padding-top: 10px
}

.pl-10px {
    padding-left: 10px
}

.pr-10px {
    padding-right: 10px
}

.ml-20-px {
    margin-left: 20px
}

.ml-30-px {
    margin-left: 30px
}

.ml-35-px {
    margin-left: 35px
}

.mr-10-px {
    margin-right: 10px
}

.mr-15-px {
    margin-right: 15px
}

.mr-20-px {
    margin-right: 20px
}

.mr-70px {
    margin-right: 70px
}

.mt-20-px {
    margin-top: 20px
}

.mb-20-px {
    margin-bottom: 20px
}

.w-10 {
    width: 10%
}

.w-20 {
    width: 20%
}

.w-30 {
    width: 30%
}

.w-40 {
    width: 40%
}

.w-50 {
    width: 50%
}

.w-60 {
    width: 60%
}

.w-70 {
    width: 70%
}

.w-80 {
    width: 80%
}

.w-90 {
    width: 90%
}

.mw-80 {
    max-width: 80%
}

.w-280px {
    width: 280px
}

.w-285px {
    width: 285px
}

.w-80px {
    width: 80px
}

.h-80px {
    height: 80px
}

.mw-25px {
    min-width: 25px
}

@media (min-width:576px) {
    .pr-10px-sm {
        padding-right: 10px
    }

    .pl-10px-sm {
        padding-left: 10px
    }
}

@media (min-width:768px) {
    .w-md-80 {
        width: 80%
    }
}

.gap-10 {
    gap: 10px
}

.opacity-0 {
    opacity: 0
}

@media (max-width:760px) {
    .noscroll-sm {
        overflow: hidden
    }
}

.global-search-container.home-banner {
    margin-top: 16px
}

@media (max-width:767px) {
    .global-search-container.home-banner {
        display: none
    }
}

.global-search-container {
    display: block;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    width: 100%;
    z-index: 999
}

.global-search-container ::-webkit-scrollbar {
    width: 10px
}

.global-search-container ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #999;
    border: 2px solid transparent
}

.global-search-container ::-webkit-scrollbar-thumb:hover {
    background-color: #777
}

.global-search-container ::-webkit-scrollbar-track {
    background-color: transparent
}

.global-search-container .ais-SearchBox-input,
.global-search-container .search-input {
    background-color: #fff;
    border: 2px solid #818181;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(44, 46, 48, .07);
    color: #818181;
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    padding: 0 60px;
    width: 100%
}

.global-search-container .ais-SearchBox-input:focus,
.global-search-container .search-input:focus {
    outline: none
}

.global-search-container .ais-SearchBox-submit {
    background-color: transparent;
    border: none;
    cursor: pointer;
    left: 10px;
    margin: 10px 10px 0;
    position: absolute;
    top: 0
}

.global-search-container .ais-SearchBox-submit .search-input-icon {
    height: 25px
}

.global-search-container .search-input-close-label {
    color: #999;
    cursor: pointer;
    display: none;
    font-size: smaller;
    line-height: 48px;
    margin-right: 45px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    z-index: 1000
}

.global-search-container .search-input-close-icon {
    cursor: pointer;
    display: none;
    height: 20px;
    margin: 13px 15px 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    z-index: 1000
}

.global-search-container .search-result {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: 0 0 8px #8e8e8e;
    display: block;
    overflow-y: scroll;
    transition: max-height .2s linear;
    width: 100%
}

@media (min-width:768px) {
    .global-search-container .search-result {
        max-height: 450px
    }
}

.global-search-container .search-result .total-items {
    bottom: -52px;
    color: #999;
    font-size: smaller;
    left: 0;
    line-height: 50px;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 999
}

.global-search-container .search-result ul {
    height: calc(100vh - 106px);
    list-style: none;
    margin: 0;
    padding: 0
}

@media (min-width:768px) {
    .global-search-container .search-result ul {
        height: auto;
        max-height: 450px
    }
}

.global-search-container .search-result li {
    color: #333;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 100;
    justify-content: center;
    min-height: 50px;
    padding: 0 20px;
    width: 100%
}

.global-search-container .search-result li span {
    padding-left: 40px
}

.global-search-container .search-result li a {
    align-items: center;
    color: #333;
    display: flex;
    padding: 8px 15px 8px 40px;
    text-decoration: none
}

.global-search-container .search-result li a .link-content {
    display: inline-block;
    font-weight: 100;
    text-transform: none;
    width: 70%
}

.global-search-container .search-result li a .link-type {
    display: none
}

.global-search-container .search-result .footer {
    background-color: #fff;
    border-top: 1px solid #d2d0d0;
    box-shadow: 0 5px 10px 0 #8e8e8e;
    cursor: pointer;
    left: 0;
    line-height: 50px;
    margin-top: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%
}

.global-search-container .ais-SearchBox-reset {
    display: none
}

.global-search-container input[type=search i]::-webkit-search-cancel-button {
    display: none
}

.global-search-container.navigation.closed {
    margin-left: 10px;
    right: 68px;
    width: 25px
}

.global-search-container.navigation.closed,
.global-search-container.navigation.opened {
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    margin-right: 0;
    position: absolute;
    vertical-align: top;
    z-index: 1030
}

.global-search-container.navigation.opened {
    right: auto;
    transition: width .2s ease-in;
    width: 100%
}

@media (min-width:991px) {
    .global-search-container.navigation.opened {
        margin-left: 224px;
        max-width: calc(100% - 450px)
    }
}

.global-search-container.navigation .ais-SearchBox-submit {
    margin: 15px 0 0
}

.global-search-container.navigation .ais-SearchBox-input,
.global-search-container.navigation .search-input {
    background-color: #fff;
    border: 1px solid #565656;
    color: #818181;
    font-size: 16px;
    font-weight: 100;
    height: 54px;
    padding: 0 45px;
    width: 100%
}

.global-search-container.navigation .ais-SearchBox-input::-moz-placeholder,
.global-search-container.navigation .search-input::-moz-placeholder {
    font-size: 15px;
    font-weight: 100
}

.global-search-container.navigation .ais-SearchBox-input:-ms-input-placeholder,
.global-search-container.navigation .search-input:-ms-input-placeholder {
    font-size: 15px;
    font-weight: 100
}

.global-search-container.navigation .ais-SearchBox-input::placeholder,
.global-search-container.navigation .search-input::placeholder {
    font-size: 15px;
    font-weight: 100
}

@media (min-width:991px) {

    .global-search-container.navigation .ais-SearchBox-input::-moz-placeholder,
    .global-search-container.navigation .search-input::-moz-placeholder {
        color: #000 !important
    }

    .global-search-container.navigation .ais-SearchBox-input:-ms-input-placeholder,
    .global-search-container.navigation .search-input:-ms-input-placeholder {
        color: #000 !important
    }

    .global-search-container.navigation .ais-SearchBox-input::placeholder,
    .global-search-container.navigation .search-input::placeholder {
        color: #000 !important
    }
}

@media (max-width:991px) {

    .global-search-container.navigation .ais-SearchBox-input,
    .global-search-container.navigation .search-input {
        margin-left: 0;
        max-width: 100%;
        transition: none;
        width: 100%
    }
}

@media (min-width:991px) {

    .global-search-container.navigation .ais-SearchBox-input,
    .global-search-container.navigation .search-input {
        background-color: #fff;
        border: 1px solid #565656;
        border-radius: 4px;
        color: #000;
        width: 100%
    }

    .global-search-container.navigation .ais-SearchBox-input::-moz-placeholder,
    .global-search-container.navigation .search-input::-moz-placeholder {
        color: #c4c4c4
    }

    .global-search-container.navigation .ais-SearchBox-input:-ms-input-placeholder,
    .global-search-container.navigation .search-input:-ms-input-placeholder {
        color: #c4c4c4
    }

    .global-search-container.navigation .ais-SearchBox-input::placeholder,
    .global-search-container.navigation .search-input::placeholder {
        color: #c4c4c4
    }
}

.global-search-container.navigation .search-icon {
    cursor: pointer;
    height: 25px;
    left: 0;
    margin-top: 15px;
    position: absolute;
    top: 0;
    width: 20px
}

.global-search-container.navigation .search-input-close-label {
    display: block;
    line-height: 54px
}

.global-search-container.navigation .search-input-close-icon {
    display: block;
    margin-top: 16px
}

.global-search-container.navigation .search-result li span {
    padding-left: 25px
}

.global-search-container.navigation .search-result li a {
    padding: 8px 15px 8px 25px
}

@media (min-width:768px) {

    .template-homepage-generic .global-search-container.navigation,
    .us-homepage .global-search-container.navigation {
        display: none
    }
}

.world-cup-banner {
    background-image: url(https://objects.kaxmedia.com/auto/o/152350/37ea3fc26a.jpeg);
    background-position: 50%;
    background-repeat: repeat;
    color: #fff;
    display: none
}

.world-cup-banner.sticky {
    position: fixed;
    right: 0;
    top: 96px;
    width: 100%;
    z-index: 1020
}

.world-cup-banner .container {
    padding: 10px
}

.world-cup-banner .btn-black {
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    padding: 5px 15px
}

.world-cup-banner .btn-black:hover {
    text-underline: none !important;
    color: #ddd
}

.world-cup-banner .close-icon {
    cursor: pointer
}

.mouse-all {
    pointer-events: all
}

.mouse-none {
    pointer-events: none
}

.cc-responsive-wrap {
    overflow-y: hidden
}

ol.cc-list-with-thumbnail,
ul.cc-list-with-thumbnail {
    margin: 0;
    padding: 0
}

ol.cc-list-with-thumbnail li,
ul.cc-list-with-thumbnail li {
    align-items: center;
    display: flex;
    list-style: none !important;
    margin-bottom: 20px !important
}

ol.cc-list-with-thumbnail li img,
ul.cc-list-with-thumbnail li img {
    clear: both;
    float: left;
    margin-right: 20px
}

ol.cc-list-with-thumbnail li p,
ul.cc-list-with-thumbnail li p {
    display: block;
    margin-left: 0;
    overflow: auto
}

.faq ol,
.faq ul {
    list-style: inherit !important
}

.automation-faq-container .accordion h3 {
    font-weight: 400
}

.intro-short-subtext p:last-child {
    margin-bottom: 0
}

.scroller {
    overflow-y: scroll;
    scrollbar-color: #999 transparent;
    scrollbar-width: thin
}

.scroller ::-webkit-scrollbar {
    width: 10px
}

.scroller ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #999;
    border: 2px solid transparent
}

.scroller ::-webkit-scrollbar-thumb:hover {
    background-color: #777
}

.scroller ::-webkit-scrollbar-track {
    background-color: transparent
}


/*** PAGE CONTENT ***/
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-selection--single .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
    padding-left: 20px;
    padding-right: 8px
}

.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline;
    list-style: none;
    padding: 0
}

.select2-container .select2-selection--multiple .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em
}

.select2-container .select2-search--inline .select2-search__field {
    border: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 100%;
    height: 18px;
    margin-left: 5px;
    margin-top: 5px;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    resize: none;
    vertical-align: bottom;
    word-break: keep-all
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    left: -100000px;
    position: absolute;
    width: 100%;
    z-index: 1051
}

.select2-results {
    display: block
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0
}

.select2-results__option {
    padding: 6px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-results__option--selectable {
    cursor: pointer
}

.select2-container--open .select2-dropdown {
    left: 0
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-search--dropdown {
    display: block;
    padding: 4px
}

.select2-search--dropdown .select2-search__field {
    box-sizing: border-box;
    padding: 4px;
    width: 100%
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.select2-search--dropdown.select2-search--hide {
    display: none
}

.select2-close-mask {
    background-color: #fff;
    border: 0;
    display: block;
    filter: alpha(opacity=0);
    height: auto;
    left: 0;
    margin: 0;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: auto;
    z-index: 99
}

.select2-hidden-accessible {
    clip: rect(0 0 0 0) !important;
    border: 0 !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    height: 26px;
    margin-right: 20px;
    padding-right: 0
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888;
    border-width: 0 4px 5px
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
    padding-right: 25px
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    font-weight: 700;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 1px;
    position: absolute;
    right: 0
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    max-width: 100%;
    overflow: hidden;
    padding: 0 0 0 20px;
    position: relative;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #aaa;
    border-top-left-radius: 4px;
    color: #999;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    left: 0;
    padding: 0 4px;
    position: absolute;
    top: 0
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    background-color: #f1f1f1;
    color: #333;
    outline: none
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #aaa;
    border-right: none;
    border-top-left-radius: 0;
    border-top-right-radius: 4px
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
    float: left;
    margin-left: 10px;
    margin-right: auto
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #000;
    outline: 0
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa
}

.select2-container--default .select2-search--inline .select2-search__field {
    -webkit-appearance: textfield;
    background: 0 0;
    border: none;
    box-shadow: none;
    outline: 0
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em
}

.select2-container--default .select2-results__option--group {
    padding: 0
}

.select2-container--default .select2-results__option--disabled {
    color: #999
}

.select2-container--default .select2-results__option--selected {
    background-color: #ddd
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #5897fb;
    color: #fff
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    background-image: linear-gradient(180deg, #fff 50%, #eee);
    background-repeat: repeat-x;
    border: 1px solid #aaa;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
    outline: 0
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    height: 26px;
    margin-right: 20px
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    background-image: linear-gradient(180deg, #eee 50%, #ccc);
    background-repeat: repeat-x;
    border: none;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
    height: 26px;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 20px
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
    float: left
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #aaa;
    border-top-left-radius: 4px;
    left: 1px;
    right: auto
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: 0 0;
    border: none
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888;
    border-width: 0 4px 5px
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    background-image: linear-gradient(180deg, #fff, #eee 50%);
    background-repeat: repeat-x;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    background-image: linear-gradient(180deg, #eee 50%, #fff);
    background-repeat: repeat-x;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    padding-bottom: 5px;
    padding-right: 5px
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    color: #888;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0 4px
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
    outline: none
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0
}

.select2-container--classic .select2-search--inline .select2-search__field {
    box-shadow: none;
    outline: 0
}

.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none
}

.select2-container--classic .select2-dropdown--below {
    border-top: none
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto
}

.select2-container--classic .select2-results__option--group {
    padding: 0
}

.select2-container--classic .select2-results__option--disabled {
    color: grey
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #3875d7;
    color: #fff
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb
}

.homepage-panel {
    height: 420px;
    position: relative
}

@media (min-width:1921px) {
    .homepage-panel {
        background-size: 100% !important
    }
}

.homepage-panel:before {
    background-image: url(https://objects.kaxmedia.com/auto/o/67761/b09dcc1a5a.svg);
    background-size: cover;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: .21;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0
}

.homepage-panel .slider-text {
    height: auto !important;
    padding-top: 30px;
    position: relative
}

.homepage-panel .slider-text .tick-points {
    z-index: 2
}

.homepage-panel .slider-text .tick-points ul {
    margin: 0;
    padding: 0;
    text-align: center
}

.homepage-panel .slider-text .tick-points ul li {
    background: url(https://objects.kaxmedia.com/auto/o/67752/e72d2a85db.svg) no-repeat;
    background-position: 11px 7px;
    background-size: 17px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 34px;
    margin: 6px 0 0 !important;
    text-transform: uppercase
}

.homepage-panel .slider-text .tick-points ul li a {
    color: #000;
    font-weight: 700;
    padding-left: 39px
}

.homepage-panel .slider-text .text {
    padding: 15px 0;
    text-align: center
}

.homepage-panel .au .slider-text .tick-points ul {
    text-align: left
}

.homepage-panel .slider-button {
    clear: both;
    float: none;
    text-align: center;
    width: 100%
}

.homepage-panel .slider-bottom {
    bottom: 0;
    position: absolute;
    width: 100%
}

.homepage-panel .slider-links {
    background: 0 0;
    height: 100%;
    margin: 30px auto 0;
    padding-bottom: 20px
}

.homepage-panel .slider-bg-rounded {
    height: 63px;
    width: 100%
}

@media (min-width:1921px) {
    .homepage-panel .slider-bg-rounded {
        background-size: 100% !important
    }
}

.homepage-panel .exclusive-offers {
    position: absolute;
    top: -30px
}

.homepage-panel .exclusive-offers-container {
    position: relative
}

.homepage-panel .top-icons {
    background-color: transparent !important;
    border-radius: 4px;
    margin: 0 0 15px;
    padding-right: 0;
    z-index: 2
}

.homepage-panel .top-icons a {
    background-color: #fff !important;
    border: 2px solid #e7eaee;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(44, 46, 48, .07);
    display: flex;
    height: 72px;
    width: 100%
}

.homepage-panel .top-icons a:hover {
    text-decoration: none
}

.homepage-panel .top-icons a img {
    float: right;
    margin: 0 auto;
    max-height: 52px
}

.homepage-panel .top-icons a .title-type {
    color: #333;
    font-size: 16px;
    font-weight: 600
}

.homepage-panel .top-icons:hover a {
    border: none;
    box-shadow: 0 0 12px 1px #b5b5b5;
    transition: all .4s
}

.homepage-panel .search-bar-parent {
    display: block;
    margin: 32px 0 0;
    position: relative
}

.homepage-panel .search-bar-parent .nav-search-container {
    color: #fff;
    display: block;
    float: left;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    width: 100%;
    z-index: 999
}

.homepage-panel .search-bar-parent .nav-search-container .nav-search-img {
    cursor: pointer;
    height: 25px;
    left: 15px;
    margin: 13px 10px 0;
    position: absolute;
    top: 0;
    transition: all .12s linear;
    width: 25px
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: 0 0 8px #8e8e8e;
    display: block;
    max-height: 60vh;
    overflow-y: scroll;
    transition: max-height .2s linear;
    width: 100%
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container li {
    color: #333;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 100;
    justify-content: center;
    min-height: 54px;
    padding: 0 20px;
    width: 100%
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container li a {
    align-items: center;
    color: #333;
    display: flex;
    padding: 8px 15px 8px 35px;
    text-decoration: none
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container li a .link-content {
    display: inline-block;
    font-weight: 100;
    text-transform: none;
    width: 70%
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container li a .link-type {
    display: none
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container .load-more {
    border-top: 1px solid #d2d0d0;
    color: #333;
    font-size: 15px;
    font-weight: 100;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    width: 100%
}

.homepage-panel .search-bar-parent .nav-search-container .search-results-container .load-more:hover {
    background-color: #1e84cc;
    color: #fff;
    cursor: pointer;
    transition: all .3s linear
}

.homepage-panel .search-bar-parent .nav-search-container #nav-search-new {
    background-color: #fff;
    border: 2px solid #818181;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(44, 46, 48, .07);
    color: #818181;
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    padding: 0 60px;
    width: 100%
}

.homepage-panel .search-bar-parent .nav-search-container #nav-search-new:-ms-input-placeholder {
    color: #777 !important
}

.homepage-panel .search-bar-parent .nav-search-container #nav-search-new::-moz-placeholder {
    color: #777 !important
}

.homepage-panel .search-bar-parent .nav-search-container #nav-search-new::placeholder {
    color: #777 !important
}

.homepage-panel .search-bar-parent .nav-search-container #nav-search-new:focus {
    outline: none
}

.homepage-panel .search-bar-parent .nav-search-container #search-results-list-new {
    list-style: none;
    margin: 0;
    padding: 0
}

@media (min-width:1650px) {
    .homepage-panel:before {
        top: 0 !important
    }
}

@media only screen and (min-device-width:768px) and (max-device-width:991px) {
    .homepage-panel .slider-container {
        margin: 0 auto;
        width: 100%
    }

    .homepage-panel .page-title {
        text-align: center
    }
}

@media (max-width:991px) {
    .homepage-panel {
        height: auto
    }

    .homepage-panel:before {
        background-image: url(https://objects.kaxmedia.com/auto/o/68015/d7a3fa224e.svg);
        background-size: cover;
        content: "";
        display: block;
        height: 600px;
        left: 0;
        opacity: .17;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1
    }

    .homepage-panel .slider-text {
        font-size: 1.2em;
        height: auto
    }

    .homepage-panel .slider-text .tick-points {
        display: table !important;
        padding-top: 10px;
        width: 100% !important
    }

    .homepage-panel .slider-links {
        height: auto;
        margin-top: 20px !important;
        max-width: 736px;
        padding-bottom: 10px;
        padding-top: 0
    }

    .homepage-panel .slider-links .top-icons a {
        overflow: hidden
    }

    .homepage-panel .slider-links .top-icons .link_text {
        line-height: normal;
        margin-left: -12px;
        padding-left: 0
    }

    .homepage-panel .slider-links .top-icons .link_image {
        padding-left: 0
    }

    .homepage-panel .slider-links .top-icons .link_image img {
        float: none;
        min-width: 42px
    }

    .homepage-panel .slider-bottom {
        position: relative
    }
}

@media (max-width:767px) {
    .homepage-panel {
        background-position: top;
        background-size: contain
    }

    .homepage-panel:before {
        background-image: url(https://objects.kaxmedia.com/auto/o/68015/d7a3fa224e.svg);
        background-size: cover;
        content: "";
        display: block;
        height: 350px;
        left: 0;
        opacity: .17;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1
    }

    .homepage-panel.au .slider-text .tick-points ul {
        text-align: left !important
    }

    .homepage-panel .slider-text .text {
        padding: 20px
    }

    .homepage-panel .slider-text .tick-points ul {
        text-align: left !important
    }

    .homepage-panel .slider-text .tick-points ul li {
        background-position: 0 3px;
        line-height: inherit
    }

    .homepage-panel .slider-text .tick-points ul li a {
        font-size: 15px;
        line-height: 25px;
        padding-left: 25px;
        padding-right: 20px
    }

    .homepage-panel .slider-text .slider-links {
        padding: 0 15px 0 20px
    }

    .homepage-panel .slider-text .slider-links .top-icons {
        border: none;
        border-radius: 4px;
        margin: 0 0 8px;
        padding-left: 0;
        padding-right: 8px;
        position: relative;
        z-index: 2
    }

    .homepage-panel .slider-text .slider-links .top-icons a .title-type {
        display: inline-block;
        font-size: 14px;
        line-height: normal
    }
}

@media (max-width:767px) and (max-width:400px) {
    .homepage-panel .slider-text .slider-links .top-icons a .title-type {
        font-size: 11px
    }
}

@media (max-width:767px) {
    .homepage-panel .slider-text .search-bar-parent {
        margin: 10px 0 0
    }
}

.homepage-panel-generic h1 {
    font-weight: 600
}

.homepage-panel-generic .homepage-panel-content>.left-container-content {
    width: 55% !important
}

.homepage-panel-generic .homepage-panel-content>.left-container-content .best-online-gambling {
    width: 100% !important
}

@media (max-width:767px) {
    .homepage-panel-generic .homepage-panel-content>.left-container-content {
        width: 100% !important
    }
}

.homepage-panel-generic .homepage-panel-content>.header-large-image {
    margin: auto
}

.homepage-panel-generic .homepage-panel-content>.header-large-image img {
    margin-top: 0;
    max-height: 217px
}

.homepage-panel-generic .featured>.featured-images>img {
    height: -moz-fit-content;
    height: fit-content
}

.homepage-panel-generic .best-online-gambling>p {
    font-size: 18px;
    margin-bottom: 0;
    width: 95%
}

@media (max-width:767px) {
    .homepage-panel-generic .best-online-gambling>p {
        font-size: 16px;
        width: 100%
    }
}

.template-homepage-generic,
.us-homepage {
    background: none !important
}

.template-homepage-generic .spacing-bottom,
.template-homepage-generic .spacing-bottom-low,
.us-homepage .spacing-bottom,
.us-homepage .spacing-bottom-low {
    clear: both;
    display: table;
    width: 100%
}

.template-homepage-generic .latest-news-articals,
.us-homepage .latest-news-articals {
    color: #909090;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 25px
}

.template-homepage-generic .gdc-free-slot-game .title,
.us-homepage .gdc-free-slot-game .title {
    color: #dc3545;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    min-height: 55px
}

@media (max-width:767px) {

    .template-homepage-generic .gdc-free-slot-game .title,
    .us-homepage .gdc-free-slot-game .title {
        font-size: 12px;
        line-height: 14px
    }
}

.template-homepage-generic .gdc-free-slot-game .slots-button,
.us-homepage .gdc-free-slot-game .slots-button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 22px
}

@media (max-width:767px) {

    .template-homepage-generic .slot_games_wrapper,
    .us-homepage .slot_games_wrapper {
        margin: 0 !important
    }
}

.template-homepage-generic .knowledge-container,
.us-homepage .knowledge-container {
    background-color: #f8f8f8
}

.template-homepage-generic .knowledge-container .knowledge-link-div,
.us-homepage .knowledge-container .knowledge-link-div {
    padding: 8px 0 8px 8px;
    width: 33%
}

@media (max-width:767px) {

    .template-homepage-generic .knowledge-container .knowledge-link-div,
    .us-homepage .knowledge-container .knowledge-link-div {
        width: 50%
    }
}

@media (max-width:576px) {

    .template-homepage-generic .knowledge-container .knowledge-link-div,
    .us-homepage .knowledge-container .knowledge-link-div {
        width: 100%
    }
}

.template-homepage-generic .knowledge-container a,
.us-homepage .knowledge-container a {
    background-color: #f8f8f8;
    color: #067bc2;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px
}

.template-homepage-generic .nav-search-container,
.us-homepage .nav-search-container {
    color: #fff;
    display: block;
    float: left;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    width: 100%;
    z-index: 999
}

.template-homepage-generic .nav-search-container .nav-search-img,
.us-homepage .nav-search-container .nav-search-img {
    cursor: pointer;
    height: 25px;
    left: 15px;
    margin: 13px 10px 0;
    position: absolute;
    top: 0;
    transition: all .12s linear;
    width: 25px
}

.template-homepage-generic .nav-search-container .search-results-container,
.us-homepage .nav-search-container .search-results-container {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: 0 0 8px #8e8e8e;
    display: block;
    max-height: 500px;
    overflow-y: scroll;
    transition: max-height .2s linear;
    width: 100%
}

.template-homepage-generic .nav-search-container .search-results-container li,
.us-homepage .nav-search-container .search-results-container li {
    color: #333;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 100;
    justify-content: center;
    min-height: 54px;
    padding: 0 20px;
    width: 100%
}

.template-homepage-generic .nav-search-container .search-results-container li a,
.us-homepage .nav-search-container .search-results-container li a {
    align-items: center;
    color: #333;
    display: flex;
    padding: 8px 15px 8px 35px;
    text-decoration: none
}

.template-homepage-generic .nav-search-container .search-results-container li a .link-content,
.us-homepage .nav-search-container .search-results-container li a .link-content {
    display: inline-block;
    font-weight: 100;
    text-transform: none;
    width: 70%
}

.template-homepage-generic .nav-search-container .search-results-container li a .link-type,
.us-homepage .nav-search-container .search-results-container li a .link-type {
    display: none
}

.template-homepage-generic .nav-search-container .search-results-container .load-more,
.us-homepage .nav-search-container .search-results-container .load-more {
    border-top: 1px solid #d2d0d0;
    color: #333;
    font-size: 15px;
    font-weight: 100;
    line-height: 54px;
    text-align: center;
    text-transform: uppercase;
    width: 100%
}

.template-homepage-generic .nav-search-container .search-results-container .load-more:hover,
.us-homepage .nav-search-container .search-results-container .load-more:hover {
    background-color: #1e84cc;
    color: #fff;
    cursor: pointer;
    transition: all .3s linear
}

.template-homepage-generic .nav-search-container #nav-search-new,
.us-homepage .nav-search-container #nav-search-new {
    background-color: #fff;
    border: 2px solid #818181;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(44, 46, 48, .07);
    color: #818181;
    font-size: 16px;
    font-weight: 100;
    height: 48px;
    padding: 0 60px;
    width: 100%
}

.template-homepage-generic .nav-search-container #nav-search-new:-ms-input-placeholder,
.us-homepage .nav-search-container #nav-search-new:-ms-input-placeholder {
    color: #777 !important
}

.template-homepage-generic .nav-search-container #nav-search-new::-moz-placeholder,
.us-homepage .nav-search-container #nav-search-new::-moz-placeholder {
    color: #777 !important
}

.template-homepage-generic .nav-search-container #nav-search-new::placeholder,
.us-homepage .nav-search-container #nav-search-new::placeholder {
    color: #777 !important
}

.template-homepage-generic .nav-search-container #nav-search-new:focus,
.us-homepage .nav-search-container #nav-search-new:focus {
    outline: none
}

.template-homepage-generic .nav-search-container #search-results-list-new,
.us-homepage .nav-search-container #search-results-list-new {
    list-style: none;
    margin: 0;
    padding: 0
}

@media (min-width:768px) {

    .template-homepage-generic .global-nav-container .global-nav .nav-search-container,
    .us-homepage .global-nav-container .global-nav .nav-search-container {
        display: none
    }
}

.template-homepage-generic .read-more-wrapper .btn-gradient,
.template-homepage-generic .read-more-wrapper .btn-gradient i,
.us-homepage .read-more-wrapper .btn-gradient,
.us-homepage .read-more-wrapper .btn-gradient i {
    font-size: 18px
}

.homepage-panel-generic,
.homepage-panel-us {
    background-color: #ecedef
}

.homepage-panel-generic .homepage-panel-container,
.homepage-panel-us .homepage-panel-container {
    flex-direction: column
}

@media (max-width:767px) {

    .homepage-panel-generic .homepage-panel-container,
    .homepage-panel-us .homepage-panel-container {
        flex-direction: row;
        padding-left: 0;
        padding-right: 0
    }
}

.homepage-panel-generic .search-bar,
.homepage-panel-us .search-bar {
    padding-top: 16px
}

@media (max-width:767px) {

    .homepage-panel-generic .search-bar,
    .homepage-panel-us .search-bar {
        display: none
    }
}

.homepage-panel-generic .header-small-image,
.homepage-panel-us .header-small-image {
    display: none;
    width: 100%
}

.homepage-panel-generic .header-small-image img,
.homepage-panel-us .header-small-image img {
    height: auto;
    max-width: 100%
}

@media (max-width:767px) {

    .homepage-panel-generic .header-small-image,
    .homepage-panel-us .header-small-image {
        display: block
    }
}

.homepage-panel-generic .header-large-image img,
.homepage-panel-us .header-large-image img {
    margin-top: -120px;
    width: 100%
}

@media (max-width:991px) {

    .homepage-panel-generic .header-large-image img,
    .homepage-panel-us .header-large-image img {
        margin-top: -90px
    }
}

@media (max-width:767px) {

    .homepage-panel-generic .header-large-image img,
    .homepage-panel-us .header-large-image img {
        display: none;
        margin-top: -60px
    }
}

.homepage-panel-generic .homepage-panel-content,
.homepage-panel-us .homepage-panel-content {
    flex-direction: row;
    padding-top: 30px
}

.homepage-panel-generic .homepage-panel-content .left-container-content,
.homepage-panel-us .homepage-panel-content .left-container-content {
    width: 60%
}

.homepage-panel-generic .homepage-panel-content .left-container-content .best-online-gambling,
.homepage-panel-us .homepage-panel-content .left-container-content .best-online-gambling {
    font-size: 14px;
    width: 80%
}

@media (max-width:767px) {

    .homepage-panel-generic .homepage-panel-content .left-container-content .best-online-gambling,
    .homepage-panel-us .homepage-panel-content .left-container-content .best-online-gambling {
        width: 100%
    }
}

.homepage-panel-generic .homepage-panel-content .left-container-content .featured,
.homepage-panel-us .homepage-panel-content .left-container-content .featured {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    width: 60%
}

.homepage-panel-generic .homepage-panel-content .left-container-content .featured h4,
.homepage-panel-us .homepage-panel-content .left-container-content .featured h4 {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px
}

.homepage-panel-generic .homepage-panel-content .left-container-content .featured img,
.homepage-panel-us .homepage-panel-content .left-container-content .featured img {
    padding: 5px
}

@media (max-width:576px) {

    .homepage-panel-generic .homepage-panel-content .left-container-content .featured img,
    .homepage-panel-us .homepage-panel-content .left-container-content .featured img {
        max-width: 25%
    }
}

.homepage-panel-generic .homepage-panel-content .left-container-content .featured .featured-images,
.homepage-panel-us .homepage-panel-content .left-container-content .featured .featured-images {
    flex-wrap: nowrap;
    justify-content: space-between !important
}

@media (max-width:576px) {

    .homepage-panel-generic .homepage-panel-content .left-container-content .featured,
    .homepage-panel-us .homepage-panel-content .left-container-content .featured {
        width: 90%
    }
}

@media (max-width:375px) {

    .homepage-panel-generic .homepage-panel-content .left-container-content .featured,
    .homepage-panel-us .homepage-panel-content .left-container-content .featured {
        width: 100%
    }
}

@media (max-width:767px) {

    .homepage-panel-generic .homepage-panel-content .left-container-content,
    .homepage-panel-us .homepage-panel-content .left-container-content {
        width: 100%
    }

    .homepage-panel-generic .homepage-panel-content,
    .homepage-panel-us .homepage-panel-content {
        flex-direction: column;
        padding-top: 0
    }
}

.homepage-panel-generic .container-padding,
.homepage-panel-us .container-padding {
    padding: 0 7px
}

@media (min-width:768px) {

    .homepage-panel-generic .container-padding,
    .homepage-panel-us .container-padding {
        padding: 0
    }
}

.homepage-panel-generic .categories h4,
.homepage-panel-us .categories h4 {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px
}

.homepage-panel-generic .categories .categories-wrapper,
.homepage-panel-us .categories .categories-wrapper {
    flex-wrap: nowrap !important
}

@media (max-width:767px) {

    .homepage-panel-generic .categories .categories-wrapper,
    .homepage-panel-us .categories .categories-wrapper {
        flex-wrap: wrap !important
    }
}

.homepage-panel-generic .categories .category,
.homepage-panel-us .categories .category {
    background-color: #fff !important;
    border-radius: 4px;
    margin: 10px 2.5px;
    padding-bottom: 5px;
    padding-top: 5px
}

@media (max-width:991px) {

    .homepage-panel-generic .categories .category,
    .homepage-panel-us .categories .category {
        margin: 0 10px 10px 0
    }
}

@media (max-width:1199px) and (min-width:601px) {

    .homepage-panel-generic .categories .category,
    .homepage-panel-us .categories .category {
        flex-wrap: wrap !important;
        width: 31.5% !important
    }
}

@media (max-width:600px) and (min-width:306px) {

    .homepage-panel-generic .categories .category,
    .homepage-panel-us .categories .category {
        width: 40% !important
    }
}

@media (max-width:305px) {

    .homepage-panel-generic .categories .category,
    .homepage-panel-us .categories .category {
        margin: 5px auto;
        text-align: center;
        width: 98.5% !important
    }
}

.homepage-panel-generic .categories .category a:hover,
.homepage-panel-us .categories .category a:hover {
    text-decoration: none
}

.homepage-panel-generic .categories .category img,
.homepage-panel-us .categories .category img {
    align-self: center !important;
    height: 40px;
    margin: 14px
}

@media (max-width:991px) {

    .homepage-panel-generic .categories .category img,
    .homepage-panel-us .categories .category img {
        height: 35px;
        margin: 9px
    }
}

@media (max-width:576px) {

    .homepage-panel-generic .categories .category img,
    .homepage-panel-us .categories .category img {
        height: 30px;
        margin: 3px
    }
}

.homepage-panel-generic .categories .category span,
.homepage-panel-us .categories .category span {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    padding-right: 15px
}

@media (max-width:576px) {

    .homepage-panel-generic .categories .category span,
    .homepage-panel-us .categories .category span {
        font-size: 12.16px;
        line-height: 14.44px
    }
}

.homepage-panel-generic .categories .category-item,
.homepage-panel-us .categories .category-item {
    background-color: #fff !important;
    border-radius: 4px;
    margin: 10px 2.5px;
    padding-bottom: 5px;
    padding-top: 5px
}

@media (max-width:991px) {

    .homepage-panel-generic .categories .category-item,
    .homepage-panel-us .categories .category-item {
        margin: 0 10px 10px 0
    }
}

@media (min-width:349px) {

    .homepage-panel-generic .categories .category-item,
    .homepage-panel-us .categories .category-item {
        width: 31.5% !important
    }
}

@media (max-width:576px) {

    .homepage-panel-generic .categories .category-item,
    .homepage-panel-us .categories .category-item {
        width: 40% !important
    }
}

@media (max-width:348px) {

    .homepage-panel-generic .categories .category-item,
    .homepage-panel-us .categories .category-item {
        margin: 5px auto;
        text-align: center;
        width: 98.5% !important
    }
}

.homepage-panel-generic .categories .category-item a:hover,
.homepage-panel-us .categories .category-item a:hover {
    text-decoration: none
}

.homepage-panel-generic .categories .category-item img,
.homepage-panel-us .categories .category-item img {
    align-self: center !important;
    height: 40px;
    margin: 14px
}

@media (max-width:991px) {

    .homepage-panel-generic .categories .category-item img,
    .homepage-panel-us .categories .category-item img {
        height: 35px;
        margin: 9px
    }
}

@media (max-width:576px) {

    .homepage-panel-generic .categories .category-item img,
    .homepage-panel-us .categories .category-item img {
        height: 30px;
        margin: 3px
    }
}

.homepage-panel-generic .categories .category-item span,
.homepage-panel-us .categories .category-item span {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    padding-right: 7px
}

@media (max-width:576px) {

    .homepage-panel-generic .categories .category-item span,
    .homepage-panel-us .categories .category-item span {
        font-size: 12.16px;
        line-height: 14.44px
    }
}

@media (max-width:991px) {

    .homepage-panel-generic .categories,
    .homepage-panel-us .categories {
        margin-top: 0;
        padding-top: 10px
    }
}

@media (max-width:576px) {

    .homepage-panel-generic .categories,
    .homepage-panel-us .categories {
        padding-left: 10px !important
    }
}

@media (min-width:1024px) {
    .homepage-panel-generic.de .categories {
        margin-top: 0
    }
}

.trust-badge {
    background-color: #1e84cc;
    border-radius: 6px;
    color: #fff;
    margin: 0 0 30px;
    padding: 16px !important;
    position: relative
}

@media (min-width:768px) {
    .trust-badge {
        background-color: #1e84cc;
        margin-bottom: 30px;
        position: relative
    }
}

.trust-badge a {
    color: #fbb03b !important
}

.trust-badge .trophy-trust img {
    margin: 10px auto 0;
    max-width: 100px
}

@media (min-width:992px) {
    .trust-badge .trophy-trust img {
        margin-top: -15px;
        max-width: 110px
    }
}

@media (max-width:479px) {
    .trust-badge .trophy-trust {
        margin: auto
    }
}

.trust-badge>div {
    padding-left: 0;
    padding-right: 0
}

@media (min-width:768px) {
    .trust-badge .col-md-10 {
        flex: 0 0 85%;
        max-width: 85%
    }

    .trust-badge .col-md-2 {
        flex: 0 0 15%;
        max-width: 15%
    }
}

.featured-list .list-container {
    position: relative;
    text-align: left
}

.featured-list .list-container:not(:first-child) {
    margin-top: 28px
}

.featured-list .grid-title,
.featured-list .grid-title a {
    color: #333;
    font-size: 20px !important;
    font-weight: 600;
    margin-bottom: 1em;
    padding: 0;
    text-decoration: none
}

.featured-list .grid-title a:hover,
.featured-list .grid-title:hover {
    color: #dc3545
}

.featured-list .grid-paragraph {
    font-size: 16px;
    padding: .5em 0
}

.featured-list img {
    display: block;
    margin: 0 auto
}

.featured-list .language-list {
    -webkit-display: flex;
    -ms-display: flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    margin: 20px 0 0;
    text-align: center
}

.featured-list .language-list li {
    list-style: none
}

.featured-list .language-list a {
    color: #999;
    font-size: 14px;
    text-decoration: none
}

@media (min-width:768px) {
    .featured-list .list-container {
        position: relative;
        width: auto
    }

    .featured-list .list-container .grid-title,
    .featured-list .list-container .title {
        font-weight: 600
    }

    .featured-list .list-container .grid-title {
        margin-bottom: .5em;
        padding: 0
    }

    .featured-list .list-container .grid-paragraph {
        padding: 0
    }

    .featured-list .list-container:nth-of-type(2n) .image {
        height: 100%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 20%
    }

    .featured-list .list-container:nth-of-type(2n) .image img {
        left: 50%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .featured-list .list-container:nth-of-type(2n) .body,
    .featured-list .list-container:nth-of-type(2n) .title {
        padding-right: 20%
    }

    .featured-list .list-container:nth-of-type(odd) .image {
        height: 100%;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 20%
    }

    .featured-list .list-container:nth-of-type(odd) .image img {
        left: 50%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .featured-list .list-container:nth-of-type(odd) .body,
    .featured-list .list-container:nth-of-type(odd) .title {
        padding-left: 20%
    }

    .featured-list .body,
    .featured-list .title {
        width: 100%
    }
}

.slick-slide,
.slick-slider {
    position: relative
}

.slick-slide>div {
    height: inherit;
    width: 100%
}

.slick-slide.slick-current.slick-active.slick-center {
    box-shadow: 0 0 20px 1px #b5b5b5;
    display: flex;
    font-size: 15px !important;
    transform: scale(1.1);
    z-index: 1
}

.slick-slide:not(.slick-current):after {
    background: #fff;
    content: "";
    height: 100%;
    left: 0;
    opacity: .7;
    position: absolute;
    top: 0;
    width: 100%
}

.slick-slide .card {
    border-color: #ededed;
    border-radius: 6px
}

.slick-track {
    align-items: center;
    display: flex !important;
    height: 450px;
    padding: 0 0 45px
}

.slick-active:not(.slick-current) .card-body.overflow-auto:after {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
    bottom: 0;
    content: "";
    height: 30%;
    left: 0;
    position: absolute
}

.slick-track .slick-active:not(.slick-current) {
    margin-left: 200px
}

.slick-track .slick-current+div.slick-active {
    margin-left: 0;
    margin-right: 200px
}

.slick-track .card-text,
.slick-track .slick-current .card-text {
    font-size: 1em !important
}

.slider .slick-slide {
    border-radius: 6px;
    font-size: 16px !important;
    height: 320px;
    left: 0;
    overflow: hidden;
    transition: .4s;
    width: 256px
}

@media screen and (max-width:575px) {
    .slider .slick-slide {
        width: 220px
    }
}

.slider .slick-slide:not(.slick-current) {
    box-shadow: 0 0 13px 1px #ececec
}

.ribbon {
    background: #dc3545;
    height: 32px;
    left: -1px
}

.ribbon:before {
    border-bottom-right-radius: 30px;
    border-right: 20px solid #dc3545;
    border-top-right-radius: 30px;
    content: "";
    height: inherit;
    position: absolute;
    right: -19px;
    top: 0
}

.slick-button-nav {
    color: #dc3545;
    font-size: 54px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2
}

.slick-button-nav:hover {
    cursor: pointer
}

.slick-next {
    right: calc(50% - 170px)
}

.slick-prev {
    left: calc(50% - 175px)
}

@media screen and (max-width:575px) {
    .slick-next {
        right: calc(50% - 144px)
    }

    .slick-prev {
        left: calc(50% - 147px)
    }
}

.homepage {
    border-top: 0;
    padding: 2em 1em 0;
    position: relative
}

@media (max-width:767px) {
    .homepage {
        padding-top: 0 !important
    }
}

.homepage .columns-list {
    margin: 0 0 20px
}

.homepage .homepage-cb1 h2 {
    margin: 1.5rem 0 1.38rem
}

.us-state-homepage .slick-slider {
    position: relative
}

.template-homepage-generic #op-list-wrap>div.tac_ad_text {
    text-align: right
}

.template-homepage-generic h2 {
    font-weight: 600
}

.template-homepage-generic h3 {
    font-weight: 400
}

.template-homepage-generic ol {
    margin-top: 15px
}

@media (max-width:767px) {
    .template-homepage-generic ol {
        margin-top: 7px
    }
}

.template-homepage-generic ol li {
    margin-bottom: 15px
}

@media (max-width:767px) {
    .template-homepage-generic ol li {
        margin-bottom: 7px
    }
}

.template-homepage-generic .homepage {
    padding-top: 0
}

.template-homepage-generic .homepage .homepage-cb1 h2 {
    margin: 1.5rem 0 1.38rem
}

.template-homepage-generic .review-online-container {
    background-color: #f8f8f8;
    margin-left: calc(50% - 50vw);
    width: 100vw
}

.template-homepage-generic .review-online-container h3 {
    font-weight: 400
}

.template-homepage-generic .review-online-container .icon {
    min-width: 50px;
    text-align: center
}

.template-homepage-generic .review-online-container .details {
    padding-left: 10px
}

.template-homepage-generic .cc-list-with-thumbnail .image {
    max-height: 155px;
    max-width: 155px
}

.template-homepage-generic .cc-list-with-thumbnail .image img {
    max-height: 111px;
    max-width: 111px
}

.template-homepage-generic .cc-list-with-thumbnail .text {
    margin-left: 40px
}

@media (max-width:767px) {
    .template-homepage-generic .cc-list-with-thumbnail .text {
        margin-left: 10px
    }
}

.template-homepage-generic .cc-list-with-thumbnail .text h2 {
    font-size: 22px;
    font-weight: 400
}

.template-homepage-generic .sign-up-how h3 {
    font-weight: 400
}

.template-homepage-generic .sign-up-how .icon {
    min-width: 50px;
    text-align: center
}

.template-homepage-generic .sign-up-how .details {
    padding-left: 10px
}

.template-homepage-generic .game-demo-container,
.template-homepage-generic .game-demo-container .play-demo {
    border-radius: 10px
}

.promotions-state-select {
    background: #ececec;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px
}

.promotions-state-select select {
    background: #fff;
    color: #777;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    text-align: left;
    vertical-align: top;
    width: 100%
}

.promotions-state-select label {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em;
    margin-bottom: .5rem
}

.promotions-state-select .select2-selection__rendered {
    font-size: 16px;
    line-height: 50px !important;
    text-align: left
}

.promotions-state-select .select-text {
    line-height: 45px
}

.operator_review_hero .box-shadow {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24)
}

.operator_review_hero .select2-selection__rendered {
    font-size: 16px;
    line-height: 48px !important;
    text-align: left
}

.operator_review_hero .select2-selection,
.operator_review_hero .select2-selection__arrow {
    height: 48px !important
}

.operator_review_hero .select2-selection__arrow {
    display: none
}

.operator_review_hero .select2-selection--single {
    border: 0 !important;
    border-radius: 0 !important
}

.operator_review_hero .select-wrap {
    margin: 0 auto;
    max-width: 367px;
    width: 100%;
    z-index: 999
}

.operator_review_hero .select-wrap select {
    -webkit-appearance: none;
    background: #fff;
    border: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #777;
    float: left;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    max-width: 325px;
    padding-left: 8px;
    vertical-align: top;
    width: 100%
}

.operator_review_hero .select-wrap .box-shadow {
    position: relative
}

.operator_review_hero .select-wrap .box-shadow .map-marker {
    content: "";
    position: absolute;
    right: 4px;
    top: 0
}

@media screen and (max-width:320px) {
    .operator_review_hero .select-wrap .select2-container {
        max-width: 231px
    }
}

.operator_review_hero .select-wrap .map-marker {
    background: #fff;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: #333;
    float: right;
    height: 48px;
    padding: 12px 14px;
    width: 42px
}

.operator_review_hero .select-wrap .map-marker.loading {
    padding: 12px 10px
}

.operator_review_hero .select-wrap .map-marker.loading i {
    font-size: 24px
}

.operator_review_hero .select-wrap p.select {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase
}

.brand-product-review-sidebar .heading {
    background: #333;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 5px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-top: 15px
}

.brand-product-review-sidebar .content {
    border-radius: 4px
}

.brand-product-review-sidebar .content .row {
    background-color: #ececec;
    border-radius: 4px;
    margin: 0 0 5px;
    padding: 8px 15px
}

.brand-product-review-sidebar .content .row div {
    padding-left: 0;
    padding-right: 0
}

.brand-product-review-sidebar .content .row-cols-2 {
    background-color: unset;
    padding: 0
}

.brand-product-review-sidebar .content .row-cols-2 div {
    background-color: #ececec;
    margin-bottom: 4px
}

.brand-product-review-sidebar .content .row-cols-2 div:nth-child(odd) {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.brand-product-review-sidebar .content .row-cols-2 div:nth-child(2n) {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

.brand-product-review-sidebar .content p {
    line-height: 24px;
    margin: 0
}

.brand-product-review-sidebar .content p.email {
    text-decoration: underline;
    word-break: break-all
}

.brand-product-review-sidebar .other-info .row-cols-2 {
    background-color: #ececec;
    border-radius: 4px;
    margin: 0 0 5px;
    padding: 8px 15px
}

.brand-product-review-sidebar .other-info .row-cols-2 div {
    overflow-wrap: anywhere
}

.brand-product-review-sidebar .supported-platforms .device-icon {
    font-size: 24px
}

.brand-product-review-sidebar .software-providers .content {
    text-transform: uppercase
}

.brand-product-review-sidebar .software-providers .content img.brand-image {
    max-height: 45px;
    width: auto
}

.brand-product-review-sidebar .software-providers .content .row-cols-2 {
    background-color: #ececec;
    border-radius: 4px;
    margin: 0 0 5px;
    padding: 8px 15px
}

.brand-product-review-sidebar .info-blocks .no-link {
    color: #dc3545
}

.brand-product-review-sidebar .info-blocks .colour-block {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    font-weight: 700;
    line-height: 24px;
    text-align: center
}

.brand-product-review-sidebar .info-blocks p a {
    color: #dc3545
}

.brand-product-review-sidebar section.other_recommended_products {
    border: none;
    margin-top: 0
}

.brand-product-review-sidebar section.other_recommended_products h2 {
    letter-spacing: 0
}

.reviewer {
    border: 1px solid hsla(0, 0%, 59%, .25);
    border-radius: 4px
}

.reviewer .profile-image {
    display: inline-block
}

.reviewer .profile-image img {
    border-radius: 50%;
    height: 64px;
    width: 64px
}

.reviewer .reviewed-by p {
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 5px
}

.reviewer .interests {
    font-size: 12px;
    font-style: italic
}

.reviewer .published,
.reviewer .updated {
    color: #979797;
    font-size: 12px;
    line-height: 17px
}

.reviewer .published .date,
.reviewer .published .read-time,
.reviewer .updated .date,
.reviewer .updated .read-time {
    font-weight: 700
}

.reviewer .share-text {
    color: #067bc2;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 17px
}

.reviewer .author-share-link {
    display: inline-block;
    font-size: 10px;
    left: 15px;
    position: absolute;
    top: 25px
}

.reviewer .copy-url,
.reviewer .social-share-link {
    cursor: pointer
}

.customer-support-table table {
    border: 1px solid #ccc;
    border-bottom: none
}

.customer-support-table .social {
    text-decoration: none !important
}

.bottom-offers-list section.other_recommended_products {
    border: none;
    margin-top: 0;
    padding: 0
}

.bottom-offers-list h2 {
    padding: 0 !important
}

.template-product-review .alert-warning {
    font-size: 12px
}

.template-product-review .body_content table {
    width: 100%
}

.template-product-review .body_content .review p {
    color: #333;
    letter-spacing: 0;
    margin-bottom: 1rem
}

.template-product-review .body_content .review .product-coverage-banner {
    background-color: #ddd;
    border-radius: 4px
}

.template-product-review .body_content .review .product-coverage-banner h2 {
    font-size: 16px;
    line-height: 45px;
    margin-bottom: 0
}

.template-product-review .body_content .review .product-coverage-banner h2.geo {
    font-weight: 400
}

.template-product-review .body_content .review .table-bordered {
    border: 1px solid #979797
}

.template-product-review .body_content .review .table-responsive {
    border: none
}

.template-product-review .body_content .review .table-responsive .table,
.template-product-review .body_content .review .table-responsive table {
    table-layout: fixed
}

.template-product-review .body_content .review .table-responsive .table tbody tr td,
.template-product-review .body_content .review .table-responsive table tbody tr td {
    overflow-wrap: break-word;
    text-align: left;
    white-space: normal
}

.template-product-review .body_content .review .table-responsive .table tbody tr td a,
.template-product-review .body_content .review .table-responsive table tbody tr td a {
    white-space: normal
}

.template-product-review .body_content .review .cms-table,
.template-product-review .body_content .review .table-bordered {
    border: none;
    border-collapse: separate;
    border-spacing: 5px;
    table-layout: fixed
}

.template-product-review .body_content .review .cms-table thead th,
.template-product-review .body_content .review .table-bordered thead th {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    overflow-wrap: break-word;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-top: 15px;
    text-align: left;
    text-transform: none;
    white-space: normal
}

.template-product-review .body_content .review .cms-table tbody tr td,
.template-product-review .body_content .review .table-bordered tbody tr td {
    background-color: #ececec;
    border-radius: 4px;
    overflow-wrap: break-word;
    padding: 8px 15px !important;
    text-align: left;
    white-space: normal
}

.template-product-review .body_content .review .cms-table tbody tr td:first-child,
.template-product-review .body_content .review .table-bordered tbody tr td:first-child {
    background-color: #e2e1e1
}

.template-product-review section.payment-methods-table {
    border: none
}

.template-product-review section.payment-methods-table .heading-row {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    flex-wrap: nowrap;
    margin-bottom: 5px
}

.template-product-review section.payment-methods-table .heading-row .col {
    align-items: center;
    flex: unset;
    flex-basis: unset;
    flex-grow: unset;
    padding: 8px 15px
}

.template-product-review section.payment-methods-table .heading-row .col div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 !important
}

.template-product-review section.payment-methods-table .data-row {
    background-color: #ececec;
    border: none;
    border-radius: 4px;
    flex-wrap: nowrap;
    margin: 0 0 5px;
    padding: 0 !important
}

.template-product-review section.payment-methods-table .data-row .col {
    align-items: center;
    flex: unset;
    flex-basis: unset;
    flex-grow: unset;
    padding: 8px 15px
}

.template-product-review section.payment-methods-table .data-row .col div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0
}

.template-product-review section.promotions_table .promo_table_row {
    background: 0 0;
    background-color: #ececec;
    border: none;
    border-radius: 4px;
    margin-bottom: 5px
}

.template-product-review section.promotions_table .promo_table_row:first-child {
    background-color: #333
}

.template-product-review section.promotions_table .promo_table_row:first-child p {
    color: #fff
}

.template-product-review section.promotions_table .promo_table_row .promo_table_col {
    border: none
}

.template-product-review section.promotions_table .promo_table_row .promo_table_terms {
    border: none;
    border-top: 1px solid #d8d8d8;
    padding-right: 0
}

.template-product-review section.promotions_table .promo_table_row .row.col-12 {
    padding-right: 0
}

.template-product-review div[id*=brand-payment-table-] .promotions_table .row {
    background: 0 0;
    background-color: #ececec;
    border: none;
    border-radius: 4px;
    margin-bottom: 5px
}

.template-product-review div[id*=brand-payment-table-] .promotions_table .row:first-child {
    background: 0 0;
    background-color: #333;
    color: #fff
}

.template-product-review div[id*=brand-payment-table-] .promotions_table .row:first-child p {
    color: #fff
}

.template-product-review div[id*=brand-payment-table-] .promotions_table .row .promo_table_col {
    border: none
}

.template-product-review div[id*=brand-offer-table-] .promo_table_row {
    background: 0 0;
    background-color: #ececec;
    border: none;
    border-radius: 4px;
    margin-bottom: 5px
}

.template-product-review div[id*=brand-offer-table-] .promo_table_row:first-child {
    background-color: #333
}

.template-product-review div[id*=brand-offer-table-] .promo_table_row:first-child p {
    color: #fff
}

.emoji {
    font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol
}

#brand_product_review .oplitstTooltip,
#brand_product_review .oplitstTooltipCa,
#brand_product_review .state-offer-alert {
    border-radius: 14px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 0;
    padding: 2px 15px 2px 4px;
    text-transform: none
}

#brand_product_review .oplitstTooltipCa,
#brand_product_review .state-offer-alert {
    margin-bottom: 10px;
    width: 100%
}

#brand_product_review .oplitstTooltipCa.not-backup-operator,
#brand_product_review .state-offer-alert.not-backup-operator {
    background-color: #d4edda;
    border: 1px solid #155724;
    color: #155724
}

#brand_product_review .oplitstTooltipCa.not-backup-operator .tooltipInfo,
#brand_product_review .state-offer-alert.not-backup-operator .tooltipInfo {
    fill: #155724
}

#brand_product_review .oplitstTooltipCa.backup-operator,
#brand_product_review .state-offer-alert.backup-operator {
    background-color: #faf2cc;
    border: 1px solid #8a6d3b;
    color: #8a6d3b
}

#brand_product_review .oplitstTooltipCa.backup-operator .tooltipInfo,
#brand_product_review .state-offer-alert.backup-operator .tooltipInfo {
    fill: #8a6d3b
}

table {
    background-color: transparent
}

th {
    text-align: center
}

.table.table-bordered a,
.table.table-content a {
    color: #333;
    text-decoration: underline
}

.table.table-bordered a:hover,
.table.table-content a:hover {
    color: #333;
    text-decoration: none
}

.table.table-bordered a.review-link,
.table.table-content a.review-link {
    color: #dc3545;
    font-weight: 700;
    text-align: left;
    text-decoration: underline
}

.table.table-bordered td a,
.table.table-content td a {
    white-space: nowrap
}

.table-responsive {
    margin: 0 0 10px
}

.table-responsive .table-bordered {
    margin: 0
}

@media screen and (max-width:767px) {
    .table-responsive {
        border: 1px solid #dee2e6
    }

    .table-responsive .table tbody tr td,
    .table-responsive .table tbody tr th,
    .table-responsive .table tfoot tr td,
    .table-responsive .table tfoot tr th,
    .table-responsive .table thead tr td,
    .table-responsive .table thead tr th {
        white-space: nowrap
    }
}

.table .thead-light th,
.table-bordered>tbody>tr>th,
.table-content th,
.table-striped>tbody>tr>th,
.table>thead>tr>th {
    background-color: #ecedef;
    border: solid #ddd;
    border-width: 0 0 1px;
    color: #333;
    font-size: 12px;
    letter-spacing: .8px;
    line-height: 26px;
    padding: 8px 20px !important;
    text-align: left;
    text-transform: uppercase;
    vertical-align: top !important
}

@media (max-width:767px) {

    .table .thead-light th,
    .table-bordered>tbody>tr>th,
    .table-content th,
    .table-striped>tbody>tr>th,
    .table>thead>tr>th {
        padding: 8px 2.5%
    }
}

.table>tbody>tr>th {
    border-top: 0 !important
}

.table-bordered>tbody>tr>td,
.table-content td,
.table>tbody>tr>td {
    border: solid #ddd;
    border-width: 0 0 1px;
    color: #333;
    padding: 8px 20px !important;
    text-align: left
}

@media (max-width:767px) {

    .table-bordered>tbody>tr>td,
    .table-content td,
    .table>tbody>tr>td {
        padding: 8px 2.5%
    }
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9 !important
}

.template-product-review table td {
    border: 1px solid #ddd;
    padding: 8px 20px !important;
    text-align: center
}

.template-product-review table .icon-check {
    color: #8cd221
}

.template-product-review table .icon-cross {
    color: #dc3545
}

.cms-table a {
    color: #000;
    text-decoration: underline
}

.casino-game-review {
    margin-bottom: 0 !important
}

.casino-game-review .td-bg-light {
    background-color: #ecedef
}

.rotate-0 {
    transform: rotate(0) !important
}

.accordion {
    margin-bottom: 20px
}

.accordion .card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: none;
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    min-width: 0;
    position: relative
}

.accordion .card .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 0;
    padding: .75rem .25rem
}

.accordion .card .card-header img,
.accordion .card .card-header svg {
    transform: rotate(180deg);
    transition: transform .5s ease
}

.accordion .card .card-header:not(.collapsed) img,
.accordion .card .card-header:not(.collapsed) svg {
    transform: rotate(0)
}

.accordion .card .card-body {
    flex: 1 1 auto;
    font-weight: 400;
    padding: 1.25rem
}

.accordion .justify-content-between {
    justify-content: space-between !important
}

.accordion .panel-title {
    cursor: pointer;
    font-weight: 600
}

#headingOne {
    background-color: transparent
}

.email-capture,
.email-capture-footer {
    background: #ecedef
}

.subscription-container form {
    margin: 0
}

.subscription-container label {
    display: inline;
    display: inherit;
    margin: 0
}

.subscription-container .subscription-text {
    color: #333 !important;
    font-size: 14px;
    font-style: italic;
    line-height: 22px
}

.subscription-container .subscription-response {
    text-align: center
}

.subscription-container .subscription-response span {
    color: #dc3545
}

.subscription-container .article-main-content .subscription-image-container {
    display: none
}

.subscription-container input.email {
    border: 1px solid #000;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    height: 54px;
    line-height: 16px;
    outline: 0;
    text-indent: 10px
}

.subscription-container input.email:-ms-input-placeholder {
    color: #757575;
    font-weight: lighter
}

.subscription-container input.email::-moz-placeholder {
    color: #757575 !important;
    font-weight: lighter
}

.subscription-container input.email::placeholder {
    color: #757575 !important;
    font-weight: lighter
}

.subscription-container input.faked {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    z-index: -10
}

.subscription-container .fake-checkbox-container {
    height: 16px;
    width: 16px
}

.subscription-container .fake-checkbox-container .fake-checkbox {
    background: #fff;
    border: 1px solid #000;
    height: inherit;
    width: inherit
}

.subscription-container .fake-checkbox-container .fake-checkbox:before {
    content: ""
}

.subscription-container .fake-checkbox-container .fake-checkbox i {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    width: 100%
}

.subscription-container .agree-message {
    color: #333 !important;
    font-size: 14px;
    line-height: 16px
}

.subscription-container p {
    color: #757575
}

@media (min-width:992px) {
    .email-capture-component .subscription-image-container {
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .email-capture-component .subscription-text-container {
        flex: 0 0 50%;
        max-width: 50%
    }
}

.email-capture-component .subscription-image-container,
.email-capture-component .subscription-text-container {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%
}

.article-main-content .email-capture-component .subscription-image-container {
    display: none
}

.article-main-content .email-capture-component .subscription-content {
    text-align: center
}

.article-main-content .email-capture-component .submit-button {
    justify-content: center !important
}

@media (min-width:992px) {
    .article-main-content .email-capture-component .subscription-text-container {
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
}

.signup-container .signup-text-container {
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border: 1px solid #d23b42;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    left: 31px;
    overflow-wrap: anywhere;
    padding-right: 30px
}

.signup-container .signup-image-container {
    position: relative
}

.signup-container .signup-image-container .image-text {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%
}

.signup-container .signup-image-container .image-text .image-text-title {
    color: #d23b42;
    font-size: 33px;
    font-weight: 700;
    line-height: 20px
}

.signup-container .signup-image-container .image-text .image-text-subtitle {
    color: #fff;
    font-size: 23px
}

.signup-container label {
    display: inline;
    display: inherit;
    margin: 0
}

.signup-container button[type=submit] {
    height: 48px;
    line-height: 43px
}

.signup-container .signup-text-1 {
    color: #d23b42;
    font-size: 30px;
    line-height: 43px;
    text-transform: uppercase
}

.signup-container .signup-text-2 {
    color: #000;
    font-size: 24px;
    line-height: 28px;
    word-break: break-word
}

.signup-container .signup-text-gambling {
    color: #d23b42;
    font-size: 26px
}

.signup-container .signup-response {
    text-align: center
}

.signup-container .signup-response span {
    color: #dc3545
}

.signup-container input.email {
    border: 1px solid #d23b42;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    height: 48px;
    line-height: 16px;
    outline: 0;
    text-indent: 10px
}

.signup-container input.email:-ms-input-placeholder {
    color: #333;
    font-weight: lighter
}

.signup-container input.email::-moz-placeholder {
    color: #333;
    font-weight: lighter
}

.signup-container input.email::placeholder {
    color: #333;
    font-weight: lighter
}

.signup-container input.faked {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    z-index: -10
}

.signup-container .fake-checkbox-container {
    height: 22px;
    width: 22px
}

.signup-container .fake-checkbox-container .fake-checkbox {
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    height: inherit;
    width: inherit
}

.signup-container .fake-checkbox-container .fake-checkbox:before {
    content: ""
}

.signup-container .fake-checkbox-container .fake-checkbox i {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    width: 100%
}

.signup-container p {
    color: #757575
}

.signup-container .component-sign-up-form .fake-checkbox-container {
    margin-top: 10px
}

.signup-container .component-sign-up-form .fake-checkbox-container .fake-checkbox {
    border: 1px solid #d23b42
}

.signup-container .component-sign-up-form .agree-message {
    color: #757575 !important;
    font-size: 12px;
    line-height: 20px
}

.signup-container .component-sign-up-form .label-email-address {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase
}

@media (max-width:479px) {

    .signup-container .signup-text-container .signup-content,
    .signup-container .signup-text-container .signup-response {
        padding: .5rem !important
    }

    .signup-container .signup-text-container .signup-content .signup-text-1 {
        font-size: 20px;
        line-height: 29px
    }

    .signup-container .signup-text-container .signup-content .signup-text-2 {
        font-size: 20px
    }
}

@media (max-width:991px) {
    .signup-container .signup-text-container {
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomleft: 0;
        border-bottom: 0;
        -webkit-border-bottom-left-radius: 0;
        border-bottom-left-radius: 0;
        -webkit-border-top-left-radius: 5px;
        border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        border-top-right-radius: 5px;
        left: 0;
        padding: 0
    }

    .signup-container .signup-image-container {
        padding: 0
    }

    .signup-container .signup-image-container img {
        width: 100%
    }

    .signup-container .component-sign-up-form .button-blue {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 10px
    }
}

#authors-list .author a {
    color: #333;
    text-decoration: none
}

#authors-list .author .author-image {
    border-radius: 50%;
    height: 50px;
    width: 50px
}

@media (min-width:768px) {
    #authors-list .author .author-image {
        height: 100px;
        width: 100px
    }
}

#authors-list .author .author-image:hover {
    box-shadow: 0 0 20px 1px #b5b5b5;
    transition: .4s
}

#authors-list .author .author-name {
    font-weight: 600
}

#author .twitter-link {
    color: #333;
    font-size: 12px;
    text-decoration: none
}

#author svg.icon-twitter {
    fill: #fff;
    background: #000;
    border-radius: 50%;
    height: 20px;
    padding: 3px;
    width: 20px
}

.article-author-section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0
}

.article-author-section .author-left-container {
    order: 1;
    width: 80%
}

@media (max-width:767px) {
    .article-author-section .author-left-container {
        order: 2;
        width: 100%
    }
}

.article-author-section .author-img-container {
    display: inline-block;
    margin-right: 15px
}

.article-author-section .author-img-container img {
    height: auto;
    width: 50px
}

.article-author-section .author-text-container {
    display: inline-block;
    vertical-align: middle
}

.article-author-section .author-text-container .author-name {
    font-size: 12px
}

.article-author-section .author-text-container .author-name a {
    color: #333;
    font-weight: 700
}

.article-author-section .author-text-container .author-name a:hover {
    text-decoration: underline
}

.article-author-section .author-text-container .author-date {
    color: #999;
    font-size: 12px
}

.article-author-section .social-share-buttons {
    display: inline-block;
    order: 2;
    position: relative;
    vertical-align: middle
}

@media (max-width:767px) {
    .article-author-section .social-share-buttons {
        border-bottom: 1px solid #999;
        margin-bottom: 10px;
        order: 1;
        padding: 10px 0;
        width: 100%
    }
}

.article-author-section .social-share-buttons .author-share-link {
    left: 110px;
    min-width: 120px;
    position: absolute;
    top: 3px
}

@media (max-width:767px) {
    .article-author-section .social-share-buttons .author-share-link {
        left: 140px;
        top: 17px
    }
}

.article-author-section .social-share-buttons ul {
    margin: 0;
    padding: 0
}

.article-author-section .social-share-buttons ul li {
    display: inline-block;
    height: 23px;
    margin-left: 15px;
    width: 23px
}

@media (max-width:767px) {
    .article-author-section .social-share-buttons ul li {
        height: 30px;
        width: 30px
    }
}

.article-author-section .social-share-buttons ul li:first-child {
    margin-left: 0
}

.article-author-section .social-share-buttons ul li button {
    background: 0 0;
    border: none;
    height: 100%;
    padding: 0;
    width: 100%
}

.article-author-section .social-share-buttons ul li button:focus {
    outline: 0
}

.article-author-section .social-share-buttons ul li button .link-circ {
    background-color: #d1001d;
    border-radius: 200px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .5);
    height: 15px;
    left: 9px;
    position: absolute;
    top: 12px;
    width: 15px
}

@media (max-width:767px) {
    .article-author-section .social-share-buttons ul li button .link-circ {
        height: 20px;
        left: 12px;
        top: 25px;
        width: 20px
    }
}

.article-author-section .social-share-buttons ul li svg {
    fill: #fff;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    height: 100%;
    width: 100%
}

.article-author-section .social-share-buttons ul li svg:hover {
    background: #dc3545
}

.article-author-section .social-share-buttons ul li svg.icon-facebook:hover {
    background: #3b5998
}

.article-author-section .social-share-buttons ul li svg.icon-twitter:hover {
    background: #00aced
}

.article-author-section .social-share-buttons ul li svg.icon-copylink {
    fill: #000;
    background: 0 0
}

.article-author-section .social-share-buttons ul li svg.icon-copylink:hover {
    fill: #dc3545
}

.article-author-section .social-share-buttons ul li a>svg {
    padding: 3px
}

.widget_column {
    padding-right: 0
}

@media (max-width:767px) {
    .widget_column {
        padding-left: 0
    }
}

.widget_column .container-fluid {
    padding-left: 8px;
    padding-right: 8px
}

.widget_column .company_info {
    margin-bottom: 1em;
    padding-bottom: 1em
}

.widget_column .company_info h3 {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 5px;
    padding: 8px 0 8px 12px
}

.widget_column .company_info h4 {
    padding-left: .5em
}

@media (min-width:768px) and (max-width:991px) {
    .widget_column .company_info {
        font-size: .75em
    }
}

.widget_column .company_info .company_info_inner .company_info_row {
    word-wrap: break-word;
    background-color: #fff
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col {
    background-color: #ececec;
    margin-bottom: 5px;
    padding-left: 0
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col:nth-child(odd) {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-top: 8px
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col:nth-child(2n) {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-top: 8px
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col .play_now_btn {
    display: inline-block;
    font-size: 13px;
    margin: .5em auto
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col .play_now_btn span {
    font-size: 1.2em;
    margin-left: 0
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col a.btn {
    margin-bottom: 1em
}

.widget_column .company_info .company_info_inner .company_info_row .company_info_col p {
    margin: 0
}

.widget_column .company_info .company_info_inner .company_info_row:last-child .company_info_col {
    background-color: #fff
}

.widget_column .company_info .company_info_inner .company_info_row:last-child .company_info_col:nth-child(odd) {
    padding: 0
}

.widget_column section.other_products_table {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 0 .5em
}

.widget_column section.other_products_table>.related_products_row {
    font-size: .7em;
    font-weight: 600;
    padding: .5em 0
}

.widget_column section.other_products_table>.related_products_row:first-child {
    padding: 0 .5em
}

.widget_column section.other_products_table>.related_products_row .related_products_non_english a {
    color: #1c1c1c;
    font-weight: 600
}

.widget_column section.other_products_table>.related_products_row .related_products_non_english a:after {
    color: #dc3545;
    font-weight: 600;
    margin-left: .2em;
    width: 2em
}

.widget_column section.other_products_table>.related_products_row>.related_products_english a {
    color: #1c1c1c
}

.widget_column section.other_products_table>.related_products_row>.related_products_english a:focus,
.widget_column section.other_products_table>.related_products_row>.related_products_english a:hover {
    color: #dc3545;
    text-decoration: none
}

.widget_column section.other_products_table>.related_products_row>.related_products_english>.related_products_icon {
    padding: 0
}

.widget_column section.other_products_table>.related_products_row>.related_products_english>.related_products_icon i {
    font-size: 2em
}

.widget_column section.other_products_table>.related_products_row:nth-child(2n) {
    background-color: #f5f5f5
}

.widget_column section.other_products_table .related_products_english .icon_disabled,
.widget_column section.other_products_table .related_products_non_english .icon_disabled {
    color: #c8c8c8 !important
}

.widget_column section.other_products_table .related_products_english .related_products_icon,
.widget_column section.other_products_table .related_products_non_english .related_products_icon {
    font-size: 2em;
    padding: 0
}

.widget_column section.other_products_table .related_products_english .related_products_icon .platform_icon,
.widget_column section.other_products_table .related_products_non_english .related_products_icon .platform_icon {
    display: table;
    height: 1.4em;
    margin: 0 auto
}

.widget_column section.other_products_table .related_products_english .related_products_icon .platform_icon i,
.widget_column section.other_products_table .related_products_english .related_products_icon .platform_icon span,
.widget_column section.other_products_table .related_products_non_english .related_products_icon .platform_icon i,
.widget_column section.other_products_table .related_products_non_english .related_products_icon .platform_icon span {
    display: table-cell;
    float: none;
    vertical-align: middle
}

.widget_column section.other_products_table .related_products_english .related_product_type,
.widget_column section.other_products_table .related_products_non_english .related_product_type {
    height: 3em
}

.widget_column section.other_products_table .related_products_english .related_product_type .platform_text,
.widget_column section.other_products_table .related_products_non_english .related_product_type .platform_text {
    display: table;
    height: 100%
}

.widget_column section.other_products_table .related_products_english .related_product_type .platform_text p,
.widget_column section.other_products_table .related_products_non_english .related_product_type .platform_text p {
    display: table-cell;
    float: none;
    font-size: 14px;
    vertical-align: middle
}

.widget_column section.other_products_table .related_products_english {
    line-height: 1em
}

.widget_column section.other_products_table .related_products_english .related_products_icon {
    text-align: center
}

.widget_column section.other_products_table .related_products_english .related_product_type {
    height: 2.25em
}

.widget_column section.dual_side_news_table,
.widget_column section.side_navigation_table,
.widget_column section.side_news_table,
.widget_column section.supported_platforms_table {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 0 5px
}

.widget_column section.dual_side_news_table>.right_col_row,
.widget_column section.side_navigation_table>.right_col_row,
.widget_column section.side_news_table>.right_col_row,
.widget_column section.supported_platforms_table>.right_col_row {
    font-weight: 600;
    padding: 5px 0
}

.widget_column section.dual_side_news_table>.right_col_row:first-child,
.widget_column section.side_navigation_table>.right_col_row:first-child,
.widget_column section.side_news_table>.right_col_row:first-child,
.widget_column section.supported_platforms_table>.right_col_row:first-child {
    padding: 0 5px
}

.widget_column section.dual_side_news_table>.right_col_row .right_col_sidebar_icons:first-child,
.widget_column section.side_navigation_table>.right_col_row .right_col_sidebar_icons:first-child,
.widget_column section.side_news_table>.right_col_row .right_col_sidebar_icons:first-child,
.widget_column section.supported_platforms_table>.right_col_row .right_col_sidebar_icons:first-child {
    padding-right: 0
}

.widget_column section.dual_side_news_table>.right_col_row .right_col_sidebar_icons:last-child a,
.widget_column section.side_navigation_table>.right_col_row .right_col_sidebar_icons:last-child a,
.widget_column section.side_news_table>.right_col_row .right_col_sidebar_icons:last-child a,
.widget_column section.supported_platforms_table>.right_col_row .right_col_sidebar_icons:last-child a {
    color: #1c1c1c;
    font-weight: 600
}

.widget_column section.dual_side_news_table>.right_col_row .right_col_sidebar_icons:last-child a:after,
.widget_column section.side_navigation_table>.right_col_row .right_col_sidebar_icons:last-child a:after,
.widget_column section.side_news_table>.right_col_row .right_col_sidebar_icons:last-child a:after,
.widget_column section.supported_platforms_table>.right_col_row .right_col_sidebar_icons:last-child a:after {
    color: #dc3545;
    content: ">>";
    font-weight: 600;
    margin-left: .2em;
    width: 2em
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links .right_col_row,
.widget_column section.side_navigation_table>.right_col_row>.news_links .right_col_row,
.widget_column section.side_news_table>.right_col_row>.news_links .right_col_row,
.widget_column section.supported_platforms_table>.right_col_row>.news_links .right_col_row {
    padding: .25em 0
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links .right_col_row img,
.widget_column section.side_navigation_table>.right_col_row>.news_links .right_col_row img,
.widget_column section.side_news_table>.right_col_row>.news_links .right_col_row img,
.widget_column section.supported_platforms_table>.right_col_row>.news_links .right_col_row img {
    display: block;
    height: auto;
    width: 100%
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links .right_col_row a,
.widget_column section.side_navigation_table>.right_col_row>.news_links .right_col_row a,
.widget_column section.side_news_table>.right_col_row>.news_links .right_col_row a,
.widget_column section.supported_platforms_table>.right_col_row>.news_links .right_col_row a {
    color: #1c1c1c;
    font-size: 1.2em;
    font-weight: 600
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links .right_col_row a:hover,
.widget_column section.side_navigation_table>.right_col_row>.news_links .right_col_row a:hover,
.widget_column section.side_news_table>.right_col_row>.news_links .right_col_row a:hover,
.widget_column section.supported_platforms_table>.right_col_row>.news_links .right_col_row a:hover {
    text-decoration: none
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links .right_col_row a:after,
.widget_column section.side_navigation_table>.right_col_row>.news_links .right_col_row a:after,
.widget_column section.side_news_table>.right_col_row>.news_links .right_col_row a:after,
.widget_column section.supported_platforms_table>.right_col_row>.news_links .right_col_row a:after {
    color: #dc3545;
    content: ">>";
    font-weight: 600;
    margin-left: .2em;
    width: 2em
}

.widget_column section.dual_side_news_table>.right_col_row>.news_links hr,
.widget_column section.side_navigation_table>.right_col_row>.news_links hr,
.widget_column section.side_news_table>.right_col_row>.news_links hr,
.widget_column section.supported_platforms_table>.right_col_row>.news_links hr {
    border-color: #999;
    margin: .75em 0
}

.widget_column section.dual_side_news_table>.right_col_row:nth-child(odd),
.widget_column section.side_navigation_table>.right_col_row:nth-child(odd),
.widget_column section.side_news_table>.right_col_row:nth-child(odd),
.widget_column section.supported_platforms_table>.right_col_row:nth-child(odd) {
    background-color: #d8d8d8
}

.widget_column section.dual_side_news_table>.right_col_row:nth-child(2n),
.widget_column section.side_navigation_table>.right_col_row:nth-child(2n),
.widget_column section.side_news_table>.right_col_row:nth-child(2n),
.widget_column section.supported_platforms_table>.right_col_row:nth-child(2n) {
    background-color: #f5f5f5
}

.widget_column section.dual_side_news_table>.right_col_row:first-child,
.widget_column section.side_navigation_table>.right_col_row:first-child,
.widget_column section.side_news_table>.right_col_row:first-child,
.widget_column section.supported_platforms_table>.right_col_row:first-child {
    background-color: #fff
}

.widget_column section.dual_side_news_table .sidebar_Icons,
.widget_column section.side_navigation_table .sidebar_Icons,
.widget_column section.side_news_table .sidebar_Icons,
.widget_column section.supported_platforms_table .sidebar_Icons {
    font-size: .7em
}

.widget_column section.dual_side_news_table .sidebar_Icons .icon_disabled,
.widget_column section.side_navigation_table .sidebar_Icons .icon_disabled,
.widget_column section.side_news_table .sidebar_Icons .icon_disabled,
.widget_column section.supported_platforms_table .sidebar_Icons .icon_disabled {
    color: #c8c8c8 !important
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_image,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_image,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_image,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_image {
    font-size: 2em;
    padding: 0
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_image .platform_icon,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_image .platform_icon {
    display: table;
    height: 1.4em;
    margin: 0 auto
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon i,
.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon span,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_image .platform_icon i,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_image .platform_icon span,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon i,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_image .platform_icon span,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_image .platform_icon i,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_image .platform_icon span {
    display: table-cell;
    float: none;
    vertical-align: middle
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_text,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_text,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_text,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_text {
    height: 3em
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_text .platform_text,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_text .platform_text {
    display: table;
    height: 100%
}

.widget_column section.dual_side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text p,
.widget_column section.side_navigation_table .sidebar_Icons .sidebar_Icons_text .platform_text p,
.widget_column section.side_news_table .sidebar_Icons .sidebar_Icons_text .platform_text p,
.widget_column section.supported_platforms_table .sidebar_Icons .sidebar_Icons_text .platform_text p {
    display: table-cell;
    float: none;
    font-size: 1.2em;
    vertical-align: middle
}

.widget_column section.side_navigation_table>.right_col_row a {
    color: #1c1c1c
}

.widget_column section.side_navigation_table>.right_col_row a:focus,
.widget_column section.side_navigation_table>.right_col_row a:hover {
    color: #dc3545
}

.widget_column section.side_navigation_table>.right_col_row a .side_nav_icon_col .platform_icon .icon-slots {
    font-size: .6em
}

.widget_column section.side_navigation_table>.right_col_row:last-child a {
    color: #1c1c1c;
    font-size: .85em;
    font-weight: 500
}

.widget_column section.side_navigation_table>.right_col_row:last-child a:focus,
.widget_column section.side_navigation_table>.right_col_row:last-child a:hover {
    color: #dc3545
}

.widget_column section.side_navigation_table>.right_col_row:last-child a .platform_text p {
    font-size: 14px;
    line-height: 20px;
    margin: 4px
}

.widget_column section.side_navigation_table>.right_col_row:last-child a .platform_text p:after {
    color: #dc3545;
    content: ">>";
    margin-left: .2em;
    width: 2em
}

.widget_column section.side_navigation_table>.right_col_row .side_nav_section_col {
    font-size: .9em;
    height: 4em;
    line-height: 1.2em
}

.widget_column section.side_navigation_table>.right_col_row .side_nav_section_col:first-child,
.widget_column section.side_navigation_table>.right_col_row .side_nav_section_col:nth-child(2) {
    margin-top: .5em
}

.widget_column section.focus_table {
    background-color: #fff;
    border: 1px solid #d8d8d8;
    padding: .5em
}

.widget_column section.focus_table .focus_table_row:nth-child(odd) {
    background-color: #d8d8d8
}

.widget_column section.focus_table .focus_table_row:nth-child(2n) {
    background-color: #f5f5f5
}

.widget_column section.focus_table .focus_table_row:first-child {
    background-color: #fff
}

.widget_column section.focus_table .focus_table_row:last-child {
    background-color: #fff;
    padding: .5em 0;
    text-align: right
}

.widget_column section.focus_table .focus_table_row:last-child a {
    color: #1c1c1c;
    font-size: .9em;
    font-weight: 700
}

.widget_column section.focus_table .focus_table_row:last-child a:focus,
.widget_column section.focus_table .focus_table_row:last-child a:hover {
    color: #dc3545;
    text-decoration: none
}

.widget_column section.focus_table .focus_table_row:last-child a:after {
    color: #dc3545;
    content: ">>";
    margin-left: .2em;
    width: 2em
}

.widget_column section.focus_table .focus_table_row .focus_table_col {
    display: table;
    height: 4em;
    min-height: 4em
}

@media (min-width:768px) and (max-width:991px) {
    .widget_column section.focus_table .focus_table_row .focus_table_col {
        padding: .25em
    }
}

.widget_column section.focus_table .focus_table_row .focus_table_col .focus_inner {
    display: table-cell;
    float: none;
    text-align: center;
    vertical-align: middle
}

.widget_column section.focus_table .focus_table_row .focus_table_col .focus_inner a p {
    color: #000;
    font-size: 1.15em;
    font-weight: 600;
    line-height: 1em;
    margin: 0
}

.widget_column section.focus_table .focus_table_row .focus_table_col .focus_inner a p em {
    color: #dc3545;
    font-style: normal;
    font-weight: 700
}

@media (min-width:768px) and (max-width:991px) {
    .widget_column section.focus_table .focus_table_row .focus_table_col .focus_inner a p {
        font-size: .8em
    }
}

.widget_column section.focus_table .focus_table_row .focus_table_col .focus_inner img {
    width: 100%
}

.widget_column section.focus_table .focus_table_row .focus_table_logo_col {
    padding: 0
}

.widget_column section.focus_table .focus_table_row .focus_table_logo_col:first-child .focus_inner {
    background-color: #000
}

.widget_column section.focus_table .focus_table_row .focus_table_logo_col:first-child .focus_inner img {
    width: 100%
}

.widget_column section.focus_table .focus_table_row h3 {
    margin-top: 0
}

.widget_column section.side_news_table>.row:last-child {
    background-color: #fff;
    text-align: right
}

.widget_column section.side_news_table>.row:last-child a {
    color: #1c1c1c;
    font-size: .9em;
    font-weight: 600;
    padding: .5em
}

.widget_column section.side_news_table>.row:last-child a:focus,
.widget_column section.side_news_table>.row:last-child a:hover {
    color: #dc3545;
    text-decoration: none
}

.widget_column section.side_news_table>.row:last-child a:after {
    color: #dc3545;
    content: ">>";
    margin-left: .2em;
    width: 2em
}

.widget_column section.other_products_table,
.widget_column section.side_navigation_table,
.widget_column section.supported_platforms_table {
    padding-bottom: .5em
}

.widget_column .rating_bars_section {
    padding-left: 0;
    padding-right: 0
}

.widget_column .rating_bars_section .row {
    background: #f5f5f5;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjAlIj48c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjZjVmNWY1Ii8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjYzdjN2M3Ii8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNMCAwaDF2MUgweiIvPjwvc3ZnPg==);
    background: linear-gradient(90deg, #f5f5f5 1%, #c7c7c7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5", endColorstr="#c7c7c7", GradientType=1);
    margin-bottom: .6em;
    overflow: hidden
}

.widget_column .rating_bars_section .row:first-child {
    margin-top: 1em
}

.widget_column .rating_bars_section .row .rating_bar {
    background: #facf00;
    background: linear-gradient(180deg, #facf00 1%, #faa700);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#facf00", endColorstr="#faa700", GradientType=0);
    height: 1.5em;
    padding: .2em .5em;
    position: relative
}

.widget_column .rating_bars_section .row .rating_bar:before {
    background: #facf00;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmYWNmMDAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmYWE3MDAiLz48L2xpbmVhckdyYWRpZW50PjxwYXRoIGZpbGw9InVybCgjYSkiIGQ9Ik0wIDBoMXYxSDB6Ii8+PC9zdmc+);
    background: linear-gradient(135deg, #facf00, #faa700);
    content: "";
    display: block;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#facf00", endColorstr="#faa700", GradientType=1);
    height: 1.5em;
    left: 100%;
    margin-left: -.75em;
    pointer-events: none;
    position: absolute;
    top: 15%;
    transform: rotate(45deg);
    width: 1.5em
}

.widget_column .rating_bars_section .row .rating_bar .rating_bar_inner {
    color: #000;
    font-family: Poppins Bold, Helvetica, Arial, sans-serif;
    position: absolute;
    white-space: nowrap
}

.widget_column .rating_bars_section .row .rating_bar .rating_bar_inner p {
    line-height: 1.2
}

.template-product-review .widget_column section.supported_platforms_table {
    border: none;
    margin: 0;
    padding: 0 8px
}

.template-product-review .widget_column section.supported_platforms_table .right_col_row:first-child {
    padding: 0
}

.template-product-review .widget_column section.supported_platforms_table .right_col_row:nth-child(2n) {
    background-color: #fff
}

.template-product-review .widget_column section.supported_platforms_table .row h3 {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    margin: 0;
    padding: 8px 0 8px 15px;
    width: 100%
}

.template-product-review .widget_column section.supported_platforms_table .row .sidebar_Icons {
    background-color: #ececec;
    margin: 0 0 5px;
    padding: 8px 0
}

.template-product-review .widget_column section.supported_platforms_table .row .sidebar_Icons:nth-child(odd) {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding-left: 5px
}

.template-product-review .widget_column section.supported_platforms_table .row .sidebar_Icons:nth-child(2n) {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px
}

.template-product-review .widget_column section.supported_platforms_table .row .sidebar_Icons .sidebar_Icons_text {
    height: unset
}

.template-product-review .widget_column .other_products_table {
    border: none
}

.template-product-review .widget_column .other_products_table .row h3 {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 5px;
    padding: 8px 0 8px 15px;
    width: 100%
}

.template-product-review .widget_column .other_products_table .related_products_row:nth-child(2n) {
    border-radius: 4px
}

.promotions_table {
    font-size: .8em;
    margin-bottom: 1em;
    margin-top: 1em
}

.promotions_table .promo_table_row {
    background: #fff;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    color: #1c1c1c;
    height: 4.8em;
    padding: 0 1em
}

.promotions_table .promo_table_row:not(:first-child) {
    height: auto !important
}

.promotions_table .promo_table_row:not(:first-child) .promo_table_col {
    border-bottom: 1px solid #d8d8d8;
    height: 4.8em;
    padding-left: 0;
    padding-right: 0
}

.promotions_table .promo_table_row:not(:first-child) .promo_table_col .cell_inner {
    padding-left: 0;
    padding-right: 0
}

.promotions_table .promo_table_row:not(:first-child) .promo_table_terms {
    border-top: 1px solid #d8d8d8;
    color: #a9a9a9;
    font-size: 10px;
    height: auto;
    padding: 10px 0
}

.promotions_table .promo_table_row:last-child .promo_table_col {
    border-bottom: 0
}

.promotions_table .promo_table_row .promo_table_col {
    height: inherit;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center
}

.promotions_table .promo_table_row .promo_table_col.play_now_col .cell_inner {
    word-break: break-all
}

.promotions_table .promo_table_row .promo_table_col .promotions_table_cell {
    height: inherit;
    width: 100%
}

.promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner {
    align-items: center;
    display: flex;
    float: none;
    height: 100%;
    justify-content: center;
    padding: 0 5px;
    vertical-align: middle;
    word-break: break-word
}

.promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner p {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0
}

@media (max-width:767px) {
    .promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner p {
        font-size: .8em;
        overflow: hidden
    }
}

@media (min-width:768px) and (max-width:991px) {
    .promotions_table .promo_table_row .promo_table_col .promotions_table_cell .cell_inner p {
        font-size: 1em
    }
}

.promotions_table .promo_table_row .play_now_col {
    padding-right: 0
}

.promotions_table .promo_table_row:first-child {
    background: linear-gradient(#fff 1%, #f5f5f5);
    border: 1px solid #d8d8d8;
    color: #505050;
    height: 4em
}

.promotions_table .promo_table_row:last-child {
    border-bottom: 1px solid #d8d8d8
}

.body_content .promotions_table .promo_table_row_terms {
    display: block;
    height: auto;
    padding: .5em 2em;
    text-align: right
}

.body_content .promotions_table .promo_table_row_terms a,
.body_content .promotions_table .promo_table_row_terms span {
    color: #777;
    cursor: pointer;
    font-size: .8em
}

.brand_review_promotions_table .row {
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 0
}

@media (min-width:992px) {
    .brand_review_promotions_table .row {
        border: none;
        padding: 0
    }

    .brand_review_promotions_table .title {
        background-color: #ececec;
        border-bottom: 1px solid #c4c4c4;
        height: 34px
    }
}

.brand_review_promotions_table .title p {
    color: #757575;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase
}

@media (min-width:992px) {
    .brand_review_promotions_table .value {
        height: 82px
    }
}

.brand_review_promotions_table .terms {
    border-bottom: 1px solid #757575
}

@media (min-width:992px) {
    .brand_review_promotions_table .terms {
        border-bottom: 1px solid #c4c4c4;
        margin-bottom: 20px
    }
}

.brand_review_promotions_table .terms span {
    color: #757575;
    font-size: 12px
}

.brand_review_promotions_table .us_play_now_btn {
    background-color: #fc3;
    border-bottom: 8px solid #f93;
    border-radius: 4px;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    height: 54px;
    max-width: 250px;
    text-transform: uppercase;
    width: 100%
}

@media (min-width:992px) {
    .brand_review_promotions_table .us_play_now_btn {
        width: 120px
    }
}

.brand_review_promotions_table .us_play_now_btn:hover {
    background-color: #f93
}

.box-terms-conditions {
    background: #f8f8f8;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 20px;
    padding: 10px 10px 10px 33px;
    position: relative
}

.box-terms-conditions #terms-conditions {
    position: absolute;
    top: -100px
}

.box-terms-conditions .title {
    display: block;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 5px
}

.box-terms-conditions .apexes {
    left: 10px;
    position: absolute
}

.box-terms-conditions .iframe {
    height: 291px;
    margin: 5px 0;
    overflow-y: auto;
    padding: 0 10px 0 0;
    position: relative;
    width: 100%
}

.box-terms-conditions .iframe::-webkit-scrollbar {
    width: 6px
}

.box-terms-conditions .iframe::-webkit-scrollbar-thumb {
    background-color: #e5e5e5 !important;
    border: 1px solid #d3d3d3
}

.box-terms-conditions .last_updated {
    font-size: 11px;
    font-style: italic;
    line-height: 10px;
    margin-top: 15px
}

@media (max-width:991px) {
    .box-terms-conditions {
        margin-bottom: 10px
    }
}

.review_hero_unit {
    background: #333;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjMzMzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMWMxYzFjIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNMCAwaDF2MUgweiIvPjwvc3ZnPg==);
    background: linear-gradient(180deg, #505050 1%, #333);
    border-top: 1px solid #505050;
    box-shadow: 0 .3em 2px #000;
    font-size: inherit;
    margin-bottom: 0;
    padding: 11px !important;
    position: relative;
    text-align: center
}

@media (min-width:768px) {
    .review_hero_unit {
        display: flex
    }
}

.review_hero_unit .bonus_col,
.review_hero_unit .logo_col {
    display: table;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px
}

@media (max-width:767px) {

    .review_hero_unit .bonus_col,
    .review_hero_unit .logo_col {
        width: 100%
    }
}

.review_hero_unit .bonus_col .container-fluid,
.review_hero_unit .logo_col .container-fluid {
    display: table-cell;
    padding-left: .25em;
    padding-right: .25em;
    vertical-align: middle
}

@media (max-width:767px) {

    .review_hero_unit .bonus_col .container-fluid,
    .review_hero_unit .logo_col .container-fluid {
        padding-left: 0;
        padding-right: 0
    }
}

.review_hero_unit .play_now_row .play_now_btn {
    padding-left: .75em;
    padding-right: .75em;
    text-transform: uppercase
}

@media (min-width:480px) {
    .review_hero_unit .play_now_row .play_now_btn {
        width: 295px
    }
}

.review_hero_unit .bonus_col .row {
    display: table;
    margin-left: 0;
    margin-right: 0;
    width: 100%
}

.review_hero_unit .bonus_col .row .bonus_desc_col {
    padding: 1em 1em 0
}

@media (max-width:767px) {
    .review_hero_unit .bonus_col .row .bonus_desc_col {
        padding: 0
    }
}

.review_hero_unit .op_header_col {
    display: table-cell;
    float: none;
    padding-left: 8px;
    padding-right: 8px;
    vertical-align: middle
}

@media (max-width:375px) {
    .review_hero_unit .op_header_col {
        display: block
    }
}

.review_hero_unit .review_hero {
    background: #fff;
    border: 1px solid #000;
    min-height: 12em;
    padding: 0;
    width: 100%
}

@media (min-width:768px) {
    .review_hero_unit .review_hero {
        display: flex
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col {
    border-right: 1px solid #777;
    padding-left: 0;
    padding-right: 0
}

@media (max-width:767px) {
    .review_hero_unit .review_hero>.review_hero_sm_col {
        border-right: none
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col:last-child {
    border-right: 0
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid {
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-table;
    padding: 0;
    width: 100%
}

@media (min-width:768px) {
    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid {
        height: 11.9em
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description,
.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_image,
.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_stats {
    display: table-cell;
    float: none;
    position: relative;
    vertical-align: top;
    width: 100%
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description,
    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_image,
    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_stats {
        margin: 0 -1px
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_image {
    background-color: #fff
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_image img {
    height: 11.85em
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid {
    padding: 0
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:first-child {
    background-color: #eee;
    padding: 1em 0
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:first-child p {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 0
}

@media (max-width:767px) {
    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:first-child p {
        font-size: .8em
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child {
    height: 5.8em
}

@media (min-width:768px) and (max-width:991px) {
    .review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child {
        height: 6em
    }
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child .non_product_col {
    height: inherit;
    padding: 0
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child .non_product_col .description_inner {
    display: table-cell;
    float: none;
    height: inherit;
    min-width: 6em;
    vertical-align: middle;
    width: inherit
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child .non_product_col .description_inner span {
    margin: .25em 0
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child .non_product_col .description_inner span em {
    color: #dc3545;
    font-size: 1.6em;
    font-style: normal
}

.review_hero_unit .review_hero>.review_hero_sm_col>.container-fluid .review_description .container-fluid:last-child .non_product_col .description_inner img {
    margin: 0 auto
}

.review_hero_unit .review_hero>.col-sm-4 .container-fluid:after,
.review_hero_unit .review_hero>.col-sm-4 .container-fluid:before {
    content: none;
    display: none;
    width: 0
}

.review_hero_unit .review_hero>.col-sm-4 .container-fluid .review_image {
    padding: .25em;
    vertical-align: middle
}

.review_hero_unit .review_hero>.col-sm-4 .container-fluid .review_image img {
    width: 100%
}

@media (min-width:768px) and (max-width:991px) {
    .review_hero_unit .review_hero>.col-sm-7 {
        height: 6em
    }
}

.review_hero_unit .operator_review_hero {
    background: #000;
    border: 1px solid #000;
    display: inline-block;
    padding: 0;
    position: relative;
    width: 100%
}

@media (min-width:768px) {
    .review_hero_unit .operator_review_hero {
        display: flex
    }
}

.review_hero_unit .operator_review_hero>.logo_col {
    height: 100%
}

.review_hero_unit .operator_review_hero>.logo_col .container-fluid img {
    height: inherit;
    margin: 0 auto;
    max-height: 187px
}

.review_hero_unit .operator_review_hero .input-group {
    display: table
}

.review_hero_unit .bonus_col {
    background-color: #333;
    border-left: .6em solid #1c1c1c;
    display: table;
    height: 100%;
    width: auto
}

@media (max-width:767px) {
    .review_hero_unit .bonus_col {
        border-left: none;
        padding: 20px 10px
    }
}

.review_hero_unit .bonus_col .container-fluid {
    display: table-cell;
    vertical-align: middle
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col {
    color: #fff;
    text-align: center
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col .review-offer {
    font-family: Poppins Bold, Helvetica, Arial, sans-serif;
    font-size: 2em
}

@media (min-width:768px) and (max-width:991px) {
    .review_hero_unit .bonus_col .container-fluid .row .op_header_col .review-offer {
        font-size: 1.5em
    }
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col:first-child {
    color: #dc3545;
    text-align: right
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col:first-child>span {
    font-size: 4em
}

@media (min-width:768px) and (max-width:991px) {
    .review_hero_unit .bonus_col .container-fluid .row .op_header_col:first-child>span {
        font-size: 3.6em
    }
}

@media (max-width:767px) {
    .review_hero_unit .bonus_col .container-fluid .row .op_header_col:first-child>span {
        display: block;
        text-align: center;
        width: 100%
    }
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col:nth-child(3) {
    color: #dc3545;
    text-align: left
}

.review_hero_unit .bonus_col .container-fluid .row .op_header_col:nth-child(3)>span {
    font-size: 4em
}

@media (min-width:768px) and (max-width:991px) {
    .review_hero_unit .bonus_col .container-fluid .row .op_header_col:nth-child(3)>span {
        font-size: 3.6em
    }
}

@media (max-width:767px) {
    .review_hero_unit .bonus_col .container-fluid .row .op_header_col:nth-child(3)>span {
        display: block;
        text-align: center;
        width: 100%
    }
}

.review_hero_unit .bonus_col .container-fluid .row .fdb_col {
    margin-bottom: .4em;
    text-align: center
}

.review_hero_unit .bonus_col .container-fluid .row .fdb_col span {
    color: #fff;
    font-size: 1.6em;
    margin-top: 0
}

.review_hero_unit .bonus_col .container-fluid .row .fdb_col span em {
    color: #dc3545
}

.review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col {
    text-align: center
}

.review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col span.review-offer {
    color: #fff;
    display: block;
    font-family: Poppins Bold, Helvetica, Arial, sans-serif;
    font-size: 43px;
    line-height: 43px;
    margin-bottom: .31em
}

@media (max-width:991px) {
    .review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col span.review-offer {
        font-size: 31px
    }
}

.review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col span.review-offer .review-no-header-heading {
    font-family: Poppins, Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 26px;
    text-align: left
}

.review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col span.review-offer .review-no-header-text {
    font-family: Poppins, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    padding-top: 10px;
    text-align: left
}

.review_hero_unit .bonus_col .container-fluid .row .bonus_desc_col span.review-offer em {
    color: #dc3545;
    font-style: normal
}

.operator-product-header-terms-conditions {
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    padding: .5em 0;
    text-align: right;
    text-align: center
}

.operator-product-header-terms-conditions a,
.operator-product-header-terms-conditions span.link {
    color: #777;
    cursor: pointer
}

.operator-product-header-terms-conditions a:hover,
.operator-product-header-terms-conditions span.link:hover {
    text-decoration: underline
}

.template-operator-review .review_hero_unit .logo_col {
    padding: 0 !important
}

@media (max-width:767px) {
    .template-operator-review .review_hero_unit .logo_col .img-fluid {
        width: 100%
    }
}

@media (min-width:768px) {
    .template-operator-review .review_hero_unit .logo_col {
        width: 41.6666667% !important
    }
}

.template-product-review .review_hero_unit .logo_col {
    background: #000
}

@media (max-width:767px) {
    .template-product-review .review_hero_unit .logo_col {
        padding: 0 !important
    }

    .template-product-review .review_hero_unit .logo_col .container-fluid {
        padding: 0
    }

    .template-product-review .review_hero_unit .logo_col .container-fluid img {
        background: #000;
        max-width: 100%
    }
}

@media (min-width:768px) {
    .template-product-review .review_hero_unit .logo_col {
        width: 41.66666667% !important
    }
}

@media (max-width:767px) {
    .template-product-review .review_hero_unit .bonus_col {
        border: 1px solid #000;
        border-top: .6em solid #1c1c1c;
        margin-top: -5px;
        padding: 20px 10px;
        width: 100% !important
    }
}

@media (min-width:768px) {
    .template-product-review .review_hero_unit .bonus_col {
        border-left: .6em solid #1c1c1c;
        width: 58.33333333% !important
    }
}

.available_products .available_product_col {
    height: 5em;
    margin-bottom: 1em
}

.available_products .available_product_col .product_icon_col {
    height: inherit;
    text-align: center
}

.available_products .available_product_col .product_icon_col .product_icon_container {
    display: table;
    height: inherit
}

.available_products .available_product_col .product_icon_col .product_icon_container span {
    display: table-cell;
    float: none;
    font-size: 48px;
    vertical-align: middle
}

@media (max-width:991px) {
    .available_products .available_product_col .product_icon_col .product_icon_container span {
        font-size: 36px
    }
}

.available_products .available_product_col .product_icon_col .product_icon_container span.icon-slots {
    font-size: 42px
}

@media (max-width:991px) {
    .available_products .available_product_col .product_icon_col .product_icon_container span.icon-slots {
        font-size: 30px
    }
}

.available_products .available_product_col .product_icon_col .product_icon_container span.icon-bookmaker {
    font-size: 42px
}

@media (max-width:991px) {
    .available_products .available_product_col .product_icon_col .product_icon_container span.icon-bookmaker {
        font-size: 30px
    }
}

.available_products .available_product_col .product_icon_col .product_icon_container span.icon-bingo {
    font-size: 42px
}

@media (max-width:991px) {
    .available_products .available_product_col .product_icon_col .product_icon_container span.icon-bingo {
        font-size: 30px
    }
}

.available_products .available_product_col .prodcut_type_col h4 {
    margin-top: 0;
    overflow: hidden;
    overflow-wrap: break-word
}

.available_products .available_product_col [class^=icon-] {
    font-size: 2em
}

.available_products .available_product_col .icon-check {
    color: #8cd221;
    font-size: 24px
}

.available_products .available_product_col .icon-cross {
    color: #dc3545;
    font-size: 24px
}

.available_products .available_product_col .icon-check:after,
.available_products .available_product_col .icon-cross:after {
    background-color: rgba(28, 28, 28, .35);
    border-radius: 100%;
    bottom: 2%;
    box-shadow: 0 0 .25em #1c1c1c;
    content: "";
    display: block;
    height: .085em;
    position: absolute;
    width: 1em
}

.operator_product .terms-and-conditions {
    color: #777;
    display: block;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    padding: 4px 8px 8px;
    text-align: left
}

.operator_product .terms-and-conditions a {
    color: #777
}

.operator_product .terms-and-conditions a:hover {
    text-decoration: underline
}

@media (max-width:767px) {
    .operator_product .operator_product_row {
        margin-left: -16px;
        margin-right: -16px;
        overflow: hidden
    }
}

.operator_product .operator_product_row>.prod_logo {
    padding: 1em;
    text-align: center
}

.operator_product .operator_product_row>.prod_logo span a img {
    width: 100%
}

.operator_product .operator_product_row .prod_details {
    padding-top: 1em
}

.operator_product .operator_product_row .prod_details ul {
    padding-left: 2em !important
}

@media (max-width:767px) {
    .operator_product .operator_product_row .prod_details ul {
        padding-left: 4em !important
    }
}

.operator_product .operator_product_row .prod_details ul li {
    font-size: 12px;
    list-style: none;
    margin-bottom: 12px
}

.operator_product .operator_product_row .prod_details ul li:before {
    color: #dc3545;
    content: "\ea10";
    font-family: GDCIcons;
    margin-left: -2em;
    position: absolute
}

.operator_product .operator_product_row .prod_details>span.h2 {
    font-weight: 700;
    text-align: center
}

.operator_product .operator_product_row .prod_details>span.h2 em {
    color: #dc3545;
    font-style: normal
}

.operator_product .operator_product_row .prod_banner {
    background: #333;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjMzMzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMWMxYzFjIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNMCAwaDF2MUgweiIvPjwvc3ZnPg==);
    background: linear-gradient(180deg, #333 1%, #1c1c1c);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$gray-dark", endColorstr="$gray-darker", GradientType=0);
    padding: .5em 1em
}

.operator_product .operator_product_row .prod_banner .operator_product_header span {
    color: #fff;
    float: none;
    font-size: 2em;
    line-height: 1.1;
    margin-right: 1em;
    vertical-align: middle
}

.operator_product .operator_product_row .prod_banner .operator_product_header h3 {
    color: #fff
}

.operator_product .operator_product_row .prod_banner .operator_product_header a,
.operator_product .operator_product_row .prod_banner .operator_product_header h3 {
    float: none;
    font-size: 19px;
    min-width: 6em;
    vertical-align: middle
}

@media (max-width:767px) {

    .operator_product .operator_product_row .prod_banner .operator_product_header a,
    .operator_product .operator_product_row .prod_banner .operator_product_header h3 {
        font-size: 15px
    }
}

.operator_product .operator_product_row:last-child {
    padding: 1em 0
}

.operator_product .operator_product_row:last-child .prod_bonus {
    font-weight: 700;
    margin-bottom: 2em;
    padding-top: 0;
    text-align: center
}

.operator_product .operator_product_row:last-child .prod_bonus span em {
    color: #dc3545;
    font-style: normal
}

.operator_product .operator_product_row:last-child .prod_play_now {
    padding-top: 0;
    text-align: center
}

@media only screen and (-webkit-min-device-pixel-ratio:2) and (max-device-width:575px) and (min-device-width:375px) {
    .operator_product .operator_product_row:last-child .prod_play_now .play_now_btn {
        width: 250px
    }
}

[class*=" icon-"],
[class^=icon-] {
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: GDCIcons;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
}

.icon-exclusive-bonus:before {
    content: "\e901"
}

.icon-lottery:before {
    content: "\e900"
}

.icon-daily-fantasy:before {
    content: "\e800"
}

.icon-top:before {
    content: "\e633"
}

.icon-social:before {
    content: "\e629"
}

.icon-easy-hard:before {
    content: "\e639"
}

.icon-desktop:before {
    content: "\e62a"
}

.icon-download:before {
    content: "\e62b"
}

.icon-in-running:before {
    content: "\e62c"
}

.icon-instant-play:before {
    content: "\e62d"
}

.icon-tablet:before {
    content: "\e62e"
}

.icon-tournament:before {
    content: "\e62f"
}

.icon-exchange:before {
    content: "\e630"
}

.icon-great-odds-pricing:before {
    content: "\e631"
}

.icon-windows:before {
    content: "\e632"
}

.icon-android:before {
    content: "\e634"
}

.icon-blackberry:before {
    content: "\e635"
}

.icon-iphone:before {
    content: "\e636"
}

.icon-mac:before {
    content: "\e637"
}

.icon-windowsphone:before {
    content: "\e638"
}

.icon-double-chevron:before {
    content: "\e622"
}

.icon-proceed:before {
    content: "\e623"
}

.icon-facebook:before {
    content: "\e624"
}

.icon-twitter:before {
    content: "\e625"
}

.icon-google-plus:before {
    content: "\e626"
}

.icon-linkedin:before {
    content: "\e627"
}

.icon-rss:before {
    content: "\e628"
}

.icon-homelist-bingo:before {
    content: "\e600"
}

.icon-account-setup:before {
    content: "\e601"
}

.icon-betting-market:before {
    content: "\e602"
}

.icon-bingo:before {
    content: "\e603"
}

.icon-bookmaker:before {
    content: "\e604"
}

.icon-casino:before {
    content: "\e606"
}

.icon-etiquette:before {
    content: "\e607"
}

.icon-eu-roulette:before {
    content: "\e608"
}

.icon-event-betting-coverage:before {
    content: "\e609"
}

.icon-event-coverage:before {
    content: "\e60a"
}

.icon-review:before {
    content: "\e60c"
}

.icon-games:before {
    content: "\e60d"
}

.icon-game-strategy:before {
    content: "\e60e"
}

.icon-homelist-casino:before {
    content: "\e605"
}

.icon-homelist-live_casino:before {
    content: "\e60f"
}

.icon-homelist-poker:before {
    content: "\e610"
}

.icon-homelist-sports:before {
    content: "\e611"
}

.icon-in-running-betting:before {
    content: "\e612"
}

.icon-overview:before {
    content: "\e613"
}

.icon-knowledge:before {
    content: "\e614"
}

.icon-live-casino:before {
    content: "\e615"
}

.icon-merkets:before {
    content: "\e616"
}

.icon-mobile:before {
    content: "\e617"
}

.icon-network:before {
    content: "\e618"
}

.icon-news:before {
    content: "\e619"
}

.icon-payment:before {
    content: "\e61a"
}

.icon-player-security:before {
    content: "\e61b"
}

.icon-Pokercopy:before {
    content: "\e63a"
}

.icon-poker:before {
    content: "\e61c"
}

.icon-product-release:before {
    content: "\e61d"
}

.icon-slots:before {
    content: "\e61e"
}

.icon-software:before {
    content: "\e61f"
}

.icon-sports:before {
    content: "\e620"
}

.icon-usa-roulette:before {
    content: "\e621"
}

.icon-bitcoin:before {
    content: "\e63b"
}

.icon-home:before {
    content: "\e902"
}

.icon-quotes-left:before {
    content: "\e977"
}

.icon-quotes-right:before {
    content: "\e978"
}

.icon-star-empty:before {
    content: "\e9d7"
}

.icon-star-half:before {
    content: "\e9d8"
}

.icon-star-full:before {
    content: "\e9d9"
}

.icon-cross:before {
    content: "\ea0f"
}

.icon-check:before {
    content: "\ea10"
}

.icon-play:before {
    content: "\ea1c"
}

.operator-item-v2 {
    -webkit-animation: fadeAnimation .3s;
    background-color: #fff !important;
    border: 1px solid rgba(51, 51, 51, .25);
    border-radius: 10px !important;
    box-shadow: 0 2px 3px rgba(3, 15, 39, .12);
    margin-bottom: 16px !important
}

.operator-item-v2 .logo-wrapper img {
    margin: 0 auto
}

.operator-item-v2 .operator-column-logo-v2 a {
    height: 100px
}

.operator-item-v2 .operator-main {
    padding-bottom: 20px;
    padding-top: 10px
}

.operator-item-v2 .operator-main .operator-column-ranking-v2 {
    background-color: #0157ff;
    border-radius: 0 4px 4px 0;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    height: 28px;
    padding-top: 5px;
    text-align: center;
    width: 28px
}

.operator-item-v2 .operator-main .operator-playnow-column-v2 a:hover {
    text-decoration: none
}

@media screen and (min-width:577px) {
    .operator-item-v2 .operator-main .operator-column-ranking-v2 {
        margin-top: 10px
    }

    .operator-item-v2 .operator-main .logo-wrapper {
        width: -moz-max-content;
        width: max-content
    }

    .operator-item-v2 .operator-main .operator-column-logo-v2 {
        padding-left: 10px;
        text-align: center;
        vertical-align: middle
    }

    .operator-item-v2 .operator-main .operator-column-bonus-v2 {
        margin: 10px auto auto;
        padding: 20px 30px
    }

    .operator-item-v2 .operator-main .operator-playnow-column-v2 {
        margin-left: auto;
        margin-right: 27px
    }

    .operator-item-v2 .operator-main .button-blue-v2 {
        margin: auto;
        width: 306px
    }
}

@media screen and (min-width:992px) {
    .operator-item-v2 .operator-main .operator-playnow-column-v2 {
        margin-top: 32px
    }
}

@media screen and (max-width:576px) {
    .operator-item-v2 .operator-main .operator-column-ranking-v2 {
        margin-top: 4px
    }

    .operator-item-v2 .operator-main .operator-column-logo-v2 {
        margin: auto;
        padding-right: 14px;
        text-align: center;
        vertical-align: middle
    }

    .operator-item-v2 .operator-main .operator-column-bonus-v2 {
        margin-top: 22px;
        padding: 10px
    }

    .operator-item-v2 .operator-main .operator-playnow-column-v2 {
        padding: 10px;
        width: 100%
    }
}

.operator-item-v2 .more_info_button {
    align-content: center;
    color: #0157ff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin-top: 20px
}

.operator-item-v2 .more_info_button:hover {
    cursor: pointer
}

.operator-item-v2 .more_info_arrow_down {
    background: url(https://objects.kaxmedia.com/auto/o/140124/9a7774e46d.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    display: inline-block;
    height: 16px;
    margin-left: 5px;
    margin-top: unset;
    width: 16px
}

.operator-item-v2 .arrow_right {
    background: url(https://objects.kaxmedia.com/auto/o/139703/8a8d4cc06f.svg);
    background-position: -3px 0;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    margin-left: 3px;
    vertical-align: middle;
    width: 16px
}

.operator-item-v2 .more_info_arrow_up {
    background: url(https://objects.kaxmedia.com/auto/o/140125/0242a7fa79.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    display: inline-block;
    height: 16px;
    margin-left: 5px;
    margin-top: unset;
    width: 16px
}

.operator-item-v2 .offer-description {
    font-weight: 600;
    text-align: center
}

.operator-item-v2 .offer-description em {
    color: #dc3545
}

@media screen and (min-width:577px) {
    .operator-item-v2 .more-info-table {
        border-top: 1px solid rgba(51, 51, 51, .25);
        display: none;
        padding: 25px
    }

    .operator-item-v2 .attribute-value {
        color: #333;
        font-weight: 700
    }

    .operator-item-v2 .attribute {
        width: 200px
    }
}

.operator-item-v2 .attribute-value {
    font-size: 12px
}

@media screen and (max-width:576px) {
    .operator-item-v2 .more-info-table {
        border-top: 1px solid rgba(51, 51, 51, .25);
        display: none;
        padding: 10px
    }

    .operator-item-v2 .attribute-value {
        color: #333;
        font-weight: 700
    }

    .operator-item-v2 .attribute {
        padding-top: 10px;
        width: 30%
    }

    .operator-item-v2 .attributes-list {
        margin-top: 10px
    }

    .operator-item-v2 .operator-main {
        flex-direction: column
    }

    .operator-item-v2 .attribute-tooltip .tooltiptext-left {
        right: 105%;
        top: -5px
    }
}

.operator-item-v2 .attribute-name {
    min-height: 36px
}

.operator-item-v2 .operator-established-year-v2 {
    color: #777;
    font-size: 12px;
    text-align: center;
    width: 100%
}

.operator-item-v2 .read-review-link-2 {
    color: #0157ff;
    font-size: 14px;
    top: 10px
}

.operator-item-v2 .read-terms-link-v2 {
    display: block
}

.operator-item-v2 .button-blue-v2 {
    background: #0157ff;
    border: 0;
    border-radius: 4px;
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.operator-item-v2 .button-blue-v2:hover {
    background: #003ee6
}

.operator-item-v2 .white_lock {
    margin-bottom: 5px;
    margin-left: 10px
}

.operator-item-v2 .read-terms-link-v2 {
    border-top: 1px solid rgba(51, 51, 51, .25);
    color: #777;
    font-size: 11px;
    line-height: 16px;
    padding: 10px
}

.operator-item-v2 .ico_bonus_feature,
.operator-item-v2 .ico_free_spins {
    height: 21px;
    margin-right: 5px;
    width: 21px
}

.operator-item-v2 .attributes-list {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px
}

.operator-item-v2 .attribute {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    margin-top: 5px;
    padding-right: 10px
}

.operator-item-v2 .attribute-list-item {
    margin-bottom: 12px
}

.operator-item-v2 .bottom-attribute {
    font-size: 16px;
    line-height: 22px
}

.operator-item-v2 .green-tick {
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDE3MiAxNzIiIHN0eWxlPSJmaWxsOiMwMDAiPjxnIGZpbGw9Im5vbmUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZm9udC1mYW1pbHk9Im5vbmUiIGZvbnQtd2VpZ2h0PSJub25lIiBmb250LXNpemU9Im5vbmUiIHRleHQtYW5jaG9yPSJub25lIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6bm9ybWFsIj48cGF0aCBkPSJNMCAxNzJWMGgxNzJ2MTcyeiIvPjxwYXRoIGQ9Ik0xNDMuMTkzIDQyLjkzYTcuMTY3IDcuMTY3IDAgMCAwLTQuOTI3IDIuMTdMNjQuNSAxMTguODY2bC0yMy42LTIzLjZBNy4xNjcgNy4xNjcgMCAxIDAgMzAuNzY2IDEwNS40bDI4LjY2NyAyOC42NjdhNy4xNjcgNy4xNjcgMCAwIDAgMTAuMTM0IDBMMTQ4LjQgNTUuMjM0YTcuMTY3IDcuMTY3IDAgMCAwLTUuMjA3LTEyLjMwNHoiIGZpbGw9IiM1MGFiNmUiLz48L2c+PC9zdmc+") 50% 50% no-repeat;
    background-position: 0 5px;
    background-size: 16px 16px;
    display: inline-block;
    display: block;
    padding-left: 20px
}

.operator-item-v2 .attribute-tooltip {
    display: inline-block;
    position: relative
}

.operator-item-v2 .attribute-tooltip:hover .tooltiptext,
.operator-item-v2 .attribute-tooltip:hover .tooltiptext-left {
    visibility: visible
}

.operator-item-v2 .attribute-tooltip .tooltiptext,
.operator-item-v2 .attribute-tooltip .tooltiptext-left {
    background-color: #f7f7f7;
    border-radius: 6px;
    color: #333;
    padding: 5px;
    position: absolute;
    visibility: hidden;
    width: 150px;
    z-index: 1
}

.operator-item-v2 .promo-code {
    background: #e6f1fc;
    border: 1px dashed #0157ff;
    border-radius: 4px;
    box-sizing: border-box;
    color: #0157ff;
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    margin-top: -7px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase
}

.operator-item-v2 .payment-provider {
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 2px
}

.operator-item-v2 .payment-provider-img {
    height: 24px;
    width: 30px
}

.operator-item-v2 .tooltip-icon {
    margin-bottom: 2px;
    margin-left: 3px;
    width: 13px
}

.operator-item-v2 .attribute-name-text {
    margin-right: 5px
}

.operator-item-v2 .min-content {
    width: -moz-min-content;
    width: min-content
}

.operator-item-v2 .gambling-comission-logo {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    text-align: center;
    top: 124px
}

@media screen and (max-width:993px) {
    .operator-item-v2 .gambling-comission-logo {
        padding-top: 10px
    }
}

@media screen and (min-width:993px) {
    .operator-item-v2 .gambling-comission-logo {
        position: absolute
    }
}

.article-main-content .operator-item-v2 .attributes-list,
.article-main-content .operator-item-v2 .operator-playnow-column-v2,
.operator-list-small .operator-item-v2 .attributes-list,
.operator-list-small .operator-item-v2 .operator-playnow-column-v2 {
    flex: 0 0 100%;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%
}

.article-main-content .operator-item-v2 .operator-playnow-column-v2,
.operator-list-small .operator-item-v2 .operator-playnow-column-v2 {
    margin-top: 0
}

.article-main-content .operator-item-v2 .attribute,
.operator-list-small .operator-item-v2 .attribute {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
}

.article-main-content .operator-item-v2 .attributes-list+div,
.operator-list-small .operator-item-v2 .attributes-list+div {
    flex: 0 0 100%;
    max-width: 100%
}

@media screen and (min-width:993px) {

    .article-main-content .operator-item-v2 .gambling-comission-logo,
    .operator-list-small .operator-item-v2 .gambling-comission-logo {
        padding-top: 10px;
        position: static
    }
}

@media screen and (max-width:992px) {

    .article-main-content .operator-item-v2 .attribute,
    .operator-list-small .operator-item-v2 .attribute {
        flex: 0 0 50%;
        max-width: 50%
    }

    .article-main-content .operator-item-v2 .operator-column-ranking-v2,
    .operator-list-small .operator-item-v2 .operator-column-ranking-v2 {
        margin-top: 4px
    }

    .article-main-content .operator-item-v2 .operator-column-logo-v2,
    .operator-list-small .operator-item-v2 .operator-column-logo-v2 {
        margin: auto;
        padding-right: 14px;
        text-align: center;
        vertical-align: middle
    }

    .article-main-content .operator-item-v2 .operator-column-bonus-v2,
    .operator-list-small .operator-item-v2 .operator-column-bonus-v2 {
        margin-top: 22px;
        padding: 10px
    }

    .article-main-content .operator-item-v2 .operator-playnow-column-v2,
    .operator-list-small .operator-item-v2 .operator-playnow-column-v2 {
        padding: 10px;
        width: 100%
    }

    .article-main-content .operator-item-v2 .more-info-table,
    .operator-list-small .operator-item-v2 .more-info-table {
        border-top: 1px solid rgba(51, 51, 51, .25);
        display: none;
        padding: 10px
    }

    .article-main-content .operator-item-v2 .attribute-value,
    .operator-list-small .operator-item-v2 .attribute-value {
        color: #333;
        font-weight: 700
    }

    .article-main-content .operator-item-v2 .attribute,
    .operator-list-small .operator-item-v2 .attribute {
        padding-top: 10px;
        width: 30%
    }

    .article-main-content .operator-item-v2 .attributes-list,
    .operator-list-small .operator-item-v2 .attributes-list {
        margin-top: 10px
    }

    .article-main-content .operator-item-v2 .operator-main,
    .operator-list-small .operator-item-v2 .operator-main {
        flex-direction: column
    }

    .article-main-content .operator-item-v2 .attribute-tooltip .tooltiptext-left,
    .operator-list-small .operator-item-v2 .attribute-tooltip .tooltiptext-left {
        right: 105%;
        top: -5px
    }
}

.load-more-wrap progress {
    border: 0;
    border-radius: 10px;
    height: 10px;
    width: 100px
}

.load-more-wrap progress::-webkit-progress-bar {
    background-color: #ecedef;
    border-radius: 50px;
    height: 10px
}

.load-more-wrap progress::-webkit-progress-value {
    background-color: #0157ff;
    border-radius: 50px
}

.load-more-wrap .show-more-button {
    border-color: #231f20;
    color: #231f20;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    margin-top: 10px;
    width: 280px
}

[v-cloak] {
    display: none !important
}

.loader {
    height: auto
}

@media (min-width:1199px) {
    .loader {
        height: 238px
    }
}

@media (max-width:320px) and (min-width:291px) {
    .loader {
        height: 435px
    }
}

@media (max-width:399px) and (min-width:321px) {
    .loader {
        height: 429px
    }
}

@media (max-width:599px) and (min-width:400px) {
    .loader {
        height: 354px
    }
}

@media (max-width:991px) and (min-width:600px) {
    .loader {
        height: 274px
    }
}

@media (max-width:1198px) and (min-width:992px) {
    .loader {
        height: 249px
    }
}

.operator-list ol,
.single-offer ol {
    color: #333;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 0
}

.operator-list ol li,
.single-offer ol li {
    list-style: none
}

.operator-list ol li.loader,
.single-offer ol li.loader {
    height: 150px !important
}

.operator-list .operator-item,
.operator-list .single-offer-item,
.single-offer .operator-item,
.single-offer .single-offer-item {
    background: #ececec;
    border-radius: 4px;
    list-style: none;
    margin-bottom: 10px;
    position: relative
}

.operator-list .operator-item:hover,
.operator-list .single-offer-item:hover,
.single-offer .operator-item:hover,
.single-offer .single-offer-item:hover {
    background: #e2e1e1
}

.operator-list .operator-item .operator-column-ranking,
.operator-list .single-offer-item .operator-column-ranking,
.single-offer .operator-item .operator-column-ranking,
.single-offer .single-offer-item .operator-column-ranking {
    background: #e2e1e1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #333;
    display: block;
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    min-width: auto;
    padding: 10px;
    text-align: left;
    width: auto
}

.operator-list .operator-item .operator-column-ranking.border-left,
.operator-list .operator-item .operator-column-ranking.empty,
.operator-list .single-offer-item .operator-column-ranking.border-left,
.operator-list .single-offer-item .operator-column-ranking.empty,
.single-offer .operator-item .operator-column-ranking.border-left,
.single-offer .operator-item .operator-column-ranking.empty,
.single-offer .single-offer-item .operator-column-ranking.border-left,
.single-offer .single-offer-item .operator-column-ranking.empty {
    display: none
}

@media (min-width:992px) {

    .operator-list .operator-item .operator-column-ranking.border-left,
    .operator-list .single-offer-item .operator-column-ranking.border-left,
    .single-offer .operator-item .operator-column-ranking.border-left,
    .single-offer .single-offer-item .operator-column-ranking.border-left {
        display: table-cell
    }
}

.operator-list .operator-item .operator-column-logo,
.operator-list .single-offer-item .operator-column-logo,
.single-offer .operator-item .operator-column-logo,
.single-offer .single-offer-item .operator-column-logo {
    display: table;
    float: left;
    height: 108px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 23%
}

@media (max-width:599px) {

    .operator-list .operator-item .operator-column-logo,
    .operator-list .single-offer-item .operator-column-logo,
    .single-offer .operator-item .operator-column-logo,
    .single-offer .single-offer-item .operator-column-logo {
        width: 40%
    }
}

.operator-list .operator-item .operator-column-logo a,
.operator-list .single-offer-item .operator-column-logo a,
.single-offer .operator-item .operator-column-logo a,
.single-offer .single-offer-item .operator-column-logo a {
    color: #333;
    display: table-cell;
    padding: 0 10px;
    vertical-align: middle;
    width: 100%
}

.operator-list .operator-item .operator-column-logo img,
.operator-list .single-offer-item .operator-column-logo img,
.single-offer .operator-item .operator-column-logo img,
.single-offer .single-offer-item .operator-column-logo img {
    margin: 15px auto auto;
    max-width: 100%;
    width: auto
}

.operator-list .operator-item .operator-column-logo .operator-established-year,
.operator-list .single-offer-item .operator-column-logo .operator-established-year,
.single-offer .operator-item .operator-column-logo .operator-established-year,
.single-offer .single-offer-item .operator-column-logo .operator-established-year {
    bottom: -6px;
    color: #757575;
    font-size: 12px;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%
}

@media (min-width:600px) {

    .operator-list .operator-item .operator-column-logo .operator-established-year,
    .operator-list .single-offer-item .operator-column-logo .operator-established-year,
    .single-offer .operator-item .operator-column-logo .operator-established-year,
    .single-offer .single-offer-item .operator-column-logo .operator-established-year {
        bottom: -11px
    }
}

.operator-list .operator-item .operator-column-bonus,
.operator-list .single-offer-item .operator-column-bonus,
.single-offer .operator-item .operator-column-bonus,
.single-offer .single-offer-item .operator-column-bonus {
    display: table;
    float: left;
    height: 108px;
    width: 42%
}

@media (max-width:599px) {

    .operator-list .operator-item .operator-column-bonus,
    .operator-list .single-offer-item .operator-column-bonus,
    .single-offer .operator-item .operator-column-bonus,
    .single-offer .single-offer-item .operator-column-bonus {
        height: 100%;
        min-height: 108px;
        overflow-wrap: anywhere;
        width: 55%
    }
}

.operator-list .operator-item .operator-column-bonus div,
.operator-list .single-offer-item .operator-column-bonus div,
.single-offer .operator-item .operator-column-bonus div,
.single-offer .single-offer-item .operator-column-bonus div {
    height: 100%;
    width: 100%
}

.operator-list .operator-item .operator-column-bonus>div,
.operator-list .single-offer-item .operator-column-bonus>div,
.single-offer .operator-item .operator-column-bonus>div,
.single-offer .single-offer-item .operator-column-bonus>div {
    display: table;
    height: 100%;
    padding: 16px 0
}

.operator-list .operator-item .operator-column-bonus>div>div,
.operator-list .single-offer-item .operator-column-bonus>div>div,
.single-offer .operator-item .operator-column-bonus>div>div,
.single-offer .single-offer-item .operator-column-bonus>div>div {
    display: table-cell;
    vertical-align: middle
}

.operator-list .operator-item .operator-column-bonus p,
.operator-list .single-offer-item .operator-column-bonus p,
.single-offer .operator-item .operator-column-bonus p,
.single-offer .single-offer-item .operator-column-bonus p {
    display: block;
    margin: 0
}

.operator-list .operator-item .operator-column-bonus .operator-bonus,
.operator-list .single-offer-item .operator-column-bonus .operator-bonus,
.single-offer .operator-item .operator-column-bonus .operator-bonus,
.single-offer .single-offer-item .operator-column-bonus .operator-bonus {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.2;
    padding: 0;
    text-align: left
}

.operator-list .operator-item .operator-column-bonus .operator-bonus em,
.operator-list .single-offer-item .operator-column-bonus .operator-bonus em,
.single-offer .operator-item .operator-column-bonus .operator-bonus em,
.single-offer .single-offer-item .operator-column-bonus .operator-bonus em {
    color: #dc3545
}

.operator-list .operator-item .operator-column-bonus label,
.operator-list .single-offer-item .operator-column-bonus label,
.single-offer .operator-item .operator-column-bonus label,
.single-offer .single-offer-item .operator-column-bonus label {
    background: #000;
    border-radius: 25px;
    color: #fff;
    display: table;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 8px;
    padding: 1px 10px
}

.operator-list .operator-item .operator-rating-column,
.operator-list .single-offer-item .operator-rating-column,
.single-offer .operator-item .operator-rating-column,
.single-offer .single-offer-item .operator-rating-column {
    background: 0 0;
    font-size: 12px;
    left: 40px;
    padding: 12px 0 0;
    position: absolute;
    right: 0;
    text-align: left;
    text-align: right;
    top: 0;
    vertical-align: middle;
    width: auto;
    z-index: 0
}

@media (min-width:992px) {

    .operator-list .operator-item .operator-rating-column,
    .operator-list .single-offer-item .operator-rating-column,
    .single-offer .operator-item .operator-rating-column,
    .single-offer .single-offer-item .operator-rating-column {
        background: 0 0;
        display: table-cell;
        float: none;
        font-size: 12px;
        left: auto;
        padding: 12px 0 0;
        position: relative;
        right: 0;
        top: 0;
        width: 13%
    }
}

.operator-list .operator-item .operator-rating-column .review_abtest_variation,
.operator-list .single-offer-item .operator-rating-column .review_abtest_variation,
.single-offer .operator-item .operator-rating-column .review_abtest_variation,
.single-offer .single-offer-item .operator-rating-column .review_abtest_variation {
    display: none
}

.operator-list .operator-item .read-review-link,
.operator-list .single-offer-item .read-review-link,
.single-offer .operator-item .read-review-link,
.single-offer .single-offer-item .read-review-link {
    background-image: url(https://objects.kaxmedia.com/auto/o/139759/a2938e1de1.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 6px;
    color: #dc3545;
    display: inline-block;
    float: right;
    font-size: 12px;
    font-weight: 700;
    height: auto;
    margin-right: 1em;
    padding: 2px 10px 2px 0;
    text-align: right;
    top: 10px;
    width: auto;
    z-index: 997
}

.operator-list .operator-item .read-review-link:hover,
.operator-list .single-offer-item .read-review-link:hover,
.single-offer .operator-item .read-review-link:hover,
.single-offer .single-offer-item .read-review-link:hover {
    text-decoration: underline
}

.operator-list .operator-item .read-review-link span,
.operator-list .single-offer-item .read-review-link span,
.single-offer .operator-item .read-review-link span,
.single-offer .single-offer-item .read-review-link span {
    display: table-cell;
    vertical-align: middle
}

@media (max-width:991px) {

    .operator-list .operator-item .read-review-link br,
    .operator-list .single-offer-item .read-review-link br,
    .single-offer .operator-item .read-review-link br,
    .single-offer .single-offer-item .read-review-link br {
        display: none
    }
}

.operator-list .operator-item .read-review-link.review-link-v2,
.operator-list .single-offer-item .read-review-link.review-link-v2,
.single-offer .operator-item .read-review-link.review-link-v2,
.single-offer .single-offer-item .read-review-link.review-link-v2 {
    background-image: url(https://objects.kaxmedia.com/auto/o/139703/8a8d4cc06f.svg);
    color: #0157ff !important;
    font-weight: 400
}

.operator-list .operator-item .operator-playnow-column,
.operator-list .single-offer-item .operator-playnow-column,
.single-offer .operator-item .operator-playnow-column,
.single-offer .single-offer-item .operator-playnow-column {
    display: table;
    float: left;
    height: 108px;
    text-align: center;
    vertical-align: middle;
    width: 35%
}

@media (max-width:599px) {

    .operator-list .operator-item .operator-playnow-column,
    .operator-list .single-offer-item .operator-playnow-column,
    .single-offer .operator-item .operator-playnow-column,
    .single-offer .single-offer-item .operator-playnow-column {
        display: block;
        height: auto;
        padding: 10px 8px;
        width: 100%
    }
}

.operator-list .operator-item .operator-playnow-column a,
.operator-list .single-offer-item .operator-playnow-column a,
.single-offer .operator-item .operator-playnow-column a,
.single-offer .single-offer-item .operator-playnow-column a {
    display: table-cell;
    text-decoration: none;
    vertical-align: middle
}

@media (max-width:599px) {

    .operator-list .operator-item .operator-playnow-column a,
    .operator-list .single-offer-item .operator-playnow-column a,
    .single-offer .operator-item .operator-playnow-column a,
    .single-offer .single-offer-item .operator-playnow-column a {
        display: block;
        width: 100%
    }
}

.operator-list .operator-item .operator-playnow-column a:hover,
.operator-list .single-offer-item .operator-playnow-column a:hover,
.single-offer .operator-item .operator-playnow-column a:hover,
.single-offer .single-offer-item .operator-playnow-column a:hover {
    text-decoration: none
}

.operator-list .operator-item .operator-playnow-column a .btn-cta-play-now,
.operator-list .operator-item .operator-playnow-column a .button-yellow,
.operator-list .single-offer-item .operator-playnow-column a .btn-cta-play-now,
.operator-list .single-offer-item .operator-playnow-column a .button-yellow,
.single-offer .operator-item .operator-playnow-column a .btn-cta-play-now,
.single-offer .operator-item .operator-playnow-column a .button-yellow,
.single-offer .single-offer-item .operator-playnow-column a .btn-cta-play-now,
.single-offer .single-offer-item .operator-playnow-column a .button-yellow {
    margin: auto
}

.operator-list .operator-item .operator-playnow-column .operator-play-now,
.operator-list .single-offer-item .operator-playnow-column .operator-play-now,
.single-offer .operator-item .operator-playnow-column .operator-play-now,
.single-offer .single-offer-item .operator-playnow-column .operator-play-now {
    width: 90%
}

@media (max-width:599px) {

    .operator-list .operator-item .operator-playnow-column .operator-play-now,
    .operator-list .single-offer-item .operator-playnow-column .operator-play-now,
    .single-offer .operator-item .operator-playnow-column .operator-play-now,
    .single-offer .single-offer-item .operator-playnow-column .operator-play-now {
        width: 100%
    }
}

.operator-list .operator-item .read-terms-link,
.operator-list .single-offer-item .read-terms-link,
.single-offer .operator-item .read-terms-link,
.single-offer .single-offer-item .read-terms-link {
    border: 0;
    border-bottom-left-radius: 4px;
    clear: left;
    display: block;
    width: 100%
}

@media (min-width:992px) {

    .operator-list .operator-item .read-terms-link,
    .operator-list .single-offer-item .read-terms-link,
    .single-offer .operator-item .read-terms-link,
    .single-offer .single-offer-item .read-terms-link {
        border-left: 48px solid #e2e1e1
    }
}

@media (min-width:600px) {

    .operator-list .operator-item .read-terms-link,
    .operator-list .single-offer-item .read-terms-link,
    .single-offer .operator-item .read-terms-link,
    .single-offer .single-offer-item .read-terms-link {
        padding-top: 10px
    }
}

.operator-list .operator-item .terms-and-conditions,
.operator-list .single-offer-item .terms-and-conditions,
.single-offer .operator-item .terms-and-conditions,
.single-offer .single-offer-item .terms-and-conditions {
    display: block;
    padding: 4px 8px 8px;
    text-align: left
}

.operator-list .operator-item .terms-and-conditions a,
.operator-list .single-offer-item .terms-and-conditions a,
.single-offer .operator-item .terms-and-conditions a,
.single-offer .single-offer-item .terms-and-conditions a {
    color: #777
}

@media (min-width:992px) {
    #row-op-list-wrap .operator-column-ranking.not-compressed {
        display: table-cell;
        float: none;
        min-width: 48px;
        text-align: center;
        vertical-align: middle;
        width: 48px
    }

    #row-op-list-wrap .operator-column-ranking .nav-flag.not-compressed {
        left: 14px;
        position: absolute;
        text-align: center;
        top: 107px
    }

    #row-op-list-wrap .operator-column-logo.not-compressed {
        display: table-cell;
        float: none;
        height: 108px;
        width: 16%
    }

    #row-op-list-wrap .operator-column-bonus.not-compressed {
        display: table-cell;
        float: none;
        font-size: 12px;
        width: 38%
    }

    #row-op-list-wrap .read-review-link.not-compressed {
        display: table;
        height: 33px;
        padding: 2px 10px 2px 0;
        position: relative;
        right: auto;
        text-align: center;
        top: auto;
        width: 100%;
        z-index: 0
    }

    #row-op-list-wrap .operator-playnow-column.not-compressed {
        display: table-cell;
        float: none;
        width: 29%
    }

    #row-op-list-wrap .operator-playnow-column.not-compressed a {
        display: block
    }

    #op-list-wrap .operator-column-ranking,
    .not-compressed .operator-item .operator-column-ranking,
    .single-offer .single-offer-item .operator-column-ranking {
        display: table-cell;
        float: none;
        min-width: 48px;
        text-align: center;
        vertical-align: middle;
        width: 48px
    }

    #op-list-wrap .operator-column-logo,
    .not-compressed .operator-item .operator-column-logo,
    .single-offer .single-offer-item .operator-column-logo {
        display: table-cell;
        float: none;
        height: 108px;
        width: 16%
    }

    #op-list-wrap .operator-column-bonus,
    .not-compressed .operator-item .operator-column-bonus,
    .single-offer .single-offer-item .operator-column-bonus {
        display: table-cell;
        float: none;
        font-size: 12px;
        width: 38%
    }

    #op-list-wrap .operator-playnow-column,
    .not-compressed .operator-item .operator-playnow-column,
    .single-offer .single-offer-item .operator-playnow-column {
        display: table-cell;
        float: none;
        width: 29%
    }

    #op-list-wrap .operator-playnow-column a,
    .not-compressed .operator-item .operator-playnow-column a,
    .single-offer .single-offer-item .operator-playnow-column a {
        display: block
    }
}

.oplist-top-bar {
    font-size: 12px;
    line-height: 21px
}

.oplist-top-bar .nav-flag {
    height: 20px;
    margin: 0;
    position: relative;
    width: 20px
}

.oplist-top-bar .blue-text {
    color: #0147ff
}

.oplist-top-bar .blue-text:hover {
    text-decoration: none
}

.oplist-top-bar .location-icon {
    fill: #0af;
    margin-top: -2px;
    width: 10px
}

.op-list-row .nav-flag {
    height: 20px;
    margin: 0;
    position: relative;
    width: 20px
}

.op-list-row .no-country-offers {
    background: #e3f3fc;
    border: 1px solid #39bcfe;
    color: #39bcfe;
    font-size: 11px;
    position: relative
}

.op-list-row .close-button {
    color: #0af
}

.op-list-row .operator-column-ranking {
    vertical-align: top !important
}

.op-list-row .operator-column-ranking .nav-flag {
    display: block
}

@media (max-width:991px) {
    .op-list-row .operator-column-ranking .nav-flag {
        display: none
    }
}

@media (min-width:992px) {
    .op-list-row .operator-column-ranking .nav-flag {
        left: 14px;
        position: absolute;
        text-align: center;
        top: 107px
    }
}

.op-list-row .operator-rating-column .nav-flag {
    display: none
}

@media (max-width:991px) {
    .op-list-row .operator-rating-column .nav-flag {
        display: inline-block;
        margin-right: 10px
    }
}

.badges_trust {
    display: block
}

@media (max-width:768px) {
    .badges_trust {
        display: block;
        float: none;
        padding-bottom: 5px;
        position: relative;
        text-align: left;
        text-align: center;
        width: 100%
    }

    .badges_trust .badges_trust {
        float: none;
        text-align: center !important
    }
}

.badges_trust img {
    display: inline-block;
    height: 18px;
    margin: 0;
    vertical-align: baseline;
    width: auto
}

.badges_trust span {
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 700;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 12px;
    margin: 0 20px 0 0;
    text-transform: uppercase
}

.badges_trust span:last-child {
    margin: 0
}

@media (max-width:559px) {
    .badges_trust span {
        margin: 0 3px 0 0
    }
}

.dge-seal p {
    color: #999;
    font-size: 14px !important;
    line-height: 24px !important
}

.filterable-op-list {
    position: relative
}

.filterable-op-list .filter-container {
    -ms-overflow-style: none;
    display: inline-block;
    font-size: 16px;
    margin: 8px 0 5px;
    overflow-x: auto;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%
}

.filterable-op-list .filter-container::-webkit-scrollbar {
    display: none
}

.filterable-op-list .filter-container .sort-by-text {
    color: #333;
    display: inline-block;
    margin-right: 8px
}

.filterable-op-list .filter-container+.tac_ad_text {
    margin-top: 24px
}

.filterable-op-list .tabOplist,
.filterable-op-list.new-op-list {
    display: none
}

#back-to-top {
    padding: 40px 0 20px;
    text-align: center
}

#back-to-top.fixed {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0) 10%, hsla(0, 0%, 100%, .5) 20%, #fff 40%);
    bottom: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 100
}

.article-main-content .single-offer .single-offer-item .operator-column-ranking,
.review .single-offer .single-offer-item .operator-column-ranking {
    display: block;
    height: 30px;
    width: 100%
}

.article-main-content .single-offer .single-offer-item .operator-rating-column,
.review .single-offer .single-offer-item .operator-rating-column {
    padding: 5px
}

@media (min-width:992px) {

    .article-main-content .single-offer .single-offer-item .operator-rating-column,
    .review .single-offer .single-offer-item .operator-rating-column {
        background-color: #e2e1e1;
        display: block;
        left: 0;
        position: absolute;
        width: 100%
    }

    .article-main-content .single-offer .single-offer-item .operator-column-logo,
    .review .single-offer .single-offer-item .operator-column-logo {
        display: table;
        float: left;
        width: 23%
    }

    .article-main-content .single-offer .single-offer-item .operator-column-bonus,
    .review .single-offer .single-offer-item .operator-column-bonus {
        display: table;
        float: left;
        width: 42%
    }

    .article-main-content .single-offer .single-offer-item .operator-playnow-column,
    .review .single-offer .single-offer-item .operator-playnow-column {
        display: table;
        height: 108px;
        width: 35%
    }

    .article-main-content .single-offer .single-offer-item .operator-playnow-column a,
    .review .single-offer .single-offer-item .operator-playnow-column a {
        display: table-cell
    }

    .article-main-content .single-offer .single-offer-item .read-terms-link,
    .review .single-offer .single-offer-item .read-terms-link {
        border-left: 0
    }
}

.row-small-op-list li.operator-item .header,
.small-op-list li.operator-item .header {
    background-color: #e2e1e1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.row-small-op-list .operator-list ol,
.small-op-list .operator-list ol {
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0
}

.row-small-op-list .operator-list .operator-ranking-number,
.small-op-list .operator-list .operator-ranking-number {
    font-size: 20px;
    padding-left: 2px
}

.row-small-op-list .operator-list .operator-rating-column,
.small-op-list .operator-list .operator-rating-column {
    background: 0 0;
    font-size: 12px;
    left: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto
}

.row-small-op-list .operator-list .read-terms-link,
.small-op-list .operator-list .read-terms-link {
    border-left: 0
}

.row-small-op-list .operator-column-bonus .operator-bonus,
.small-op-list .operator-column-bonus .operator-bonus {
    text-align: center
}

.row-small-op-list .termsLine,
.small-op-list .termsLine {
    border: 0
}

.row-small-op-list .read-terms-link,
.small-op-list .read-terms-link {
    border: 0;
    clear: left;
    display: block;
    width: 100%
}

.small-op-list .operator-list .read-review-link {
    background-image: url(https://objects.kaxmedia.com/auto/o/139759/a2938e1de1.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 6px;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: auto;
    padding: 2px 10px 2px 0;
    position: absolute;
    right: 1em;
    text-align: right;
    top: 4px;
    width: auto;
    z-index: 997
}

.state-not-available {
    border-radius: 5px;
    -moz-border-radius: 5px;
    font-weight: 400
}

.state-not-available button.subscribe {
    margin: 0;
    width: 100%
}

.state-not-available button.subscribe.button-blue {
    margin: auto
}

.state-not-available .select-wrap {
    margin: auto;
    width: 90%
}

.state-not-available .select-wrap select {
    float: left;
    height: 50px;
    max-width: 327px
}

.state-not-available .select-wrap .map-marker:hover {
    cursor: pointer
}

.state-not-available p {
    color: #fff;
    font-weight: 400
}

.oplist-slots {
    background: #fff
}

.oplist-slots ol li {
    border-radius: 0 0 4px 4px !important
}

.oplist-slots ol li.loader {
    height: 135px !important
}

.oplist-slots .operator-column-ranking,
.oplist-slots .operator-rating-column {
    display: none !important
}

.oplist-slots .operator-column-logo {
    float: left !important
}

.oplist-slots .operator-column-bonus {
    float: left !important;
    width: 52% !important
}

@media (min-width:992px) {
    .oplist-slots .operator-column-bonus {
        width: 55% !important
    }
}

.oplist-slots .operator-playnow-column {
    align-items: center !important;
    display: flex !important;
    float: left !important;
    width: 25% !important
}

@media (max-width:599px) {
    .oplist-slots .operator-playnow-column {
        width: 100% !important
    }
}

.oplist-slots .operator-playnow-column a {
    width: 100% !important
}

.oplist-slots .read-terms-link {
    border: 0 !important
}

.oplist-slots .read-terms-link .terms-and-conditions {
    padding: 5px 15px 10px !important
}

.oplist-slots .termsLine {
    border-left: 0
}

.oplist-slots .oplist-top-bar .float-left,
.oplist-slots .oplist-top-bar .float-right {
    padding: 0 !important
}

.enhanced-odds-operator-list {
    border: 1px solid #ececec;
    border-radius: 6px
}

.enhanced-odds-operator-list li {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 1px solid #ececec
}

.enhanced-odds-operator-list li .offer-content,
.enhanced-odds-operator-list li .offer-heading {
    padding: 0 15px;
    text-align: center;
    width: 33.33%
}

@media (min-width:600px) and (max-width:991px) {

    .enhanced-odds-operator-list li .offer-content,
    .enhanced-odds-operator-list li .offer-heading {
        width: 25%
    }
}

@media (min-width:992px) {

    .enhanced-odds-operator-list li .offer-content,
    .enhanced-odds-operator-list li .offer-heading {
        width: 20%
    }
}

.enhanced-odds-operator-list li .list-item-header {
    background-color: #ececec;
    color: #333
}

.enhanced-odds-operator-list li .list-item-header .offer-heading {
    font-size: 18px
}

.enhanced-odds-operator-list li .list-item-header .offer-heading.offer-img {
    display: none
}

@media (min-width:600px) {
    .enhanced-odds-operator-list li .list-item-header .offer-heading.offer-img {
        display: inline-block
    }
}

.enhanced-odds-operator-list li .list-item-header .offer-heading.offer-cta {
    display: none
}

@media (min-width:992px) {
    .enhanced-odds-operator-list li .list-item-header .offer-heading.offer-cta {
        display: inline-block
    }
}

.enhanced-odds-operator-list li .list-item-content .offer-img {
    order: 1
}

@media (max-width:599px) {
    .enhanced-odds-operator-list li .list-item-content .offer-img {
        display: flex;
        order: 5;
        width: 100%
    }
}

.enhanced-odds-operator-list li .list-item-content .offer-expiry {
    order: 2
}

.enhanced-odds-operator-list li .list-item-content .offer-text {
    order: 3
}

.enhanced-odds-operator-list li .list-item-content .offer-odds {
    color: #dc3545;
    font-size: 22px;
    order: 4
}

.enhanced-odds-operator-list li .list-item-content .offer-cta {
    order: 6
}

@media (max-width:991px) {
    .enhanced-odds-operator-list li .list-item-content .offer-cta {
        display: flex;
        width: 100%
    }
}

.enhanced-odds-operator-list li .list-item-content .offer-cta .btn-cta-play-now,
.enhanced-odds-operator-list li .list-item-content .offer-cta .button-yellow {
    width: 100%
}

@media (min-width:600px) and (max-width:991px) {

    .enhanced-odds-operator-list li .list-item-content .offer-cta .btn-cta-play-now,
    .enhanced-odds-operator-list li .list-item-content .offer-cta .button-yellow {
        width: 50%
    }
}

.enhanced-odds-operator-list li .list-item-terms {
    font-size: 12px;
    line-height: 12px
}

.enhanced-odds-operator-list li .list-item-no-results {
    font-size: 16px
}

.loading-spinner-container {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 200px
}

.loading-spinner-container .loading-spinner {
    display: inline-block;
    height: 80px;
    width: 80px
}

.loading-spinner-container .loading-spinner:after {
    animation: loading-spinner 1.2s linear infinite;
    border-color: #dc3545 transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    content: " ";
    display: block;
    height: 64px;
    margin: 8px;
    width: 64px
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.no-top-offer {
    background: #e2e1e1;
    border-radius: 4px;
    font-weight: 600
}

.article-main-content #op-list-wrap .operator-list ol .operator-item,
.operator-list-small #op-list-wrap .operator-list ol .operator-item {
    width: 100%
}

.article-main-content #op-list-wrap .operator-list ol .operator-item .operator-column-ranking,
.operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-column-ranking {
    display: block;
    font-weight: unset;
    height: 30px;
    padding: 5px;
    text-align: left;
    width: 100%
}

.article-main-content #op-list-wrap .operator-list ol .operator-item .operator-rating-column,
.operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-rating-column {
    padding: 5px
}

@media (min-width:992px) {

    .article-main-content #op-list-wrap .operator-list ol .operator-item .operator-rating-column,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-rating-column {
        display: block;
        left: 0;
        position: absolute;
        width: 100%
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .operator-column-logo,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-column-logo {
        display: table;
        float: left;
        width: 23%
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .operator-column-bonus,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-column-bonus {
        display: table;
        float: left;
        width: 42%
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .operator-playnow-column,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-playnow-column {
        display: table;
        height: 108px;
        width: 35%
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .operator-playnow-column a,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .operator-playnow-column a {
        display: table-cell
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .read-terms-link,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .read-terms-link {
        border-left: 0
    }

    .article-main-content #op-list-wrap .operator-list ol .operator-item .terms-and-conditions,
    .operator-list-small #op-list-wrap .operator-list ol .operator-item .terms-and-conditions {
        text-align: left !important
    }
}

.text-advertiser-tooltip {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    border-bottom: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
    color: #000;
    font-style: normal;
    left: 0;
    margin-top: 5px;
    padding: 10px 15px;
    position: absolute;
    text-shadow: none;
    visibility: hidden;
    z-index: 100
}

#disclaimer {
    cursor: pointer
}

.bell-icon {
    display: inline-block;
    width: 10px
}

.tac_ad_text {
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    text-align: right
}

.tac_ad_text>div {
    cursor: pointer;
    display: inline-block
}

.tac_ad_text>div.hide-mobile:hover {
    color: #b0001b
}

.tac_ad_text>div.hide-mobile:hover .text-advertiser-tooltip {
    visibility: visible
}

.tac_ad_text img {
    display: inline-block;
    margin: 0 0 0 5px;
    width: 10px
}

.template-homepage-generic .tac_ad_text,
.us-homepage .tac_ad_text {
    text-align: center
}

.terms-and-conditions,
.termsLine {
    color: #777;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px
}

.termsLine {
    border-bottom-left-radius: 4px;
    border-left: 48px solid #e2e1e1;
    border-right: 48px solid transparent
}

.termsLine .termsLink {
    color: #777;
    cursor: pointer;
    line-height: 2;
    padding: .5em 1em;
    position: relative;
    text-align: center;
    text-decoration: underline;
    z-index: auto
}

.termsLine .hiddenTerms {
    background-color: #f3f3f3;
    border-radius: 6px;
    box-shadow: 10px 10px 15px grey;
    color: #717171;
    display: block;
    font-style: normal;
    left: 50%;
    margin-left: -140px;
    opacity: 0;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 24px;
    transition: opacity 1s ease-in-out;
    visibility: hidden;
    width: 280px;
    z-index: 999
}

.termsLine .hiddenTerms.active {
    display: inline-block;
    opacity: 1;
    visibility: visible
}

.termsLine .hiddenTerms .close {
    background-color: #f3f3f3;
    border: none;
    color: #333;
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    height: 10px;
    line-height: 10px;
    margin: 7px;
    padding: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
    width: 20px;
    z-index: 1999
}

.termsLine .hiddenTerms .terms-and-conditions {
    padding: 4px 15px 8px
}

.termsLine .hiddenTerms .operator-play-now {
    border: 2px solid transparent;
    text-transform: uppercase;
    width: 90%
}

.terms-event-header .termsLink {
    display: block;
    text-align: left
}

.terms-event-header .hiddenTerms {
    background-color: #fff;
    left: 0;
    margin-left: 0;
    padding: 10px 30px 10px 10px;
    text-align: left;
    width: 100%
}

.terms-event-header .hiddenTerms .terms-and-conditions {
    padding: 0
}

.terms-event-header .hiddenTerms .terms-and-conditions a {
    color: #777
}

@media (max-device-width:1024px) {
    .termsLine {
        clear: both;
        padding: 8px 4.5%
    }

    .terms-and-conditions-long,
    .termsLine {
        border-bottom-left-radius: 0 !important;
        border-left: 0 solid #e2e1e1;
        border-right: 0 solid transparent;
        text-align: center !important
    }

    .termsLink {
        display: block;
        padding: 0 1em .5em
    }

    .terms-event-header .termsLink,
    .terms-event-header.termsLine {
        padding: 0
    }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
    .termsLine {
        padding: 0 4.5%
    }

    .termsLink {
        padding: 0
    }
}

@media only screen and (min-device-width:991px) and (max-device-width:1024px) {
    .termsLine {
        border-left: 48px solid #e2e1e1;
        border-right: 48px solid transparent
    }
}

@media (max-width:767px) {
    .tac_ad_text .hide-mobile {
        display: none
    }
}

@media (min-width:768px) {
    .tac_ad_text .hide-desktop {
        display: none
    }
}

@media (max-width:991px) {
    .tac_ad_text>div {
        display: block
    }
}

.state-selector.modal {
    padding: 8px
}

@media (min-width:480px) {
    .state-selector.modal {
        overflow: inherit;
        padding: 0;
        position: absolute
    }
}

.state-selector.modal .close {
    color: #333;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
    opacity: 1;
    padding: 0 25px 0 0;
    text-shadow: none;
    width: auto
}

.state-selector.modal .close span {
    font-size: 35px;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0
}

.state-selector.modal .modal-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    right: 0;
    width: 100%
}

@media (min-width:480px) {
    .state-selector.modal .modal-container {
        margin: auto;
        position: absolute;
        top: 27px;
        width: 454px
    }
}

.state-selector.modal .modal-container .modal-dialog {
    margin: 0
}

@media (min-width:480px) {
    .state-selector.modal .modal-container .modal-dialog {
        width: 100% !important
    }
}

.state-selector.modal .modal-container .select2-container {
    display: block !important;
    left: auto !important;
    position: relative !important;
    top: auto !important
}

.state-selector.modal .modal-container .grey-background {
    background-color: #e7e7e7;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 1;
    padding: 10px
}

.state-selector.modal .modal-container .selector-container {
    background-color: #f2f2f2;
    border-radius: 12px;
    box-sizing: border-box;
    opacity: 1;
    padding: 10px
}

.state-selector.modal .modal-container .select2-container--default .select2-selection--single {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    box-shadow: none;
    outline: none
}

.state-selector.modal .modal-container .select2-container--default .select2-selection--single:focus {
    border: 1px solid #979797;
    border-radius: 8px
}

.state-selector.modal .modal-container .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: 18px;
    line-height: 24px !important;
    padding: 12px 10px 12px 20px;
    text-align: left
}

.state-selector.modal .modal-container .select2-container--default .select2-selection--single .select2-selection__rendered img {
    position: absolute;
    width: 24px
}

.state-selector.modal .modal-container .select2-container--default .select2-selection--single .select2-selection__rendered span {
    display: block;
    padding: 0 0 0 40px
}

.state-selector.modal .modal-container .select2-container--default .select2-results__option {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    padding: 10px 10px 10px 20px;
    position: relative;
    text-align: left
}

.state-selector.modal .modal-container .select2-container--default .select2-results__option .img-flag {
    position: absolute;
    width: 24px
}

.state-selector.modal .modal-container .select2-container--default .select2-results__option span {
    display: block;
    padding: 0 0 0 40px
}

.state-selector.modal .modal-container .select2-container--default .select2-search--dropdown {
    padding: 0
}

.state-selector.modal .modal-container .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: #f2f2f2;
    background: url(https://objects.kaxmedia.com/auto/o/51706/e6bc26e371.svg) no-repeat 20px 15px;
    background-size: 18px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    font-size: 18px;
    line-height: 50px !important;
    outline: none;
    padding: 0 10px 0 60px
}

.state-selector.modal .modal-container .select2-container--default .select2-search--dropdown .select2-search__field:focus {
    border: 1px solid #979797;
    border-radius: 8px
}

.state-selector.modal .modal-container .select2-container--default .select2-results>.select2-results__options {
    margin-bottom: 0
}

.state-selector.modal .modal-container .select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar {
    width: 10px
}

.state-selector.modal .modal-container .select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-track {
    background: #f2f2f2
}

.state-selector.modal .modal-container .select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
    background-color: #868686;
    border-radius: 5px
}

.state-selector.modal .modal-container .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #d8d8d8;
    color: #3d3d3d
}

.state-selector.modal .modal-container .select2-dropdown {
    background: 0 0;
    border: 0;
    box-shadow: none;
    position: relative
}

.state-selector.modal .modal-container .select2-results,
.state-selector.modal .modal-container .select2-search {
    background-color: #f2f2f2;
    border-radius: 8px;
    margin-top: 10px
}

.state-selector.modal .modal-container .select2-results {
    padding: 10px 0
}

.state-selector.modal .update-state {
    background: #fff;
    border: 2px solid #000;
    border-radius: 8px;
    color: #333;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 50px;
    margin-top: 10px;
    text-align: center;
    width: 100%
}

.state-selector.modal .update-state:hover {
    background-color: #333;
    border: 2px solid #000;
    color: #fff
}

#row-op-list-wrap .state-selector {
    background: hsla(0, 2%, 88%, .8);
    border-radius: 5px;
    font-size: 18px;
    padding: 10px 0
}

.state-selector-tooltip .oplitstTooltip {
    display: inline-block;
    margin-left: 0
}

.state-selector-tooltip .oplitstTooltip,
.state-selector-tooltip .oplitstTooltipSmall {
    background-color: #ececec;
    border: 1px solid #d8d8d8;
    border-radius: 14px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 4px;
    text-transform: none
}

.state-selector-tooltip .oplitstTooltipSmall {
    margin-bottom: 10px
}

.state-selector-tooltip .oplitstTooltipDisable {
    cursor: pointer;
    display: inline-block;
    margin: 4px 10px 3px 5px
}

.state-selector-tooltip .cityName {
    text-transform: uppercase
}

.state-selector-tooltip .closeTooltip {
    cursor: pointer;
    margin-bottom: 2px
}

.state-selector-tooltip .localName {
    line-height: 30px
}

.state-selector-tooltip .tooltipInfo {
    fill: #333
}

.state-selector-tooltip .tooltipInfoDisable {
    fill: #999
}

.state-selector-tooltip .tooltipInfoDisable:hover {
    fill: #333
}

.state-selector-tooltip .moreInfo {
    color: #05659f;
    font-weight: 700
}

.box-shadow:active,
.box-shadow:hover {
    box-shadow: 0 0 20px 1px #b5b5b5;
    transition: .4s
}

@media screen and (max-width:480px) {
    .select2-container {
        min-width: auto;
        width: 100% !important
    }
}

.select2-container .select2-selection--single {
    border: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    height: 50px !important
}

.select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 16px;
    line-height: 50px !important
}

.select2-container .select2-selection--single .select2-selection__arrow {
    height: 50px !important;
    top: 0
}

.select2-container .select2-dropdown {
    z-index: 999
}

.select2-container .select2-dropdown--below {
    border: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24)
}

.select2-container .select2-search--dropdown .select2-search__field {
    font-size: 20px
}

.select2-container .select2-results__option {
    font-size: 12px
}

.select2-container:hover {
    cursor: pointer
}

select.select-box-large {
    border: 0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .24);
    font-size: 1em;
    min-height: 50px;
    min-width: 255px;
    outline: 0;
    padding: 10px 5px;
    width: 100%
}

.popup-offer .button-close {
    background-color: inherit;
    border: none !important;
    padding: 10px;
    position: absolute;
    right: 7px;
    top: 8px;
    z-index: 2
}

.popup-offer .close-popup-cta {
    fill: #fff !important;
    background-color: #000 !important;
    border: 1px solid #fff !important;
    border-radius: 100% !important;
    display: block;
    height: 21px;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    min-width: auto !important;
    padding: 3px !important;
    width: 21px
}

.popup-offer .continue-browsing {
    color: #007bff;
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none
}

.popup-offer .continue-browsing:hover {
    cursor: pointer;
    text-decoration: underline
}

.popup-offer .modal {
    padding-right: 0 !important
}

.popup-offer .modal-content {
    background: #000;
    border: 1px solid #0157ff;
    border-radius: 10px;
    color: #fff;
    margin: auto;
    max-width: 420px
}

.popup-offer .modal-content p.operator-bonus.operator-bonus-popup {
    color: #fff !important
}

.popup-offer .modal-header-content {
    background-image: url(https://objects.kaxmedia.com/auto/o/156882/59a83ad496.gif);
    background-size: cover;
    border-radius: 6px;
    padding-bottom: 20px;
    padding-top: 50px
}

.popup-offer .operator-list .operator-item,
.popup-offer .operator-list .operator-item:hover,
.popup-offer .operator-list .single-offer-item,
.popup-offer .operator-list .single-offer-item:hover,
.popup-offer .single-offer .operator-item,
.popup-offer .single-offer .operator-item:hover,
.popup-offer .single-offer .single-offer-item,
.popup-offer .single-offer .single-offer-item:hover {
    background: unset
}

.popup-offer .read-terms-link {
    border: 0 !important
}

#offer-exit-pop,
#offer-exit-pop-variation {
    padding-right: 0 !important
}

@media (min-width:768px) {

    #offer-exit-pop .modal-dialog,
    #offer-exit-pop-variation .modal-dialog {
        margin: auto;
        max-width: 768px;
        width: 768px
    }
}

@media (max-width:767px) {

    #offer-exit-pop .modal-dialog,
    #offer-exit-pop-variation .modal-dialog {
        max-width: 600px
    }
}

#offer-exit-pop .popup-logo,
#offer-exit-pop-variation .popup-logo {
    background: hsla(0, 0%, 100%, .8);
    border-radius: 10px;
    margin: 0 auto;
    max-width: 250px;
    padding: 15px
}

#offer-exit-pop .popup-logo img,
#offer-exit-pop-variation .popup-logo img {
    margin: auto
}

#offer-exit-pop .popup-2nd-col,
#offer-exit-pop-variation .popup-2nd-col {
    padding: 0
}

#offer-exit-pop .popup-bonus,
#offer-exit-pop-variation .popup-bonus {
    display: table;
    float: none;
    height: auto;
    width: 100%
}

@media (max-width:767px) {

    #offer-exit-pop .popup-bonus,
    #offer-exit-pop-variation .popup-bonus {
        min-height: 0 !important
    }
}

#offer-exit-pop .popup-bonus label,
#offer-exit-pop-variation .popup-bonus label {
    margin: auto auto 10px
}

#offer-exit-pop .operator-bonus-popup,
#offer-exit-pop-variation .operator-bonus-popup {
    padding: 0 15px
}

@media (min-width:768px) {

    #offer-exit-pop .operator-bonus-popup,
    #offer-exit-pop-variation .operator-bonus-popup {
        font-size: 24px
    }
}

#offer-exit-pop .operator-bonus-popup em,
#offer-exit-pop-variation .operator-bonus-popup em {
    font-style: normal !important
}

#offer-exit-pop .terms-and-conditions,
#offer-exit-pop-variation .terms-and-conditions {
    color: #fff;
    line-height: 16px;
    margin: 0 20px 15px;
    padding: 7px;
    text-align: center
}

#offer-exit-pop .operator-playnow-column,
#offer-exit-pop-variation .operator-playnow-column {
    max-width: 90% !important
}

#offer-exit-pop .btn-cta-play-now,
#offer-exit-pop .button-blue,
#offer-exit-pop-variation .btn-cta-play-now,
#offer-exit-pop-variation .button-blue {
    color: #000 !important
}

#popUpEmail form {
    text-align: center
}

#popUpEmail .subscription-text-container.col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%
}

#popUpEmail .subscription-content h3 {
    background-image: url(https://objects.kaxmedia.com/auto/o/156882/59a83ad496.gif);
    background-size: cover;
    border-radius: 6px;
    font-size: 24px;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 35px;
    padding-bottom: 10px !important;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 40px !important
}

@media (max-width:500px) {
    #popUpEmail .subscription-content h3 {
        padding-left: 10px;
        padding-right: 10px
    }
}

#popUpEmail .subscription-text {
    color: #fff !important;
    font-size: 16px;
    font-style: normal !important;
    letter-spacing: 0;
    line-height: 27px;
    margin: 10px auto 0;
    max-width: 300px;
    text-align: center
}

#popUpEmail .agree-text {
    margin: 12px auto 0 !important
}

#popUpEmail .agree-message {
    color: #fff !important;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 20px
}

#popUpEmail .fake-checkbox {
    background-color: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    box-sizing: border-box;
    height: 23px;
    width: 21.87px
}

#popUpEmail .button-blue {
    background: #0157ff !important;
    border: 0 !important;
    border-radius: 4px !important;
    color: #000 !important;
    display: block !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 50px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important
}

#popUpEmail .button-blue.col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%
}

#popUpEmail .form-input-material {
    border-radius: 4px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%
}

#popUpEmail .form-input-material .label {
    color: rgba(0, 0, 0, .5);
    font-size: 16px;
    font-weight: 500;
    left: 12px;
    pointer-events: none;
    position: absolute;
    top: 20px;
    transform: translateZ(0);
    transform-origin: 0 0;
    transition: all .2s ease
}

#popUpEmail .form-input-material .focus-bg {
    background: rgba(0, 0, 0, .05);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scaleX(0);
    transform-origin: left;
    width: 100%;
    z-index: -1
}

#popUpEmail .form-input-material input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(0, 0, 0, .02);
    border: 0;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .3);
    color: #000;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    height: 56px;
    max-width: 315px;
    padding: 16px 12px 0;
    text-align: center;
    transition: all .15s ease;
    width: 100%
}

#popUpEmail .form-input-material input:hover {
    background: rgba(0, 0, 0, .04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .5)
}

#popUpEmail .form-input-material input:not(:-moz-placeholder-shown)+.label {
    color: rgba(0, 0, 0, .5);
    transform: translate3d(0, -12px, 0) scale(.75)
}

#popUpEmail .form-input-material input:not(:-ms-input-placeholder)+.label {
    color: rgba(0, 0, 0, .5);
    transform: translate3d(0, -12px, 0) scale(.75)
}

#popUpEmail .form-input-material input:not(:placeholder-shown)+.label {
    color: rgba(0, 0, 0, .5);
    transform: translate3d(0, -12px, 0) scale(.75)
}

#popUpEmail .form-input-material input:focus {
    background: rgba(0, 0, 0, .05);
    box-shadow: inset 0 -2px 0 #0157ff;
    outline: none
}

#popUpEmail .form-input-material input:focus+.label {
    color: #0157ff;
    transform: translate3d(0, -12px, 0) scale(.75)
}

#popUpEmail .form-input-material input:focus+.label+.focus-bg {
    transform: scaleX(1);
    transition: all .1s ease
}

#popUpEmail .form-input-material input::-moz-placeholder {
    color: #757575;
    text-align: center
}

#popUpEmail .form-input-material input:-ms-input-placeholder {
    color: #757575;
    text-align: center
}

#popUpEmail .form-input-material input::placeholder {
    color: #757575;
    text-align: center
}

#popUpEmail .form-input-material input:focus::-moz-placeholder {
    color: transparent !important
}

#popUpEmail .form-input-material input:focus:-ms-input-placeholder {
    color: transparent !important
}

#popUpEmail .form-input-material input:focus::placeholder {
    color: transparent !important
}

#popUpEmail #email-form-region-name {
    font-weight: 700;
    white-space: nowrap
}

#popUpEmail .subscription-response {
    background-image: url(https://objects.kaxmedia.com/auto/o/156882/59a83ad496.gif);
    background-size: cover;
    padding-bottom: 10px !important;
    padding-top: 40px !important
}

#popUpEmail .subscription-response p {
    color: #fff !important
}

#popUpEmail .continue-browsing {
    margin-bottom: 20px;
    margin-top: 0
}

#anchor-menu-heading {
    background-color: #333;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px
}

.anchor-menu-content {
    border: 1px solid #ecedef
}

.anchor-menu-content a {
    color: #dc3545;
    font-size: 18px;
    font-weight: 400
}

.anchor-menu-content a .emoji {
    padding-right: 15px
}

#anchor-menu-sidebar-container {
    display: none
}

@media (min-width:992px) {
    #anchor-menu-sidebar-container {
        display: block;
        margin-bottom: 0;
        padding-bottom: 10px
    }
}

#anchor-menu-sidebar-mobile-container {
    display: none
}

@media (max-width:991px) {
    #anchor-menu-sidebar-mobile-container {
        display: block
    }
}

#anchor-menu-sidebar-mobile-container #anchor-menu-sidebar-container,
.anchor-link {
    display: block
}

.anchor-link {
    position: relative;
    top: -80px;
    visibility: hidden
}

#anchor-menu-container .card {
    border-radius: .25rem !important
}

.body_content ol.references-list,
.body_content ol.two-columns,
.container ol.references-list,
.container ol.two-columns {
    column-count: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    font-size: 12px;
    margin: 0;
    padding: 0
}

@media (max-width:767px) {

    .body_content ol.references-list,
    .body_content ol.two-columns,
    .container ol.references-list,
    .container ol.two-columns {
        -moz-column-count: 1;
        column-count: 1
    }
}

.body_content ol.references-list li,
.body_content ol.two-columns li,
.container ol.references-list li,
.container ol.two-columns li {
    font-size: inherit;
    margin-left: 15px;
    padding: 5px 5px 5px 0
}

.body_content ol.references-list a,
.body_content ol.two-columns a,
.container ol.references-list a,
.container ol.two-columns a {
    color: #dc3545
}

.body_content ol.references-list a:hover,
.body_content ol.two-columns a:hover,
.container ol.references-list a:hover,
.container ol.two-columns a:hover {
    color: #dc3545;
    text-decoration: underline
}

.body_content ol.references-list a.operator-item-link:hover,
.body_content ol.references-list a:focus,
.body_content ol.two-columns a.operator-item-link:hover,
.body_content ol.two-columns a:focus,
.container ol.references-list a.operator-item-link:hover,
.container ol.references-list a:focus,
.container ol.two-columns a.operator-item-link:hover,
.container ol.two-columns a:focus {
    text-decoration: none
}

.cc-responsive-wrap {
    overflow-y: hidden
}

ol.cc-list-with-thumbnail,
ul.cc-list-with-thumbnail {
    margin: 0;
    padding: 0
}

ol.cc-list-with-thumbnail li,
ul.cc-list-with-thumbnail li {
    align-items: center;
    display: flex;
    list-style: none !important;
    margin-bottom: 20px !important
}

ol.cc-list-with-thumbnail li img,
ul.cc-list-with-thumbnail li img {
    clear: both;
    float: left;
    margin-right: 20px
}

ol.cc-list-with-thumbnail li p,
ul.cc-list-with-thumbnail li p {
    display: block;
    margin-left: 0;
    overflow: auto
}

.faq ol,
.faq ul {
    list-style: inherit !important
}

.automation-faq-container .accordion h3 {
    font-weight: 400
}

.intro-short-subtext p:last-child {
    margin-bottom: 0
}

.cc-secondary-op-list .operator-item,
.cc-secondary-op-list .operator-item:hover {
    background: 0 0;
    border: 1px solid rgba(51, 51, 51, .25);
    border-radius: 4px
}

.cc-secondary-op-list .operator-item-row {
    align-items: center;
    margin: 0;
    padding: 15px
}

@media (min-width:768px) {
    .cc-secondary-op-list .operator-item-row:not(:last-child) {
        border-bottom: 1px solid rgba(51, 51, 51, .25)
    }
}

.cc-secondary-op-list .brand-logo img {
    height: auto;
    width: 100%
}

.cc-secondary-op-list .operator-established-year {
    color: #757575;
    font-size: 12px;
    text-align: center
}

.cc-secondary-op-list .bonus-text {
    font-size: 20px;
    font-weight: 600;
    text-align: left
}

@media (max-width:768px) {
    .cc-secondary-op-list .bonus-text {
        padding-top: 20px;
        text-align: center
    }
}

.cc-secondary-op-list .operator-attribute-details {
    justify-content: center;
    padding: 1rem 0
}

@media (max-width:768px) {
    .cc-secondary-op-list .operator-attribute-details {
        justify-content: left
    }
}

.cc-secondary-op-list .operator-attribute-details .attribute-header {
    font-size: 14px;
    line-height: 18px
}

.cc-secondary-op-list .operator-attribute-details .attribute-header img {
    display: inline-block;
    height: 16px;
    width: 16px
}

.cc-secondary-op-list .operator-attribute-details .attribute-value {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 10px
}

.cc-secondary-op-list .operator-attribute-details .attribute-tooltip-container:hover .attribute-tooltip {
    visibility: visible
}

.cc-secondary-op-list .operator-attribute-details .attribute-tooltip {
    background-color: #f7f7f7;
    border-radius: 6px;
    color: #333;
    padding: 5px;
    position: absolute;
    visibility: hidden;
    width: 150px;
    z-index: 1
}

.cc-secondary-op-list .operator-exit-btn {
    padding-top: 0
}

@media (max-width:768px) {
    .cc-secondary-op-list .operator-exit-btn {
        padding: 1rem 0 0
    }
}

.cc-secondary-op-list .operator-tandc {
    color: #777;
    font-size: 10px;
    line-height: 12px
}

.cc-secondary-op-list .btn-cta-play-now {
    background: #0157ff;
    border: 0
}

/*** CUSTOM CODE ***/
.body_content{
    margin-top: 50px;
}
table td, table th {
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border: 1px solid #ccc;
  }